import { html } from 'lit';
import { _converse, api } from '@converse/headless/core';

import '../styles/moments.scss';
import { STORY_ACTIVITY } from '@converse/headless/plugins/muclight/constants';


export default (el) => {
    const story = el.story.get({jid:el.model.get('jid')}).get('stories').filter(data => data.story_id===el.model.get('story_id'))[0]
    const body = JSON.parse(story.body);
    const nickname = story.nick || story.nickname || body.fullName
    const chatBox = _converse?.chatboxes?.get(el.model.get('jid'))
    const story_seen_data = chatBox?.messages?.findWhere({story_seen_id:el.model.get(`story_id`)})?.get('story_seen_data') || []
    let TaggedFriends = body?.storiesData[0]?.storyTaggedFriend || []
    try {
        TaggedFriends = JSON.parse(TaggedFriends)
    }catch(e) {

    }
    const TaggedFriendsList = TaggedFriends.length ? `with ${TaggedFriends[0].contactName} ${TaggedFriends.length>1 ? `+${TaggedFriends.length-1} ${TaggedFriends.length-1>1 ? `Others` : `Other`}` : ``}` : ``
    const moment_date = new Date(parseInt(body.postDate))
    const today_date = new Date()
    let moment_date_ddmmyyyy = `${moment_date.getDate()}-${(moment_date.getMonth()+1)}-${moment_date.getFullYear()}`
    let today_date_ddmmyyyy = `${today_date.getDate()}-${(today_date.getMonth()+1)}-${today_date.getFullYear()}`
    moment_date_ddmmyyyy = moment_date_ddmmyyyy===today_date_ddmmyyyy ? `Today at` : moment_date_ddmmyyyy
    moment_date_ddmmyyyy = `${moment_date_ddmmyyyy} ${moment_date.getHours()}:${moment_date.getMinutes()}`


    return html`<div id="myNav" class="overlay overlay-full-width">
        <a class="closebtn text-white" @click=${ () => el.model.save({jid:``,story_id:``})}><img height="50px" src="/images/moment-modal-close-icon-right-top-corner.png" alt="x" /></a>
        <div class="story-view-main-container d-flex" style="overflow-y: hidden;">
            <div class="col-3 rd-box d-flex justify-content-center align-items-center">
                <div class="text-center" @click=${() => el.previous()}>
                    <img src="../../../../images/left-arrow.svg" class="cursorPointer" width="80px" height="80px" />
                </div>
            </div>
            ${
                ``
                /*<div class="nd-box" style="line-height:${(window.screen.height/3)+window.screen.height}px">*/
            }
            <div class="col-6 nd-box d-flex align-items-center justify-content-center">
                <div class="d-flex align-items-center position-absolute w-100 p-3" style="z-index: 1; top: 0;">
                    <converse-moment-header-span-listing .data=${{story: el.story, model: el.model}}>
                    </converse-moment-header-span-listing>
                    <div class="moments-head-sub-block">
                        <converse-avatar
                            class="avatar align-self-center"
                            .data=${{image: body.avatarUrl, image_type: body.mime_type}}
                            height="40"
                            width="40"
                        ></converse-avatar>
                        <div class="d-flex flex-column ml-2 text-white">
                            <span> ${nickname} 
                            ${body?.storiesData[0]?.storyActivity ? `is ${body?.storiesData[0]?.storyActivity} ${STORY_ACTIVITY[body?.storiesData[0]?.storyActivity]}` : ``}
                            ${body?.storiesData[0]?.storyCheckIn ? `at ${body?.storiesData[0]?.storyCheckIn.split('@@@')[body?.storiesData[0]?.storyCheckIn.split('@@@').length>1 ? 1 : 0]}` : ``}
                            ${TaggedFriendsList}
                            </span>
                            <span> ${moment_date_ddmmyyyy} </span>
                        </div>
                    </div>
                </div>
                <div class="text-white d-flex flex-column position-relative">
                ${body?.storiesData[0]?.mime_type.includes('image')
                    ? html`<img class="moment-view-img-video" src=${body?.storiesData[0]?.url} />`
                    : body?.storiesData[0]?.mime_type.includes('video')
                    ? html`<video class="moment-view-img-video" controls autoplay>
                        <source
                            src=${body?.storiesData[0]?.url}
                            type=${body?.storiesData[0]?.mime_type?.includes(`quicktime`)
                                ? 'video/mp4'
                                : body?.storiesData[0]?.mime_type}
                        />
                        Your browser does not support the video tag.
                    </video>`
                    : html``}
                </div>
                ${el.model.get('jid') !== _converse.bare_jid
                    ? html`<converse-chat-bottom-panel
                          class="position-absolute w-100"
                          style="bottom: 0; z-index: 1; width: 100%"
                          is_story_reply="1"
                          jid="${el.model.get('jid')}"
                          class="bottom-panel"
                      >
                      </converse-chat-bottom-panel>`
                    : html`<div class="view-list-button" @click=${ async (ev) => {
                        await el.model.save({view_list: !el.model.get(`view_list`)})
                        }}>
                        <i class="fa fa-eye text-white" aria-hidden="true"></i> ${story_seen_data?.length ? story_seen_data?.length : 0}
                    </div>`}
                      ${el.model.get(`view_list`) ? html `
                        <div class="position-absolute w-100 views">
                            <div @click=${ async (ev) => {
                                await el.model.save({view_list: !el.model.get(`view_list`)})
                            }}
                            class="d-flex justify-content-center mt-4 mb-2">
                                <img class="cursor-grab" src="../../../../images/moments_icons/views/down-arrow.svg" alt="close" />
                            </div>
                            <div class="d-flex align-items-center justify-content-between m-4">
                                <div class="text-dark">Viewed by (${story_seen_data?.length
                                    ? story_seen_data?.length
                                    : 0})</div>
                                <div>
                                    <img @click=${ async (ev) => {
                                        const is_removed = await el.removeStory({attrs: {story_id:el.model.get(`story_id`)}})
                                        is_removed ? await el.updateData({jid:``,story_id:``,view_list: false}) : ``
                                    }} src="../../../../images/moments_icons/views/delete-moment.svg" alt="delete" />
                                </div>
                            </div>
                            <div class="users-viewed d-flex flex-column m-2 px-3">
                                ${story_seen_data?.length ? story_seen_data.map(data => {
                                    const viewer_model = _converse.roster.get(data)
                                    return html `
                                <div class="d-flex align-items-center justify-content-between my-2">
                                    <div class="d-flex align-items-center">
                                    <converse-avatar class="avatar contactInfo__avatar" .data=${viewer_model?.attributes?.image?.length ? viewer_model.attributes : viewer_model.vcard?.attributes} nonce=${viewer_model.vcard?.get('vcard_updated')} height="50" width="50"></converse-avatar>
                                        <span class="text-dark ml-2">${viewer_model.get('nickname')}</span>
                                    </div>
                                    <div class="text-dark">12:30 PM</div>
                                </div>`
                                }) : ``}
                            </div>
                        </div>
                    </div>`:``}
                </div>
            <div class="col-3 st-box d-flex justify-content-center align-items-center">
                <div class="text-center" @click=${ () => el.next()}>
                    <img src="../../../../images/right-arrow.svg" class="cursorPointer" width="80px" height="80px" />
                </div>
            </div>
        </div>`;

};
