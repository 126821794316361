import DOMPurify from 'dompurify';
import { __ } from 'i18n';
import { api, _converse } from '@converse/headless/core.js';
import { html } from "lit";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { CHAT_TYPE } from '@converse/headless/plugins/muclight/constants.js';
import tpl_contacts_list from 'plugins/muclight-views/modals/templates/contact-list.js' 


export default (el) => {
    return html`${tpl_contacts_list({model: _converse.roompanel}, CHAT_TYPE.GROUP_CHAT)}`;
}


// const contactsData = (el) => {
//     const existing_occupants = el.model.get('info')?.occupants ? Object.entries(el.model.get('info')?.occupants) : []

//     const existing_occupants_filters = []
//     for( const existing_occupants_data of existing_occupants){
//         existing_occupants_filters[existing_occupants_data[existing_occupants_data.length-1]?.jid] = true
//     }
//     return _converse.roster.map(dataRosters => { return dataRosters.get(`chat_type`)===CHAT_TYPE.CHAT && !existing_occupants_filters[dataRosters.get(`jid`)] ? html`<div class="form-group" style="
//     display: inline-block;
//     width: 100%;
//     height: 50px;">
//     <div style="margin-left: 5%;">
//     <input type="checkbox"
//     title="${dataRosters.get(`nickname`) ? dataRosters.get(`nickname`) : dataRosters.get(`jid`)}"
//     name="jids[]"
//     value="${dataRosters.get(`jid`)}"
//     class="form-check-input"/>
//     <converse-avatar class="avatar align-self-center"
//         .data=${dataRosters?.attributes?.image?.length ? dataRosters?.attributes : dataRosters?.vcard?.attributes}
//         nonce=${dataRosters?.attributes?.image?.length ? dataRosters?.attributes : dataRosters?.vcard?.attributes}
//         height="40" width="40"></converse-avatar>
//         <label for="subject">${dataRosters.get(`nickname`) ? dataRosters.get(`nickname`) : dataRosters.get(`jid`)}</label>
//     </div>` : ''})
// }

// export default (el) => {
//     const muclight_domain = el.model.get('muclight_domain') || api.settings.get('muclight_domain');
//     const i18n_Add = `Add`
//     return html`<form class="converse-form add-chatroom" @submit=${(ev) => el.tagMembers(ev)}>
//     ${contactsData(el)}
//     <div><input type="submit" class="btn btn-primary" name="add" value="${i18n_Add || ''}"/></div>`;
// }
