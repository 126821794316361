import { AsyncDirective } from 'lit/async-directive.js';
import { converse } from '@converse/headless/core';
import { directive } from 'lit/directive.js';
import { getHyperlinkTemplate } from 'utils/html.js';
import { html } from 'lit';
import { isURLWithImageExtension } from '@converse/headless/utils/url.js';

const { URI } = converse.env;


class ImageDirective extends AsyncDirective {

    render (src, href, onLoad, onClick) {
        return href ?
            html`<a href="${href}" class="chat-image__link" target="_blank" rel="noopener">${ this.renderImage(src, href, onLoad, onClick) }</a>` :
            this.renderImage(src, href, onLoad, onClick);
    }

    renderImage(src, href, onLoad, onClick) {
        function Click(e) {
            e.preventDefault()
            var imgWindow = document.getElementById('imageWindow')
            let imgDiv = `<div class="overlay" style="position: fixed;
                          top: 0;
                          left: 0;
                          width: 100%;
                          height: 100%;
                          background: rgba(0, 0, 0, 0.8);
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          z-index: 5;">
                          <span class="close-button" id="closeButton" style="position: absolute;
                          top: 10px;
                          right: 10px;
                          cursor: pointer;
                          color: white;
                          font-size: 20px;" title="Close">&#x2715;</span>
                          <a href=${src} download style="position: absolute;
                          top: 10px;
                          right: 40px;
                          cursor: pointer;
                          color: white;
                          font-size: 20px;" title="download">&#x21e9;</a>
                          <img src="${src}" alt="example" style="max-width: 100%;
                          max-height: 100%;">
                          </div>`
            
            imageWindow.innerHTML = imgDiv;
            $('#closeButton').click(function () {
                imageWindow.innerHTML = '';

            })
            // ('#downChatImg').click(function () {
            //     window.location.src = "src";

            // })
        }
        
        return html`<img class="chat-image img-thumbnail"
                loading="lazy"
                id="chatImage"
                src="${src}"
                @click=${Click}
                @error=${() => this.onError(src, href, onLoad, onClick)}
                @load=${onLoad}/></a>`;
    }
   
    onError (src, href, onLoad, onClick) {
        if (isURLWithImageExtension(src)) {
            href && this.setValue(getHyperlinkTemplate(href));
        } else {
            // Before giving up and falling back to just rendering a hyperlink,
            // we attach `.png` and try one more time.
            // This works with some Imgur URLs
            const uri = new URI(src);
            const filename = uri.filename();
            uri.filename(`${filename}.png`);
            this.setValue(renderImage(uri.toString(), href, onLoad, onClick));
        }
    }
}

/**
 * lit directive which attempts to render an <img> element from a URL.
 * It will fall back to rendering an <a> element if it can't.
 *
 * @param { String } src - The value that will be assigned to the `src` attribute of the `<img>` element.
 * @param { String } href - The value that will be assigned to the `href` attribute of the `<img>` element.
 * @param { Function } onLoad - A callback function to be called once the image has loaded.
 * @param { Function } onClick - A callback function to be called once the image has been clicked.
 */
export const renderImage = directive(ImageDirective);
