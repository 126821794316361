import { _converse, api, converse } from '@converse/headless/core.js';
import axios from 'axios';
const { u } = converse.env;
import { CONST_URLs } from '../../shared/constants';
import Helper from '../../utils/helper';


async function getOptions(){
    return new Promise(async (resolve, reject) => {
        try{
            let opt = {};
            let data = await getUserData()
            opt = { headers: {"Authorization" : `Bearer ${data.token}`} }
          resolve(opt)
        }catch(error)
        {
            reject(error)
        }
    })
}

async function getUserData(){
    return new Promise(async (resolve, reject) => {
        try{
            let datafinal = JSON.parse(localStorage.getItem('user'))
            resolve(datafinal)
        }catch(error)
        {
            reject(error)
        }
    })
}

  
export async function syncContactsOnAppOpenViaCS () {
    return new Promise(async (resolve, reject) => {
        try{
            let Options = await getOptions()
            let datafinal = await getUserData()
            if(!datafinal)
            {
                throw new Error(`unable to fetch userdata for syncContactsOnAppOpenViaCS from localstorage`)
            }
            const res = await axios.post(CONST_URLs.APIM_APIS.syncContactsOnAppOpenViaCS, {}, Options);
            resolve(res.data)
        }catch(error){
            reject(error);
        }
      })    
}

export async function userPepNodes (data) {
    return new Promise(async (resolve, reject) => {
        try{
            let Options = await getOptions()
            let datafinal = await getUserData()
            if(!datafinal)
            {
                throw new Error(`unable to fetch userdata for pepnodes from localstorage`)
            }
            const res = await axios.post(CONST_URLs.APIPRODURL_APIS.userPepNodes, data, Options);
            resolve(res.data)
        }catch(error){
            reject(error);
        }
      })    
}

export async function myCompanies (data) {
    return new Promise(async (resolve, reject) => {
        try{
            let Options = await getOptions()
            let datafinal = await getUserData()
            if(!datafinal)
            {
                throw new Error(`unable to fetch userdata for pepnodes from localstorage`)
            }
            const res = await axios.post(CONST_URLs.APIM_APIS.myCompanies, data, Options);
            resolve(res.data)
        }catch(error){
            reject(error);
        }
      })    
}

export async function companyView (id) {
    return new Promise(async (resolve, reject) => {
        try{
            let Options = await getOptions()
            let datafinal = await getUserData()
            if(!datafinal)
            {
                throw new Error(`unable to fetch userdata for pepnodes from localstorage`)
            }
            const res = await axios.get(`${CONST_URLs.APIM_APIS.companyView}/${id}`, Options);
            resolve(res.data)
        }catch(error){
            reject(error);
        }
      })    
}

export async function userGroup () {
    return new Promise(async (resolve, reject) => {
        try{
            let Options = await getOptions()
            let datafinal = await getUserData()
            if(!datafinal)
            {
                throw new Error(`unable to fetch userdata for pepnodes from localstorage`)
            }
            const res = await axios.get(CONST_URLs.APIPRODURL_APIS.userGroup, Options);
            resolve(res.data)
        }catch(error){
            reject(error);
        }
      })    
}
export async function userProfile (data) {
    return new Promise(async (resolve, reject) => {
        try{
            let Options = await getOptions()
            let datafinal = await getUserData()
            if(!datafinal)
            {
                throw new Error(`unable to fetch userdata for userProfile from localstorage`)
            }
            const res = await axios.post(CONST_URLs.APIPRODURL_APIS.userProfile, data, Options);
            resolve(res.data)
        }catch(error){
            reject(error);
        }
      })
}

export async function blobToFile(data) {
    const fileType = data.type;
    const ext = fileType.split('/')[fileType.split('/').length-1]
    const fileName = `${new Date().getTime()}.${ext}`
    const arrayOfBlobParts = [data];
    return new File(arrayOfBlobParts, fileName, { type: fileType });
  }


export async function uploadImageAndGetLink (data) {
    return new Promise(async (resolve, reject) => {
        try{
            let Options = await getOptions()
            let datafinal = await getUserData()
            if(!datafinal)
            {
                throw new Error(`unable to fetch userdata for userProfile from localstorage`)
            }
            const res = await axios.post(CONST_URLs.APIM_APIS.uploadImageAndGetLink, data, Options);
            resolve(res.data)
        }catch(error){
            reject(error);
        }
      })
}

export async function getNearByRosters (data={}) {
    return new Promise(async (resolve, reject) => {
        try{
            let Options = await getOptions()
            let datafinal = await getUserData()
            if(!datafinal)
            {
                throw new Error(`unable to fetch userdata for userProfile from localstorage`)
            }
            const Lat_Long = await Helper.geoLocation()
            if(Lat_Long.status) {
                data.latitude = Lat_Long.data.coords.latitude.toString()
                data.longitude = Lat_Long.data.coords.longitude.toString()
                const res = await axios.post(CONST_URLs.APIM_APIS.getNearByRosters, data, Options);
                resolve(res.data)
            }
            resolve(false)
        }catch(error){
            reject(error);
        }
      })
}

export async function sendStoryWithPrivacy (data) {
    return new Promise(async (resolve, reject) => {
        try{
            let Options = await getOptions()
            let datafinal = await getUserData()
            if(!datafinal)
            {
                throw new Error(`unable to fetch userdata for userProfile from localstorage`)
            }
            const res = await axios.post(CONST_URLs.APIPRODURL_APIS.sendStoryWithPrivacy, data, Options);
            resolve(res.data)
        }catch(error){
            reject(error);
        }
      })
}

export function playCallSound() {
    const audioMp3 = new Audio(api.settings.get('sounds_path') + 'call.mp3');
    audioMp3.setAttribute(`controls`,true)
    audioMp3.setAttribute(`loop`,true)
    if(_converse.incommingcallsound) {
        _converse.incommingcallsound?.pause()
        _converse.incommingcallsound.currentTime = 0
    }
    _converse.incommingcallsound = audioMp3
    const canPlayMp3 = audioMp3.canPlayType('audio/mp3');
    if (canPlayMp3 === 'probably') {
        _converse.incommingcallsound?.play();
    } else if (canPlayMp3 === 'maybe') {
        _converse.incommingcallsound?.play();
    }
}

export function stopCallSound() {
    if(_converse.incommingcallsound) {
        _converse.incommingcallsound?.pause()
        _converse.incommingcallsound.currentTime = 0
    }
}

export function playCallRingSound() {
    const audioMp3 = new Audio(api.settings.get('sounds_path') + 'call-ringing.mp3');
    audioMp3.setAttribute(`controls`,true)
    audioMp3.setAttribute(`loop`,true)
    if(_converse.outgoingcallsound) {
        _converse.outgoingcallsound?.pause()
        _converse.outgoingcallsound.currentTime = 0
    }
    _converse.outgoingcallsound = audioMp3
    const canPlayMp3 = audioMp3.canPlayType('audio/mp3');
    if (canPlayMp3 === 'probably') {
        _converse.outgoingcallsound?.play();
    } else if (canPlayMp3 === 'maybe') {
        _converse.outgoingcallsound?.play();
    }
}
export function stopCallRingSound() {
    if(_converse.outgoingcallsound) {
        _converse.outgoingcallsound?.pause()
        _converse.outgoingcallsound.currentTime = 0
    }
}

export async function userCall (data) {
    return new Promise(async (resolve, reject) => {
        try{
            let Options = await getOptions()
            let datafinal = await getUserData()
            if(!datafinal)
            {
                throw new Error(`unable to fetch userdata for userProfile from localstorage`)
            }
            const res = await axios.post(CONST_URLs.APIPRODURL_APIS.userCall, data, Options);
            resolve(res.data)
        }catch(error){
            reject(error);
        }
      })
}

export async function deleteStory (data) {
    return new Promise(async (resolve, reject) => {
        try{
            let Options = await getOptions()
            let datafinal = await getUserData()
            if(!datafinal)
            {
                throw new Error(`unable to fetch userdata for userProfile from localstorage`)
            }
            const res = await axios.post(CONST_URLs.APIM_APIS.deleteStory, data, Options);
            resolve(res.data)
        }catch(error){
            reject(error);
        }
      })
}

export async function uploadMedia (data, jid) {
    return new Promise(async (resolve, reject) => {
        try{
            let Options = await getOptions()
            let datafinal = await getUserData()
            if(!datafinal)
            {
                throw new Error(`unable to fetch userdata for userProfile from localstorage`)
            }
            const res = await axios.post(`${CONST_URLs.APIPRODURL_APIS.uploadMedia}/${jid}`, data, Options);
            resolve(res.data)
        }catch(error){
            reject(error);
        }
      })
}

export async function getThumbnailForVideo(videoUrl,should_upload=false) {
    const video = document.createElement("video");
    const canvas = document.createElement("canvas");  
    // Trigger video load
    await new Promise((resolve, reject) => {
      video.addEventListener("loadedmetadata", () => {
        video.width = video.videoWidth;
        video.height = video.videoHeight;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        // Seek the video to 25%
        video.currentTime = video.duration * 0.25;
      });
      video.addEventListener("seeked", () => resolve());
      video.src = videoUrl;
    });
  
    // Draw the thumbnailz
    canvas
      .getContext("2d")
      .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    const imageUrl = canvas.toDataURL("image/png");
    let boj={
        canvas:canvas,
        file: await dataURLtoFile(imageUrl, `${+new Date()}.png`),
        base64:imageUrl
      }
      if(should_upload){
        let formData = new FormData();    //formdata object
        formData.append('image', boj.file);   //append the values with key, value pair
        return await uploadImageAndGetLink(formData)
      }
      return boj;
  }
  
  export async function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  export async function getGoogleMapDataByLatLong (latitude,longitude) {
    return new Promise(async (resolve, reject) => {
        try{
            const res = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyACaXLSwbp8bz6RLyXurPgShTS0m0KP-x4`);
            resolve(res)
        }catch(error){
            reject(error);
        }
      })    
}

export async function getPlaceOnGoogleMapDataByName (obj) {
    return new Promise(async (resolve, reject) => {
        try{
            const MapDiv = document.getElementById(obj.mapDivId)
            let map;
            let service;
            let infowindow;
            const sydney = new google.maps.LatLng(obj.latitude, obj.longitude);

              infowindow = new google.maps.InfoWindow();
              map = new google.maps.Map(MapDiv, {
                center: sydney,
                zoom: 15,
              });
            
              const request = {
                query: obj.value,
                fields: ["name", "geometry"],
              };
              service = new google.maps.places.PlacesService(map);
              service.findPlaceFromQuery(request, (results, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && results) {
                    const findbyname = results[0].name
                        service.nearbySearch(
                        { location: { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() }, radius: 500 },
                        (results, status, pagination) => {
                            if (status !== "OK" || !results) {
                                resolve([])
                            }else{
                                resolve(results)
                            }
                        }
                        );                    
                    }else{
                        resolve([])
                    }
              });
            // const res = await axios.get(`https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=${name}&inputtype=textquery&fields=formatted_address%2Cname%2Crating%2Copening_hours%2Cgeometry&key=AIzaSyACaXLSwbp8bz6RLyXurPgShTS0m0KP-x4`);
        }catch(error){
            console.log(`error`,error)
            resolve([])
        }
      })    
}

function getPlaceOnGoogleMapDataByTextSearch (name) {
    return new Promise(async (resolve, reject) => {
        try{
            const res = await axios.get(`https://maps.googleapis.com/maps/api/place/textsearch/json?query=restaurants%20in%20Sydney&key=AIzaSyACaXLSwbp8bz6RLyXurPgShTS0m0KP-x4`);
            resolve(res)
        }catch(error){
            reject(error);
        }
      })    
}

export async function getNearByPlacesOnGoogleMapDataByLatLong (latitude,longitude) {
    return new Promise(async (resolve, reject) => {
        try{
            const res = await axios.get(`https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${latitude},${longitude}&radius=1500&key=AIzaSyACaXLSwbp8bz6RLyXurPgShTS0m0KP-x4`);
            resolve(res)
        }catch(error){
            reject(error);
        }
      })    
}

export async function sendInvitation (data) {
    return new Promise(async (resolve, reject) => {
        try{
            let Options = await getOptions()
            let datafinal = await getUserData()
            if(!datafinal)
            {
                throw new Error(`unable to fetch userdata for pepnodes from localstorage`)
            }
            const res = await axios.post(CONST_URLs.APIM_APIS.sendInvitation, data, Options);
            resolve(res.data)
        }catch(error){
            reject(error);
        }
      })    
}

Object.assign(u, { syncContactsOnAppOpenViaCS, userPepNodes, userGroup, sendStoryWithPrivacy, uploadMedia, uploadImageAndGetLink, userProfile, deleteStory, getPlaceOnGoogleMapDataByName, getPlaceOnGoogleMapDataByTextSearch, getGoogleMapDataByLatLong, getNearByPlacesOnGoogleMapDataByLatLong, companyView, userCall });
