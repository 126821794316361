import { __ } from 'i18n';
import { api, converse, _converse } from '@converse/headless/core';

const { Strophe, $iq, sizzle, u } = converse.env;

export function getPrettyStatus (stat) {
    if (stat === 'chat') {
        return __('online');
    } else if (stat === 'dnd') {
        return __('busy');
    } else if (stat === 'xa') {
        return __('away for long');
    } else if (stat === 'away') {
        return __('away');
    } else if (stat === 'offline') {
        return __('offline');
    } else {
        return __(stat) || __('online');
    }
}

export async function logOut(ev, showConfirmationBox = true) {
    ev?.preventDefault();
    let result = true;
    if (showConfirmationBox) {
        result = await api.confirm(__('Are you sure you want to log out?'));
    }
    if (result) {
        let logoutdata = await api.user.logout();
        let clear_session_url = '';
        try {
            clear_session_url = JSON.parse(logoutdata).clear_session_url;
        } catch (error) {
            console.log('logOut error', error);
        }
        window.location.href = clear_session_url;
    }
}
