import { CustomElement } from 'shared/components/element.js';
import tpl_imageEditor from './templates/imageEditor';
import { _converse, api } from '@converse/headless/core';
import { LOCAL_STORAGE } from "../../shared/constants"
// import './imageEditor.scss';


export default class ImageEditor extends CustomElement {

    static get properties () {
        return {
            width: { type: String },
            height: { type: String },
        }
    }

    constructor () {
        super();
        this.width = 36;
        this.height = 36;
    }

 
    render  () {  
        return tpl_imageEditor({
            'classes': this.getAttribute('class'),
            'height': this.height,
            'width': this.width,                     
        });
    }
}

api.elements.define('converse-imgeditor', ImageEditor);
