import "shared/components/image-picker.js";
import { __ } from 'i18n';
import { api, _converse ,} from  "@converse/headless/core";
import { html } from "lit";
import { LOCAL_STORAGE } from "../../../../../shared/constants";


const tpl_omemo_page = (el) => html`
    <div class="tab-pane ${ el.tab === 'omemo' ? 'active' : ''}" id="omemo-tabpanel" role="tabpanel" aria-labelledby="omemo-tab">
        ${ el.tab === 'omemo' ? html`<converse-omemo-profile></converse-omemo-profile>` : '' }
    </div>`;


export default (el) => {
    const o = { ...el.model.toJSON(), ...el.model.vcard.toJSON(), localstorage: JSON.parse(localStorage.getItem(LOCAL_STORAGE.KEY.USER)) };
    const i18n_email = __('Email');
    const i18n_fullname = __('Full Name');
    const i18n_jid = __('XMPP Address');
    const i18n_nickname = __('Nickname');
    const i18n_role = __('Role');
    const i18n_save = __('Save and close');
    const i18n_role_help = __('Use commas to separate multiple roles. Your roles are shown next to your name on your chat messages.');
    const i18n_url = __('URL');

    const i18n_omemo = __('OMEMO');
    const i18n_profile = __('Profile');
    const ii18n_reset_password = __('Reset Password');
    const theme = __('Change theme');

   const navigation_tabs = [    
    // html`<li role="presentation" class="nav-item">
    // <a class="nav-link ${el.tab === "profile" ? "active" : ""}"
    //    id="profile-tab"
    //    href="#profile-tabpanel"
    //    aria-controls="profile-tabpanel"
    //    role="tab"
    //    @click=${ev => el.switchTab(ev)}
    //    data-name="profile"
    //    data-toggle="tab">${ i18n_profile }</a>
    // </li>`
    ];
   
    if(api.settings.get("is_reset_password_tab_visible")){
        navigation_tabs.push(
            html`<li role="presentation" class="nav-item">
                    <a class="nav-link ${el.tab === "passwordreset" ? "active" : ""}"
                    id="passwordreset-tab"
                    href="#passwordreset-tabpanel"
                    aria-controls="passwordreset-tabpanel"
                    role="tab"
                    @click=${ev => el.switchTab(ev)}
                    data-name="passwordreset"
                    data-toggle="tab">${ ii18n_reset_password }</a>
            </li>`
        );
    }
 
    if(api.settings.get("is_change_theme_tab_visible")){
        navigation_tabs.push(     
            html`<li role="presentation" class="nav-item">
                <a
                    class="nav-link ${el.tab === 'theme' ? 'active' : ''}  theme-changed changeThemeTab"
                    id="theme-tab"
                    href="#theme-tabpanel"
                    aria-controls="theme-tabpanel"
                    role="tab"
                    @click=${ev => {
                        el.switchTab(ev);
                    }}
                    data-name="theme"
                    data-toggle="tab"
                    >${theme}</a
                >
            </li>`
        );
    }    
   
    if(api.settings.get("is_omemo_tab_visible")) {
    if (_converse.pluggable.plugins['converse-omemo']?.enabled(_converse)) {
        navigation_tabs.push(
            html`<li role="presentation" class="nav-item">
                <a class="nav-link ${el.tab === "omemo" ? "active" : ""}"
                   id="omemo-tab"
                   href="#omemo-tabpanel"
                   aria-controls="omemo-tabpanel"
                   role="tab"
                   @click=${ev => el.switchTab(ev)}
                   data-name="omemo"
                   data-toggle="tab">${ i18n_omemo }</a>
            </li>`
        );
    }
}
    // setTimeout(() => {
    //     const themeTab = document.getElementsByClassName('changeThemeTab')[0];
    //     themeTab.addEventListener('click', () => {
    //         const getCookies = document.cookie.split(';');
    //         const getThemeCookie = getCookies[1]?.split('=')[1]?.split(' ')[2]?.split('-')[1];
    //         const checkbox = document.getElementById(`${getThemeCookie}`);
    //         if (checkbox) {
    //             checkbox.checked = true;
    //         }
    //         document.querySelectorAll(`#${getThemeCookie}`);
    //     });
    //     const main = document.getElementsByClassName('conversejs');
    //     main[0].className = 'conversejs converse-fullscreen theme-hookzapp';
    // }, 200);
     

function changeTheme() {
    var theme = document.querySelector('input[name="theme"]:checked').value;
    const selectedTheme = `conversejs converse-fullscreen theme-${theme}`;
    document.cookie = `selectedTheme=${selectedTheme};max-age=` + 31536000;
    const main = document.getElementsByClassName('conversejs');
    main[0].className = selectedTheme;
}
//   const jid = _converse.connection.jid || "";
//   const mobileNumber = Strophe.getResourceFromJid(jid) || "";
  const loggedInUser = localStorage.getItem("user")
  const mobileNumber =  JSON.parse(loggedInUser)?.mobile;
    return html`
        <ul class="nav nav-pills justify-content-center">${navigation_tabs}</ul>
        <div class="tab-content">
            <div class="tab-pane ${
                el.tab === 'profile' ? 'active' : ''
            }" id="profile-tabpanel" role="tabpanel" aria-labelledby="profile-tab">
                <form class="converse-form converse-form--modal profile-form" action="#" @submit=${ev =>
                    el.onFormSubmitted(ev)}>
                    <div class="profile_img">
                      <div >
                        <div class="profile_img-content" style="display:flex;justify-content:center">
                            <converse-image-picker  style="border:3px solid #FF503B; border-radius:50%;" .data="${{
                                image: o.image,
                                image_type: o.image_type
                            }}" width="100" height="100"></converse-image-picker>
                            <img class="profile_img-content-edit" style="width:30px;height:30px;position:absolute;border:1px solid #FF503B; border-radius:50%;background-color:white;padding:5px;top: 109px;right: 198px; pointer-events: none;"  src="/images/general_assets/edit-svgrepo-com.svg"/>
                        </div>
                        </div>
                     ${api.settings.get("is_profile_jid_visible") ? html`<div class="col">
                            <div class="form-group">
                                <label class="col-form-label">${i18n_jid}:</label>
                                <div>${o.jid}</div>
                            </div>
                        </div>`:""}
                    </div>
                    <div class="form-group">
                        <label for="vcard-nickname" class="col-form-label">${i18n_nickname}:</label>
                        <input id="vcard-nickname" type="text" class="form-control" name="nickname" value="${
                            o.localstorage.profile.nickName || o.nickname || ''
                        }"/>
                    </div>
                    <div class="form-group">
                        <label for="vcard-mobilenumber" class="col-form-label">Mobile Number </label>
                        <input disabled id="vcard-mobilenumber" type="text" class="form-control" name="mobilenumber" value="${
                            mobileNumber || ''
                        }"/>
                    </div>
                    
                    <div class="form-group">
                        <label for="vcard-bio" class="col-form-label">Bio</label>
                        <textarea rows="2" cols="20" maxlength="100" id="vcard-bio" type="text" class="form-control" name="bio">${o.localstorage.profile.status || ''}</textarea>
                    </div>
                ${api.settings.get("is_Url_Email_role_visible")?html`<div class="form-group">
                        <label for="vcard-url" class="col-form-label">${i18n_url}:</label>
                        <input id="vcard-url" type="url" class="form-control" name="url" value="${o.url || ''}"/>
                    </div>
                    <div class="form-group">
                        <label for="vcard-email" class="col-form-label">${i18n_email}:</label>
                        <input id="vcard-email" type="email" class="form-control" name="email" value="${
                            o.email || ''
                        }"/>
                    </div>
                    <div class="form-group">
                        <label for="vcard-role" class="col-form-label">${i18n_role}:</label>
                        <input id="vcard-role" type="text" class="form-control" name="role" value="${o.role || ''}" aria-describedby="vcard-role-help"/>
                        <small id="vcard-role-help" class="form-text text-muted">${i18n_role_help}</small></div>`:""}

                    
                    <div class="form-group">
                        <button type="submit" style="background-color:#FF503B; border-radius:30px;" class="save-form btn save-button">${i18n_save}</button>
                    </div>
                </form>
            </div>

            <div class="tab-pane ${
                el.tab === 'passwordreset' ? 'active' : ''
            }" id="passwordreset-tabpanel" role="tabpanel" aria-labelledby="passwordreset-tab">
                ${
                    el.tab === 'passwordreset'
                        ? html`<converse-change-password-form></converse-change-password-form>`
                        : ''
                }
            </div>

            <div class="tab-pane ${
                el.tab === 'theme' ? 'active' : ''
            }" id="theme-tabpanel" role="tabpanel" aria-labelledby="theme-tab">
                ${
                    el.tab === 'theme'
                        ? html`
                        <div style="padding:20px; margin-top:"10px; cursor:pointer;" >
                              <input type="radio" id="dracula" name="theme" value="dracula"
                            
                              />
                              <label for="dracula">Dark Mode</label><br />
                              
                              <input type="radio" id="hookzapp" name="theme" value="hookzapp" 
                              
                            />
                              <label for="hookzapp">HookzApp</label><br />


                              <input type="radio" id="aniket" name="theme" value="aniket" 
                         />
                              <label for="aniket">WingTrill</label>
                              </br>
                              <button class="save-form btn btn-primary"
                              style="margin-top:15px"
                                  @click=${changeTheme}
                              >
                                  Apply
                              </button>
                              </div>
                          `
                        : ''
                }
            </div>

            ${_converse.pluggable.plugins['converse-omemo']?.enabled(_converse) ? tpl_omemo_page(el) : ''}
        </div>
            </div>`;}
