import DOMPurify from 'dompurify';
import { __ } from 'i18n';
import { api, _converse } from '@converse/headless/core.js';
import { html } from "lit";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { getAutoCompleteList } from "../../search.js";
import { CHAT_TYPE } from '@converse/headless/plugins/muclight/constants.js';

import '../styles/add-muclight.scss';


export default (el,rosters) => {
    const selected_occupants = el.model.get('group_occupants') || []
    return Object.entries(rosters.contacts).map(data => {
        const alpha_heading = data[0]
        return data[1].map((contact,index) => {
            if(contact.get(`hidden`)) {
                return ``
            }
            return html`
            ${index===0 ? html `<div class="alpha_heading pl-4 py-2 my-3">${alpha_heading}</div>` : ``}
            <div @click=${ async(ev) => {
                selected_occupants.includes(contact.get(`jid`)) ? selected_occupants.indexOf(contact.get(`jid`))>-1 ? selected_occupants.splice(selected_occupants.indexOf(contact.get(`jid`)),1) : `` : selected_occupants.push(contact.get(`jid`))
                await el.model.save({group_occupants: selected_occupants})
                api.trigger('refresh_group_occupants')
            }} class="d-flex align-items-center w-100 p-3"
            style="height: 60px;"
        >
            <converse-avatar
                class="avatar align-self-center"
                .data=${contact?.attributes?.image?.length
                    ? contact?.attributes
                    : contact?.vcard?.attributes}
                nonce=${contact?.attributes?.image?.length
                    ? contact?.attributes
                    : contact?.vcard?.attributes}
                height="40"
                width="40"
            ></converse-avatar>
            <div class="d-flex align-items-center justify-content-between w-100 ml-1 mx-3" style="height: 60px; border-bottom: 1px solid #e8e8e8;">
                <label class="mb-0 mx-1" for="subject" style="font-size: 1rem;"
                    >${contact.get(`nickname`) ? contact.get(`nickname`) : contact.get(`jid`)}</label
                >
                ${selected_occupants.includes(contact.get(`jid`)) ? html `
                <input
                    type="checkbox"
                    title="${contact.get(`nickname`) || contact.get(`jid`)}"
                    style="width: 1rem; height: 1rem;" checked>`
                    :
                    html `<input
                    type="checkbox"
                    title="${contact.get(`nickname`) || contact.get(`jid`)}"
                    style="width: 1rem; height: 1rem;">`
                }
            </div>
        </div>`
        })
    })
}
