import tpl_forward_message from "./templates/forward-message.js";
import tpl_forward_message_footer from "./templates/forward-message_footer.js";
import BaseModal from "plugins/modal/modal.js";
import { __ } from 'i18n';
import { _converse, api, converse } from "@converse/headless/core";
// import './core.js'
// import '../styles/add-muclight-modal.scss';
import { isNull } from "lodash-es";
import { html } from "lit";
import { CHAT_TYPE } from "@converse/headless/plugins/muclight/constants.js";

const u = converse.env.utils;
const { Strophe, $iq, sizzle } = converse.env;


export default class ForwardMessageModal extends BaseModal {

    initialize () {
        super.initialize();
        this.listenTo(this.model, 'change:muclight_domain', () => this.render());
        this.listenTo(_converse.roompanel, 'change:group_occupants_search', () => this.render());
        api.listen.on('groupInfoRequestUpdate', () => {
            this.render();
        });
        api.listen.on('refresh_group_occupants', () => {
            this.render();
        });
        this.muclight_roomid_policy_error_msg = null;
        this.render();
    }

    renderModal () {
        if (this.ev) {
            _converse.roompanel.save({
                group_occupants_search: '',
                group_screen: 0,
                group_name: '',
                group_description: '',
                group_cover: '',
                group_occupants: [],
                error: '',
                alert: '',
            });
            delete this.ev;
        }
        return tpl_forward_message(this);
    }

    getModalTitle () { // eslint-disable-line class-methods-use-this
        return __(`Forwarding Message${_converse.roompanel.get('group_occupants').length ? ` to  ${_converse.roster.get(_converse.roompanel.get('group_occupants')[0]).get('nickname')} ${_converse.roompanel.get('group_occupants').length>1 ? `and ${_converse.roompanel.get('group_occupants').length-1} more.` : ``}` : ``}`);
    }
    renderModalFooter() {
        return tpl_forward_message_footer(this);
    }

    /*
    *   forwardMessage will call server to execute or request to add new member in existing
    *   group
    *   Example to use : forwardMessage(el) is an object which holdes message data
    */
    async forwardMessage (el={}) {
        return new Promise(async (resolve, reject) => {
            try{
                const jids = el.occupants
                for(const jids_data of jids){
                    let jid_chatbox = await api.chatboxes.get(jids_data,{},true)
                    if(!jid_chatbox) {
                        await api.chats.get (jids_data, {}, true)
                        return resolve(await this.forwardMessage (el))
                    }
                }
                for(const jids_data of jids){
                    let jid_chatbox = await api.chatboxes.get(jids_data,{},true)
                    jid_chatbox.sendMessage({body:el.model.get(`plaintext`) || el.model.get(`message`) || el.model.get(`body`), is_forward: true, message_type: el.model.get(`message_type`)})
                }
                if(jids.length===1) {
                    await _converse.roster.get(jids[0])?.openChat(true)
                }
                this.modal.hide();
                resolve(true)
                }catch(error){
                reject(error)
            }
        })
    }

}

api.elements.define('converse-forward-message-modal', ForwardMessageModal);
