import { __ } from 'i18n';
import { _converse, api } from "@converse/headless/core";
import { html, render } from "lit";
import { resetElementHeight } from '../utils.js';

import '../styles/moments.scss';
import '../styles/bottom-responsive.scss'

export default (o) => {
    const label_message = o.composing_spoiler ? __('Hidden message') : __('Type a message');
    const label_spoiler_hint = __('Optional hint');
    const show_send_button = api.settings.get('show_send_button');
    const chatview = _converse.chatboxviews.get(o.model.get('jid'));

    function focusInput() {
      document.getElementById("chat-message-input").focus();
    }

    function resizeTextArea() {
        const textarea = document.getElementsByClassName("moment-reply-textarea");
        textarea.style.height = "auto"; // Reset the height to auto to recalculate the height based on the content
        textarea.style.height = textarea.scrollHeight + "px"; // Set the height to the scroll height of the content
      }

    function renderStoryToolbox(chatview){  
        return html`<div class="d-flex align-items-center" style="justify-content: space-evenly;">
            <div class="d-flex align-items-center justify-content-evenly chat-toolbar-input-area w-75" style="height: 3rem;">
                <textarea
                    autofocus
                    type="text"
                    enterkeyhint="send"
                    @drop=${o.onDrop}
                    @keydown=${o.onKeyDown}
                    @keyup=${o.onKeyUp}
                    @paste=${o.onPaste}
                    @change=${o.onChange}
                    class="chat-textarea chat-textareatext-size ${o.composing_spoiler ? 'spoiler' : ''} moment-reply-textarea w-100"
                    placeholder="Type a reply"
                    style="resize: none;"
                >
                </textarea>
                <button class="toggle-emojis" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="border: none;" title="Insert emojis">
                    <svg id="Group_15702" data-name="Group 15702" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20.25" height="20.247" viewBox="0 0 20.25 20.247">
                        <defs>
                            <linearGradient id="linear-gradient" x1="-0.048" x2="0.975" y2="1.036" gradientUnits="objectBoundingBox">
                            <stop offset="0" stop-color="#ff5850"></stop>
                            <stop offset="1" stop-color="#fe2b51"></stop>
                            </linearGradient>
                            <clipPath id="clip-path">
                            <rect id="Rectangle_4895" data-name="Rectangle 4895" width="20.25" height="20.247" fill="url(#linear-gradient)"></rect>
                            </clipPath>
                        </defs>
                        <g id="Group_15701" data-name="Group 15701" clip-path="url(#clip-path)">
                            <path id="Path_9438" data-name="Path 9438" d="M11.695,15.044v5.1c-.078.041-.12.083-.161.083-1.815-.014-3.643.1-5.441-.078A6.687,6.687,0,0,1,.071,13.786,68.894,68.894,0,0,1,.142,6.206,6.473,6.473,0,0,1,6.192.15,64.194,64.194,0,0,1,13.821.084a6.817,6.817,0,0,1,6.405,6.779c.028,1.2,0,2.4.023,3.6.008.382-.14.455-.484.457-1.28.008-2.561.029-3.84.077A4.2,4.2,0,0,0,12.9,12.45,3.578,3.578,0,0,1,7.2,12.038c-.073-.107-.135-.222-.213-.325a.761.761,0,0,0-1.023-.263.749.749,0,0,0-.3,1.05,5.187,5.187,0,0,0,5.538,2.626c.156-.031.313-.053.486-.081m1.725-8.8a1.148,1.148,0,0,0-1.127,1.16,1.165,1.165,0,0,0,1.155,1.143A1.186,1.186,0,0,0,14.58,7.378,1.16,1.16,0,0,0,13.42,6.246M5.832,7.369A1.135,1.135,0,1,0,8.1,7.415a1.135,1.135,0,1,0-2.269-.046" transform="translate(0 0)" fill="url(#linear-gradient)"></path>
                            <path id="Path_9439" data-name="Path 9439" d="M143.11,128.564c.212,3.174-3.47,7.2-6.886,7.51a1.03,1.03,0,0,1-.064-.255c.01-1.426-.018-2.855.052-4.278a2.8,2.8,0,0,1,2.888-2.958c1.319-.09,2.648-.018,4.01-.018" transform="translate(-122.912 -116.03)" fill="url(#linear-gradient)"></path>
                        </g>
                    </svg>
                </button>
            </div>
            <span class="bottompanel__message-form-paperclip-icon"><img src="/images/moments_icons/reply-to-moment.png" style="height: 3rem;" /></span>
            <span class="bottompanel__message-form-sticker-icon"><img src="/images/moments_icons/love-moment.png" style="height: 3rem;" /></span>
        </div>`;
    }
     
    return html`
        <form class="sendXMPPMessage">
        ${
            o?.is_story_reply != true
                ? html`<div class="chat__audio-box">
                          <div class="chat__audio-box-left">
                              <div class="deleteAudio">
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="25"
                                      width="25"
                                      viewBox="0 0 16 22"
                                      class="i0x3nve6"
                                  >
                                      <path
                                          d="M5,0,3,2H0V4H16V2H13L11,0ZM15,5H1V19.5A2.5,2.5,0,0,0,3.5,22h9A2.5,2.5,0,0,0,15,19.5Z"
                                          fill="#667781"
                                      ></path>
                                  </svg>
                              </div>
                              <div class="chat__audio-box-play" id="playButton">
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="25"
                                      width="25"
                                      viewBox="0 0 16 18"
                                      class="d7dzraxg"
                                  >
                                      <path
                                          d="M15.05,8.39,2,.32a1,1,0,0,0-1.53.85V16.83A1,1,0,0,0,2,17.7L15,10.1A1,1,0,0,0,15.05,8.39Z"
                                          fill="currentColor"
                                      ></path>
                                  </svg>
                              </div>
                          </div>
                          <div class="chat__audio-box-right">
                              <div class="chat__audio-box-right-stop" id="stopRecordingButton">
                                  <svg height="30" width="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                      <circle
                                          cx="16"
                                          cy="16"
                                          r="14.75"
                                          fill="none"
                                          stroke="#ff3b30"
                                          stroke-width="2.5"
                                      ></circle>
                                      <path
                                          class="iwt3stqw s79hpmcy ksz6vod1"
                                          d="M20.65,21.69V10.25H17.31V21.69Zm-9.3-11.44V21.69h3.34V10.25Z"
                                          fill="#ff3b30"
                                      ></path>
                                  </svg>
                              </div>
                              <div>
                                  <svg
                                      height="30"
                                      width="30"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 35 35"
                                      class=""
                                      id="downloadButton"
                                  >
                                      <path
                                          d="M17.5,0h0A17.51,17.51,0,0,1,35,17.5h0A17.51,17.51,0,0,1,17.5,35h0A17.51,17.51,0,0,1,0,17.5H0A17.51,17.51,0,0,1,17.5,0Z"
                                          fill="#00a884"
                                      ></path>
                                      <path
                                          class="iwt3stqw s79hpmcy ksz6vod1"
                                          d="M25.64,18.55,11.2,24.93a.86.86,0,0,1-1.13-.44.83.83,0,0,1-.06-.44l.48-4.11a1.36,1.36,0,0,1,1.24-1.19l7.51-.6a.16.16,0,0,0,.14-.16.16.16,0,0,0-.14-.14l-7.51-.6a1.36,1.36,0,0,1-1.24-1.19L10,12a.84.84,0,0,1,.74-.94.87.87,0,0,1,.45.06l14.44,6.38a.61.61,0,0,1,.31.79A.59.59,0,0,1,25.64,18.55Z"
                                          fill="#fff"
                                      ></path>
                                  </svg>
                              </div>
                          </div>
                      </div>
                      <input
                          type="text"
                          enterkeyhint="send"
                          placeholder="${label_spoiler_hint || ''}"
                          i
                          value="${o.hint_value || ''}"
                          class="${o.composing_spoiler ? '' : 'hidden'} spoiler-hint"
                      />
                      <div class="bottompanel__message-form-textarea">
                      <div class="d-flex align-items-center justify-content-evenly chat-toolbar-input-area ${o.model.attributes.show_contact_info_side_bar ? "chatbox-textarea-open-contact-container": "chatbox-textarea-container"}" @click=${focusInput}>
                        <span class="bottompanel__message-form-camera-icon">
                            <img src="/images/general_assets/camera_icon.png" />
                        </span>
        
                        <textarea
                        id="chat-message-input"
                        autofocus
                        type="text"
                        enterkeyhint="send"
                        @drop=${o.onDrop}
                        @input=${resetElementHeight}
                        @keydown=${o.onKeyDown}
                        @keyup=${o.onKeyUp}
                        @paste=${o.onPaste}
                        @change=${o.onChange}
                        class="ml-2 mt-2 chat-textarea chat-textareatext-size
                        ${show_send_button ? 'chat-textarea-send-button' : ''}
                        ${o.composing_spoiler ? 'spoiler' : ''}"
                        placeholder="${label_message}"
                    >${o.message_value || o.draft || ''}</textarea>
                        <converse-emoji-dropdown
                          .chatview=${chatview}
                        ></converse-emoji-dropdown>
                      </div>
                    </div>`
                : renderStoryToolbox(chatview)
        }
            </div>
        </form>`;
}
