import { __ } from 'i18n';
import { _converse, api, converse } from "@converse/headless/core";

import '../styles/add-muclight-modal.scss';
import { isNull } from "lodash-es";
import { html } from "lit";
import { isInboxMessage } from '@converse/headless/shared/parsers';

const u = converse.env.utils;
const { Strophe, $iq, sizzle } = converse.env;



    /*
    *   we make a json here by collecting data from a form
    *   form param has all the input fields
    *   Example to use : new FormData(form);
    *   required parms and valude are [groupname,groupsubject] :: Data Type String,
    *   required atleast 1 should be selected as jids :: Array
    *   Example to use/call this.parseMucLightRoomDataFromEvent(form) or import add-muclight.js
    *   any and make an object to call it.
    */
    function parseMucLightRoomDataFromEvent (form) { // eslint-disable-line class-methods-use-this
        const data = new FormData(form);
        const groupname = data.get('groupname')?.trim();
        let groupsubject = data.get('groupsubject')?.trim();

        let jids = form.querySelectorAll('input[name="jids[]"]:checked')
        let dataObj = {
            name: groupname,
            subject: groupsubject,
            occupants: this.getCheckedBoxes(jids)
        }
        return dataObj

    }
    /*
    *   we make a json here by collecting data from a muc-info received from server
    *   the data which we receive from server we will pass in parseMucLightInfo to gather information
    *   Example to use : parseMucLightInfo(result);
    *   it must have attribute name as `type` and it's value should be result to verify
    *   that the informaiton found for the sent group jid or else not found or any error will
    *   return by server
    */
    function parseMucLightInfo(result) {
        let groupParsedData = {}
        if(result.getAttribute(`type`)==`result`){
            let configurationElements = result.querySelector(`configuration`).childNodes
            for(const configurationElementsData of configurationElements){
                groupParsedData[configurationElementsData.nodeName] = configurationElementsData?.textContent
            }
            groupParsedData[`occupants`] = {}
            let occupantsElements = result.querySelector(`occupants`).childNodes
            let occupantsElementsDataI = 0
            for(const occupantsElementsData of occupantsElements){
                groupParsedData[`occupants`][occupantsElementsDataI] = {jid:occupantsElementsData?.textContent,type:occupantsElementsData.getAttribute(`affiliation`)}
                occupantsElementsDataI++
            }
        }
        return groupParsedData
    }

    /*
    *   collecting data from form to return only selected data by user in occupants array
    *   Example to use : getCheckedBoxes(checkboxes)
    *   it will collect only those jids which are selected by user to add occupants in room
    */
    function getCheckedBoxes(checkboxes) {
        var checkboxesChecked = [];
        // loop over them all
        for (var i=0; i<checkboxes.length; i++) {
           // And stick the checked ones onto an array...
           if (checkboxes[i].checked) {
              checkboxesChecked.push(checkboxes[i].value);
           }
        }
        // Return the array if it is non-empty, or null
        return checkboxesChecked.length > 0 ? checkboxesChecked : [];
    }
    function generateUserJid (jid) {
        if(isNull(jid)){
            return jid;
          }
          return `${Strophe.getNodeFromJid(jid)}@${_converse.domain}`
    }
    /*
    *   getGroupInfo to call server with specific group jid to request to return data
    *   based on groupjid
    *   Example to use : getGroupInfo(`groupjid@xmpp.muclight.domain.com`)
    *   it will send request by iq and and will receive instant response from serve
    */
    async function getGroupInfo (groupJid) {
        return new Promise(async (resolve, reject) => {
            try{
                groupJid = `${Strophe.getNodeFromJid(groupJid)}@${api.settings.get('muclight_domain')}`
                var iq = $iq({
                    type: "get",
                    id: Math.floor(Math.random() * 9999999999),
                    to: groupJid
                  }).c("query", { xmlns: Strophe.NS.MUCLIGHT_INFO })
                  .up().up();
                  let result = await api.sendIQ(iq)
                  const resultParseData = this.parseMucLightInfo(result)
                  const occupants_jid = []
                  for(const resultParseDataKey of Object.keys(resultParseData.occupants)) {
                      occupants_jid.push(resultParseData.occupants[resultParseDataKey].jid)
                  }
                  const userJidsInfo = await _converse.roster.fetchJidsInfo(occupants_jid)
                  for(const resultParseDataKey of Object.keys(resultParseData.occupants)) {
                      resultParseData.occupants[resultParseDataKey].nickname = userJidsInfo[resultParseData.occupants[resultParseDataKey].jid]?.nickname?.trim()
                      resultParseData.occupants[resultParseDataKey].image = userJidsInfo[resultParseData.occupants[resultParseDataKey].jid]?.image
                      resultParseData.occupants[resultParseDataKey].status = userJidsInfo[resultParseData.occupants[resultParseDataKey].jid]?.status
                  }

                resolve(resultParseData)
            }catch(error){
                reject(error)
            }
        })
    }

    /*
    *   fetchInbox to call server to execute or request to create new room
    *   with passed some params which allows to configure room settings
    *   Example to use : fetchInbox(ev) ev has as clickable event and data which will
    *   collect submitted form data also to know what use has subbmited to request
    */
    async function fetchInbox (ev) {
        ev?.preventDefault();
        return new Promise(async (resolve, reject) => {
            try{
                if (!api.connection.connected()) {
                    await api.connection.reconnect();
                    // throw new Error('Can\'t call `fetchInbox` before having established an XMPP session');
                }
                const inboxData = []
                const inboxDataTimeStamp = []

                var attr = {
                    type: 'set',
                };
                let options = {
                    onMessage: async function (message) {
                        let selector = `forwarded`;
                        message = sizzle(selector, message).pop()
                        const delay = sizzle(`delay`, message).pop()
                        const timestamp = delay?.getAttribute(`stamp`)
                        selector = `message`;
                        message = sizzle(selector, message).pop()
                        let from_jid = Strophe.getBareJidFromJid(message.getAttribute(`from`))
                        const logged_jid = Strophe.getBareJidFromJid(_converse.connection.jid)
                        if(from_jid===logged_jid){
                            from_jid = Strophe.getBareJidFromJid(message.getAttribute(`to`))
                        }
                        if(Strophe.getBareJidFromJid(from_jid))
                        !inboxData.includes(from_jid) ? inboxData.push(from_jid) : ''

                        inboxDataTimeStamp[from_jid] = new Date(timestamp).getTime()
                    },
                    onComplete: function (iq) {
                        resolve({inboxData,inboxDataTimeStamp})
                    },              
                };
                var inboxAttr = {xmlns: Strophe.NS.INBOX,'queryid': Math.floor(Math.random() * 9999999999)};
                if (!!options.queryid) {
                    inboxAttr.queryid = options.queryid;
                    // delete options.queryid;
                }
                var iq = $iq(attr).c('inbox',inboxAttr);
        
                //iq.c('field',{var:'FORM_TYPE', type:'hidden'}).c('value').t(Strophe.NS.INBOX).up().up();
        
                iq.up();
                var onMessage = options.onMessage;
                delete options.onMessage;
                var onComplete = options.onComplete;
                delete options.onComplete;
        
                var { connection } = _converse;
                var handler = connection.addHandler(onMessage, Strophe.NS.INBOX, 'message', null);
                return connection.sendIQ(iq, function(){
                connection.deleteHandler(handler);
                onComplete.apply(this, arguments);
                });
            }catch(error){
                resolve({inboxData:[],inboxDataTimeStamp:[]})
                // reject(`fetchInbox error : `,error)
            }
        })
    }
    /*
    *   blockContact will call server to execute or request to BLOCK member
    *   Example to use : blockContact(data) data is an object which holdes contact's data
    *   data.to and data.jid as member's jid we wish to block
    */
    async function blockContact (data,ev) {
        const result = await api.confirm(__(`Are you sure you want to ${data.action} this contact?`));
        if (!result)  return;
        return new Promise(async (resolve, reject) => {
            try{
                var iq = $iq({
                    type: "set",
                    id: u.getUniqueId(),
                  }).c(data.action, { xmlns: Strophe.NS.XMPP_BLOCK_XMLNS })
                    .c("item", { jid: data.model.jid }).up()
                    .up()
                    .up()
                let datareturned = await api.sendIQ(iq)
                if(datareturned?.getAttribute(`type`)=="result"){
                    const chatbox = _converse.chatboxes.get(data.model.jid)
                    chatbox.save({is_blocked: data.action==='block'})
                    _converse.roster.get(data.model.jid).save({is_blocked:data.action==='block'})
                    await _converse.roster.get(data.model.jid).openChat(true)
                    api.trigger(`groupInfoRequestUpdate`)
                }
                resolve(datareturned)
                }catch(error){
                reject(error)
            }
        })
    }

    /*
    *   destroyGroup will call server to execute or request to REMOVE member FROM existing
    *   group
    *   Example to use : destroyGroup(data) data is an object which holdes groupjid as
    *   data.to and data.jid as member's jid we wish to remove in group
    */
        async function destroyGroup (data,ev) {
            const result = await api.confirm(__("Are you sure you want to delete this group?"));
            if (!result)  return;
            return new Promise(async (resolve, reject) => {
                try{
                    await _converse.roster.get(data.to)?.destroy()
                    const chatbox_model = await _converse.chatboxes.get(data.to)
                    
                    let messages_data = await chatbox_model?.messages?.models
                    messages_data = await messages_data ? await messages_data : []
                    for(const messages of messages_data) {
                        messages.save({is_deleted: true})
                    }
                    chatbox_model?.destroy()            
                    resolve(true)
                    }catch(error){
                    reject(error)
                }
            })
        }    
    /*
    *   removeParticipants will call server to execute or request to REMOVE member FROM existing
    *   group
    *   Example to use : removeParticipants(data) data is an object which holdes groupjid as
    *   data.to and data.jid as member's jid we wish to remove in group
    */
    async function removeParticipants (data,ev) {
        const result = await api.confirm(__("Are you sure you want to remove this contact?"));
        if (!result)  return;
        return new Promise(async (resolve, reject) => {
            try{
                var iq = $iq({
                    type: "set",
                    id: u.getUniqueId(),
                    to: data.to
                  }).c("query", { xmlns: Strophe.NS.MUCLIGHT_AFFILIATIONS })
                    .c("user", { affiliation: Strophe.NS.MUCLIGHT_AFFILIATION_TYPE_REMOVE }).t(data.jid).up()
                    .up()
                    .up()
                let datareturned = await api.sendIQ(iq)
                if(datareturned?.getAttribute(`type`)=="result"){
                    await _converse.roster.get(data.to).openChat(true)
                    api.trigger(`groupInfoRequestUpdate`)
                }
                resolve(datareturned)
                }catch(error){
                reject(error)
            }
        })
    }
    /*
    *   addParticipants will call server to execute or request to REMOVE member FROM existing
    *   group
    *   Example to use : addParticipants(data) data is an object which holdes groupjid as
    *   data.to and data.jid as member's jid we wish to remove in group
    */
    async function addParticipants (data,ev) {
        return new Promise(async (resolve, reject) => {
            try{
                ev.target.innerHTML = `Please Wait...`
                ev.target.disabled = true        
                var iq = $iq({
                    type: "set",
                    id: u.getUniqueId(),
                    to: data.to
                  }).c("query", { xmlns: Strophe.NS.MUCLIGHT_AFFILIATIONS })
                    .c("user", { affiliation: Strophe.NS.MUCLIGHT_AFFILIATION_TYPE_MEMBER }).t(data.jid).up()
                    .up()
                    .up()
                let datareturned = await api.sendIQ(iq)
                if(datareturned?.getAttribute(`type`)=="result"){
                    await _converse.roster.get(data.to).openChat(true)
                    api.trigger(`groupInfoRequestUpdate`)
                }
                resolve(datareturned)
                }catch(error){
                reject(error)
            }
        })
    }
    /*
    *   makeParticipantAsAdmin will call server to execute or request to add member as admin
    *   in existing group.
    *   Example to use : makeParticipantAsAdmin(data) data is an object which holdes groupjid as
    *   data.to and data.jid as member's jid we wish to add and make admin in group
    */
    async function makeParticipantAsAdmin (data,ev) {
        return new Promise(async (resolve, reject) => {
            try{
                ev.target.innerHTML = `Please Wait...`
                ev.target.disabled = true        
                var iq = $iq({
                    type: "set",
                    id: u.getUniqueId(),
                    to: data.to
                  }).c("query", { xmlns: Strophe.NS.MUCLIGHT_AFFILIATIONS })
                    .c("user", { affiliation: Strophe.NS.MUCLIGHT_AFFILIATION_TYPE_ADMIN }).t(data.jid).up()
                    .up()
                    .up()
                let datareturned = await api.sendIQ(iq)
                if(datareturned?.getAttribute(`type`)=="result"){
                    await _converse.roster.get(data.to).openChat(true)
                    api.trigger(`groupInfoRequestUpdate`)
                }
                resolve(datareturned)
                }catch(error){
                reject(error)
            }
        })
    }
Object.assign(u, {parseMucLightRoomDataFromEvent, parseMucLightInfo, getCheckedBoxes, generateUserJid, getGroupInfo, removeParticipants, destroyGroup, addParticipants, makeParticipantAsAdmin, fetchInbox, blockContact})