import 'shared/components/icons.js';
import DOMNavigator from "shared/dom-navigator.js";
import DropdownBase from 'shared/components/dropdownbase.js';
import { KEYCODES } from '@converse/headless/shared/constants.js';
import { api } from "@converse/headless/core.js";
import { html } from 'lit';
import { until } from 'lit/directives/until.js';

import './styles/dropdown.scss';


export default class ReactionView extends DropdownBase {

    static get properties () {
        return {
            'icon_classes': { type: String },
            'items': { type: Array },
            'model': { type: Object }
        }
    }

    constructor () {
        super();
        this.icon_classes = 'fa fa-angle-down';
    }

    render () {
        let reaction_data = this.model.get(`reaction_data`)
        if(reaction_data.length===0) {
            return ``
        }
        const counts = {};
        if(reaction_data) {

            for(const o_model_reaction_data of reaction_data) {
                counts[o_model_reaction_data?.reaction] = (counts[o_model_reaction_data?.reaction] || 0) + 1;
            }
        }
        const heading_subdata_html = Object.entries(counts).map((Udata,index) => {
            return html` ${index >3 ? `` : index >2 ? html `<small>+${parseInt(Object.entries(counts).length-index)}</small>` : html `<span class="${Udata[0]}">${Udata[0]}</span>${Udata[1]>1 ? html `<small>+${Udata[1]}</small>` : ``}`}`
        })

        return html`
            <button type="button" class="btn--transparent py-1 px-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                ${heading_subdata_html}
            </button>
            <div class="dropdown-menu reactionview-drop-down-menu">
                ${ this.items.map(b => until(b, '')) }
            </div>
        `;
    }

    firstUpdated () {
        super.firstUpdated();
        this.initArrowNavigation();
    }

    connectedCallback () {
        super.connectedCallback();
        this.hideOnEscape = ev => (ev.keyCode === KEYCODES.ESCAPE && this.hideMenu());
        document.addEventListener('keydown', this.hideOnEscape);
    }

    disconnectedCallback() {
        document.removeEventListener('keydown', this.hideOnEscape);
        super.disconnectedCallback();
    }

    hideMenu () {
        super.hideMenu();
        this.navigator?.disable();
    }

    initArrowNavigation () {
        if (!this.navigator) {
            const options = {
                'selector': '.dropdown-item',
                'onSelected': el => el.focus()
            };
            this.navigator = new DOMNavigator(this.menu, options);
        }
    }

    enableArrowNavigation (ev) {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        this.navigator.enable();
        this.navigator.select(this.menu.firstElementChild);
    }

    handleKeyUp (ev) {
        super.handleKeyUp(ev);
        if (ev.keyCode === KEYCODES.DOWN_ARROW && !this.navigator.enabled) {
            this.enableArrowNavigation(ev);
        }
    }
}

api.elements.define('converse-reactionview-dropdown', ReactionView);
