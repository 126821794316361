import { html } from 'lit';

export default (closeCamera) => {
   return html`
   <div id ="camera" class="camera d-none">
      <div class="d-flex flex-column">
         <span class="toolbar__camera__cross" >
            <svg @click=${closeCamera} viewBox="0 0 24 24" height="30" width="30" preserveAspectRatio="xMidYMid meet" class="" fill="white" enable-background="new 0 0 24 24" xml:space="preserve">
               <path d="M19.6004 17.2L14.3004 11.9L19.6004 6.60005L17.8004 4.80005L12.5004 10.2L7.20039 4.90005L5.40039 6.60005L10.7004 11.9L5.40039 17.2L7.20039 19L12.5004 13.7L17.8004 19L19.6004 17.2Z"></path>
            </svg>
         </span>
         <video autoplay id="video" width="700" height="400" class="mb-4"></video>
         <div id="click-photo" class="toolbar__camera-icon position-relative d-flex justify-content-center">
            <img class="position-absolute" src="/images/moments_icons/capture-btn.svg" alt="Capture" width="400px" style="width: 400px; top: -20px;" />
         </div>
      </div>
   </div>`;
};
