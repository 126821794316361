import 'shared/components/icons.js';
import { __ } from 'i18n';
import { _converse, api, converse } from "@converse/headless/core";
import { html } from "lit";
import { isUniView } from '@converse/headless/utils/core.js';
import { repeat } from 'lit/directives/repeat.js';
import { toggleGroup } from '../../rosterview/utils.js';
import { roster_section_edit } from '@converse/headless/plugins/chat/utils.js';

const { u } = converse.env;


function renderContact (contacts) {
    return Object.entries(contacts).map(data => {
        const alpha_heading = data[0]
        return data[1].map((contact,index) => {
            if(contact.get(`hidden`)) {
                return ``
            }
            const jid = contact.get('jid');
            const extra_classes = [];
            if (isUniView()) {
                const chatbox = _converse.chatboxes.get(jid);
                if (chatbox && !chatbox.get('hidden')) {
                    extra_classes.push('open');
                }
            }
            const ask = contact.get('ask');
            const requesting  = contact.get('requesting');
            const subscription = contact.get('subscription');
            if ((ask === 'subscribe') || (subscription === 'from')) {
                /* ask === 'subscribe'
                 *      Means we have asked to subscribe to them.
                 *
                 * subscription === 'from'
                 *      They are subscribed to us, but not vice versa.
                 *      We assume that there is a pending subscription
                 *      from us to them (otherwise we're in a state not
                 *      supported by converse.js).
                 *
                 *  So in both cases the user is a "pending" contact.
                 */
                extra_classes.push('pending-xmpp-contact');
            } else if (requesting === true) {
                extra_classes.push('requesting-xmpp-contact');
            } else if (subscription === 'both' || subscription === 'to' || u.isSameBareJID(jid, _converse.connection.jid)) {
                extra_classes.push('current-xmpp-contact');
                extra_classes.push(subscription);
                extra_classes.push(contact.presence.get('show'));
            }
            return html`
            ${index===0 ? html `<div class="alpha_heading pl-4 py-2 my-3">${alpha_heading}</div>` : ``}
                <li class="list-item d-flex controlbox-padded ${extra_classes.join(' ')} list-item-height-4em pl-4 my-2" data-status="${contact.presence.get('show')}">
                    <converse-roster-contact .data=${{disable_trash: true}} .model=${contact}></converse-roster-contact>            
                </li>
                `
        })
    })
}

export default  (o) => {
    const collapsed = _converse.roster.state.get('collapsed_groups');
    return html`
        <div class="roster-group" data-group="${o.name}">
            <ul class="items-list roster-group-contacts ${ (collapsed.includes(o.name)) ? 'collapsed' : '' }" data-group="${o.name}">

                ${ 
                    renderContact(o.contacts)
                }
            </ul>
        </div>`;
}
