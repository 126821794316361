import { CustomElement } from 'shared/components/element.js';
import tpl_avatar from './templates/avatar.js';
import { _converse, api } from '@converse/headless/core';
import { LOCAL_STORAGE } from '../../shared/constants';
import { Strophe } from 'strophe.js/src/strophe';
import { isThemeDracula } from '../../utils/helper.js'

import './avatar.scss';

export default class Avatar extends CustomElement {
    static get properties() {
        return {
            data: { type: Object },
            width: { type: String },
            height: { type: String },
            nonce: { type: String }, // Used to trigger rerenders
        };
    }

    constructor() {
        super();
        this.width = 36;
        this.height = 36;
    }

    render() {
        const image_type = this.data?.image_type || _converse.DEFAULT_IMAGE_TYPE;
        let image;
        if (this.data?.data_uri) {
            image = this.data?.data_uri;
        } else {
            let image_data = this.data?.image || _converse.DEFAULT_IMAGE;
            image =
                image_data == 'undefined' || image_data == undefined || image_data.length == 0
                    ? _converse.DEFAULT_IMAGE
                    : image_data.startsWith('https://')
                    ? image_data
                    : 'data:' + image_type + ';base64,' + image_data;
        }
        if (image.split(`DEFAULT_IMAGE`).length > 1) {
            const let_domain = this?.data?.jid ? Strophe.getDomainFromJid(this?.data?.jid) : this?.data?.jid;
            let_domain === Strophe.NS.MUCLIGHT_DOMAIN
                ? (image = `/images/default-group-icon/${Math.floor(Math.random() * 5)}.png`)
                : (image = `/images/${isThemeDracula() ? '/hookz-office/': ''}default-avatars/${Math.floor(Math.random() * 4)}.${isThemeDracula() ? 'svg': 'png'}`);
        }
        const fullName = this.data?.fullname || '';

        return tpl_avatar({
            'classes': this.getAttribute('class'),
            'height': this.height,
            'width': this.width,
            image,
            image_type,
            fullName,
        });
    }
}

api.elements.define('converse-avatar', Avatar);
