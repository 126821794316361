import log from '@converse/headless/log.js';
import { CustomElement } from 'shared/components/element.js';
import { __ } from 'i18n';
import { _converse, api, converse } from '@converse/headless/core.js';
import { getAppSettings } from '@converse/headless/shared/settings/utils.js';
import { getMediaURLs } from '@converse/headless/shared/chat/utils.js';
import { html } from 'lit';
import { isMediaURLDomainAllowed, isDomainWhitelisted } from '@converse/headless/utils/url.js';
import { until } from 'lit/directives/until.js';
import 'plugins/chatview/forward-message.js'
import 'plugins/chatview/delete-message.js'
import './styles/message-actions.scss';

const { Strophe, u } = converse.env;

class MessageActions extends CustomElement {
    static get properties () {
        return {
            is_retracted: { type: Boolean },
            model: { type: Object },
            is_custom: { type: Boolean }
        };
    }

    initialize () {
        const settings = getAppSettings();
        this.listenTo(settings, 'change:allowed_audio_domains', () => this.requestUpdate());
        this.listenTo(settings, 'change:allowed_image_domains', () => this.requestUpdate());
        this.listenTo(settings, 'change:allowed_video_domains', () => this.requestUpdate());
        this.listenTo(settings, 'change:render_media', () => this.requestUpdate());
        this.listenTo(this.model, 'change', () => this.requestUpdate());
    }

    render () {
        return html`${until(this.renderActions(), '')}`;
    }

    async renderActions () {
        // We want to let the message actions menu drop upwards if we're at the
        // bottom of the message history, and down otherwise. This is to avoid
        // the menu disappearing behind the bottom panel (toolbar, textarea etc).
        // That's difficult to know from state, so we're making an approximation here.
        const should_drop_up = this.model.collection.length > 2 && this.model === this.model.collection.last();

        const buttons = await this.getActionButtons();
        const items = buttons.map(b => MessageActions.getActionsDropdownItem(b, true, this.is_custom));
        if (items.length) {
            return html`<converse-dropdown
                class="chat-msg__actions ${should_drop_up ? 'dropup dropup--left' : 'dropleft'} message-from-${this?.model?.get(`sender`)}"
                .items=${items}
                .isCustom=${this.is_custom}
            ></converse-dropdown>`;
        } else {
            return '';
        }
    }

    static getActionsDropdownItem (o, useFaIcons = false, is_custom = false) {
        return html `
        <button type="button" class="list-group-item list-group-item-action d-flex justify-content-between ${o.button_class}" @click=${o.handler} style="border-top: 1px solid #F8F8F8;">
            <span>${o.i18n_text}</span>
            <span class="ml-5">
                ${o.svg}
            </span>
        </button>`;
        // return !is_custom 
        //     ? html`
        //     <button class="chat-msg__action ${o.button_class}" @click=${o.handler}>
        //         ${useFaIcons 
        //         ? html`
        //         <i class="${o.icon_class}"></i>`
        //         : html`
        //         <converse-icon
        //             class="${o.icon_class}"
        //             color="var(--text-color-lighten-15-percent)"
        //             size="1em"
        //         >
        //         </converse-icon>`}
        //             ${o.i18n_text}
        //         </button>`
        //     : html `
        //     <button type="button" class="list-group-item list-group-item-action d-flex justify-content-between ${o.button_class}" @click=${o.handler} style="border-top: 1px solid #F8F8F8;">
        //         <span>${o.i18n_text}</span>
        //         <span class="ml-5">
        //             ${o.svg}
        //         </span>
        //     </button>`;
    }

    async onMessageEditButtonClicked (ev) {
        ev.preventDefault();
        const currently_correcting = this.model.collection.findWhere('correcting');
        // TODO: Use state intead of DOM querying
        // Then this code can also be put on the model
        const unsent_text = u.ancestor(this, '.chatbox')?.querySelector('.chat-textarea')?.value;
        if (unsent_text && (!currently_correcting || currently_correcting.getMessageText() !== unsent_text)) {
            const result = await api.confirm(__('You have an unsent message which will be lost if you continue. Are you sure?'));
            if (!result) return;
        }
        if (currently_correcting !== this.model) {
            currently_correcting?.save('correcting', false);
            this.model.save('correcting', true);
        } else {
            this.model.save('correcting', false);
        }
    }
    async onDeleteMessage(ev) {
        ev?.preventDefault?.();
        api.modal.show('converse-delete-message-modal', { 'model': this.model, ev }, ev)

    }
    async onDirectMessageRetractButtonClicked () {
        if (this.model.get('sender') !== 'me') {
            return log.error("onMessageRetractButtonClicked called for someone else's message!");
        }
        const retraction_warning = __(
            'Be aware that other XMPP/Jabber clients (and servers) may ' +
                'not yet support retractions and that this message may not ' +
                'be removed everywhere.'
        );
        const messages = [__('Are you sure you want to delete this message?')];
        if (api.settings.get('show_retraction_warning')) {
            messages[1] = retraction_warning;
        }
        const result = await api.confirm(__('Delete'), messages);
        if (result) {
            const chatbox = this.model.collection.chatbox;
            chatbox.retractOwnMessage(this.model);
        }
    }

    /**
     * Retract someone else's message in this groupchat.
     * @private
     * @param { _converse.Message } message - The message which we're retracting.
     * @param { string } [reason] - The reason for retracting the message.
     */
    async retractOtherMessage (reason) {
        const chatbox = this.model.collection.chatbox;
        const result = await chatbox.retractOtherMessage(this.model, reason);
        if (result === null) {
            const err_msg = __(`A timeout occurred while trying to retract the message`);
            api.alert('error', __('Error'), err_msg);
            log(err_msg, Strophe.LogLevel.WARN);
        } else if (u.isErrorStanza(result)) {
            const err_msg = __(`Sorry, you're not allowed to retract this message.`);
            api.alert('error', __('Error'), err_msg);
            log(err_msg, Strophe.LogLevel.WARN);
            log(result, Strophe.LogLevel.WARN);
        }
    }

    async onMUCMessageRetractButtonClicked () {
        const retraction_warning = __(
            'Be aware that other XMPP/Jabber clients (and servers) may ' +
                'not yet support retractions and that this message may not ' +
                'be removed everywhere.'
        );

        if (this.model.mayBeRetracted()) {
            const messages = [__('Are you sure you want to delete this message?')];
            if (api.settings.get('show_retraction_warning')) {
                messages[1] = retraction_warning;
            }
            if (await api.confirm(__('Confirm'), messages)) {
                const chatbox = this.model.collection.chatbox;
                chatbox.retractOwnMessage(this.model);
            }
        } else if (await this.model.mayBeModerated()) {
            if (this.model.get('sender') === 'me') {
                let messages = [__('Are you sure you want to delete this message?')];
                if (api.settings.get('show_retraction_warning')) {
                    messages = [messages[0], retraction_warning, messages[1]];
                }
                !!(await api.confirm(__('Confirm'), messages)) && this.retractOtherMessage();
            } else {
                let messages = [
                    __('You are about to retract this message.'),
                    __('You may optionally include a message, explaining the reason for the retraction.'),
                ];
                if (api.settings.get('show_retraction_warning')) {
                    messages = [messages[0], retraction_warning, messages[1]];
                }
                const reason = await api.prompt(__('Message Retraction'), messages, __('Optional reason'));
                reason !== false && this.retractOtherMessage(reason);
            }
        } else {
            const err_msg = __(`Sorry, you're not allowed to retract this message`);
            api.alert('error', __('Error'), err_msg);
        }
    }

    onMessageRetractButtonClicked (ev) {
        ev?.preventDefault?.();
        const chatbox = this.model.collection.chatbox;
        if (chatbox.get('type') === _converse.CHATROOMS_TYPE) {
            this.onMUCMessageRetractButtonClicked();
        } else {
            this.onDirectMessageRetractButtonClicked();
        }
    }

    async onMessageReply (ev,data) {
        ev?.preventDefault?.();
        const chatbox = this.model.collection.chatbox;
        await chatbox.save({reply_msg_id: data.model.get(`msgid`)})
    }

    async onMessageForward (ev) {
        ev?.preventDefault?.();
        api.modal.show('converse-forward-message-modal', { 'model': this.model, ev }, ev)
    }

    async onReactToMessage (ev, data) {
        ev?.preventDefault?.();
        const chatbox = await this.model.collection.chatbox;
        for (const chatboxData of chatbox.messages.models) {
            chatboxData.get(`show_emoji_icon_force`) ? await chatboxData.save({show_emoji_icon_force: false}) : ``
        }
        await data.model.save({show_emoji_icon_force: true, show_emoji_icon_force_timer: Date.now(), hide_emoji_icon: false});
        api.trigger(`default_emoji_icons_menu`, { ev, default_emoji_icons_menu: true })
    }

    onMediaToggleClicked (ev) {
        ev?.preventDefault?.();

        if (this.hasHiddenMedia(this.getMediaURLs())) {
            this.model.save({
                'hide_url_previews': false,
                'url_preview_transition': 'fade-in',
            });
        } else {
            const ogp_metadata = this.model.get('ogp_metadata') || [];
            if (ogp_metadata.length) {
                this.model.set('url_preview_transition', 'fade-out');
            } else {
                this.model.save({
                    'hide_url_previews': true,
                    'url_preview_transition': 'fade-in',
                });
            }
        }
    }

    /**
     * Check whether media is hidden or shown, which is used to determine the toggle text.
     *
     * If `render_media` is an array, check if there are media URLs outside
     * of that array, in which case we consider message media on the whole to be hidden (since
     * those excluded by the whitelist will be, even if the render_media whitelisted URLs are shown).
     * @param { Array<String> } media_urls
     * @returns { Boolean }
     */
    hasHiddenMedia (media_urls) {
        if (typeof this.model.get('hide_url_previews') === 'boolean') {
            return this.model.get('hide_url_previews');
        }
        const render_media = api.settings.get('render_media');
        if (Array.isArray(render_media)) {
            return media_urls.reduce((acc, url) => acc || !isDomainWhitelisted(render_media, url), false);
        } else {
            return !render_media;
        }
    }

    getMediaURLs () {
        const unfurls_to_show = (this.model.get('ogp_metadata') || [])
            .map(o => ({ 'url': o['og:image'], 'is_image': true }))
            .filter(o => isMediaURLDomainAllowed(o));

        const media_urls = getMediaURLs(this.model.get('media_urls') || [], this.model.get('body'))
            .filter(o => isMediaURLDomainAllowed(o));

        return [...new Set([...media_urls.map(o => o.url), ...unfurls_to_show.map(o => o.url)])];
    }

    /**
     * Adds a media rendering toggle to this message's action buttons if necessary.
     *
     * The toggle is only added if the message contains media URLs and if the
     * user is allowed to show or hide media for those URLs.
     *
     * Whether a user is allowed to show or hide domains depends on the config settings:
     * * allowed_audio_domains
     * * allowed_video_domains
     * * allowed_image_domains
     *
     * Whether media is currently shown or hidden is determined by the { @link hasHiddenMedia } method.
     *
     * @param { Array<MessageActionAttributes> } buttons - An array of objects representing action buttons
     */
    // addMediaRenderingToggle (buttons) {
    //     const urls = this.getMediaURLs();
    //     if (urls.length) {
    //         const hidden = this.hasHiddenMedia(urls);
    //         buttons.push({
    //             'i18n_text': hidden ? __('Show media') : __('Hide media'),
    //             'handler': ev => this.onMediaToggleClicked(ev),
    //             'button_class': 'chat-msg__action-hide-previews',
    //             'icon_class': hidden ? 'fas fa-eye' : 'fas fa-eye-slash',
    //             'name': 'hide',
    //         });
    //     }
    // }

    async getActionButtons () {
        const buttons = [];
        
        buttons.push({
            'i18n_text': __('Reply'),
            'handler': ev => this.onMessageReply(ev,this),
            'button_class': 'chat-msg__action-reply',
            'icon_class': 'fas fa-reply',
            'name': 'reply',
            'svg': html`<svg xmlns="http://www.w3.org/2000/svg" width="17.807" height="15.339" viewBox="0 0 17.807 15.339">
                        <path id="Icon_material-reply" data-name="Icon material-reply" d="M10.959,11.191V7.5L4.5,13.959l6.459,6.459V16.635c4.614,0,7.844,1.476,10.15,4.706C20.187,16.728,17.419,12.114,10.959,11.191Z" transform="translate(-3.793 -6.293)" fill="none" stroke="#003254" stroke-width="1"/>
                    </svg>`
        });

        buttons.push({
            'i18n_text': __('Forward'),
            'handler': ev => this.onMessageForward(ev),
            'button_class': 'chat-msg__action-forward',
            'icon_class': 'fas fa-share',
            'name': 'forward',
            'svg': html`<svg xmlns="http://www.w3.org/2000/svg" width="17.807" height="15.339" viewBox="0 0 17.807 15.339">
                        <path id="Icon_material-reply" data-name="Icon material-reply" d="M14.65,11.191V7.5l6.459,6.459L14.65,20.419V16.635c-4.614,0-7.844,1.476-10.15,4.706C5.423,16.728,8.191,12.114,14.65,11.191Z" transform="translate(-4.01 -6.293)" fill="none" stroke="#003254" stroke-width="1"/>
                    </svg>`
        });

        buttons.push({
            'i18n_text': __('React to message'),
            'handler': ev => this.onReactToMessage(ev, this),
            'button_class': 'chat-msg__action-forward react-to-message',
            'icon_class': 'fas fa-share',
            'name': 'forward',
            'svg': html`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <circle cx="12" cy="12" r="10" fill="white" stroke="grey" stroke-width="2" />
            <circle cx="8" cy="10" r="1.5" fill="grey" />
            <circle cx="16" cy="10" r="1.5" fill="grey" />
            <path d="M7 15.5c4 3.5 8 0 10 0" stroke="grey" stroke-width="2" stroke-linecap="round" />
          </svg>
          `,
        });

        if (this.model.get('editable') && false) {
            /**
             * @typedef { Object } MessageActionAttributes
             * An object which represents a message action (as shown in the message dropdown);
             * @property { String } i18n_text
             * @property { Function } handler
             * @property { String } button_class
             * @property { String } icon_class
             * @property { String } name
             */
            buttons.push({
                'i18n_text': this.model.get('correcting') ? __('Cancel Editing') : __('Edit'),
                'handler': ev => this.onMessageEditButtonClicked(ev),
                'button_class': 'chat-msg__action-edit',
                'icon_class': 'fa fa-pencil-alt',
                'name': 'edit',
                'svg': html`<svg xmlns="http://www.w3.org/2000/svg" width="17.807" height="15.339" viewBox="0 0 17.807 15.339">
                            <path id="Icon_material-reply" data-name="Icon material-reply" d="M14.65,11.191V7.5l6.459,6.459L14.65,20.419V16.635c-4.614,0-7.844,1.476-10.15,4.706C5.423,16.728,8.191,12.114,14.65,11.191Z" transform="translate(-4.01 -6.293)" fill="none" stroke="#003254" stroke-width="1"/>
                        </svg>`
            });
        }

        // const may_be_moderated = ['groupchat', 'mep'].includes(this.model.get('type')) && (await this.model.mayBeModerated());
        const may_be_moderated = ['groupchat', 'mep'].includes(this.model.get('type'));
        const retractable = !this.is_retracted && (this.model.mayBeRetracted() || may_be_moderated);
        if (retractable) {
            buttons.push({
                'i18n_text': __('Delete'),
                'handler': ev => this.onDeleteMessage(ev),
                // 'handler': ev => this.onMessageRetractButtonClicked(ev),
                'button_class': 'chat-msg__action-retract text-danger',
                'icon_class': 'fas fa-trash-alt',
                'name': 'retract',
                'svg': html`<svg id="Group_14883" data-name="Group 14883" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="13.724" height="15.099" viewBox="0 0 13.724 15.099">
                            <defs>
                                <clipPath id="clip-path">
                                <rect id="Rectangle_4114" data-name="Rectangle 4114" width="13.724" height="15.099" fill="#fe3116"/>
                                </clipPath>
                            </defs>
                            <g id="Group_14882" data-name="Group 14882" clip-path="url(#clip-path)">
                                <path id="Path_8195" data-name="Path 8195" d="M1.556,3.509c-.381,0-.743,0-1.106,0a.443.443,0,0,1-.434-.564.435.435,0,0,1,.367-.317A2,2,0,0,1,.589,2.62h3.2c0-.243,0-.472,0-.7A1.91,1.91,0,0,1,5.275.024a.865.865,0,0,1,.2-.02C6.353,0,7.228.011,8.1,0a1.726,1.726,0,0,1,1.3.578,1.878,1.878,0,0,1,.525,1.38c0,.215,0,.43,0,.662h.191q1.519,0,3.037,0a.921.921,0,0,1,.262.027.439.439,0,0,1,.3.471.447.447,0,0,1-.433.389c-.314.006-.629,0-.944,0h-.181V3.7q0,4.982,0,9.965a1.314,1.314,0,0,1-.761,1.289,1.4,1.4,0,0,1-.58.135q-3.959.013-7.917,0a1.344,1.344,0,0,1-1.348-1.438q0-3.906,0-7.813V3.509Zm.9.009v.173q0,4.981,0,9.962c0,.405.155.56.56.56H10.7c.414,0,.574-.157.574-.566q0-4.981,0-9.962V3.518ZM4.661,2.61H9.04c0-.225,0-.441,0-.656A1.036,1.036,0,0,0,7.964.86Q6.858.849,5.752.86a1.013,1.013,0,0,0-1.04.8,8.293,8.293,0,0,0-.051.945" transform="translate(0 0)" fill="#fe3116"/>
                                <path id="Path_8196" data-name="Path 8196" d="M111.323,233.586c0-.658,0-1.316,0-1.974a.446.446,0,0,1,.479-.491.428.428,0,0,1,.4.349.864.864,0,0,1,.016.161q0,1.96,0,3.919a.468.468,0,0,1-.3.492.451.451,0,0,1-.6-.466c0-.619,0-1.238,0-1.856q0-.066,0-.133" transform="translate(-107.205 -222.57)" fill="#fe3116"/>
                                <path id="Path_8197" data-name="Path 8197" d="M174.285,233.57c0,.673,0,1.346,0,2.019a.439.439,0,0,1-.581.435.456.456,0,0,1-.3-.47q0-1.672,0-3.345c0-.216,0-.432,0-.648a.438.438,0,0,1,.447-.462.43.43,0,0,1,.438.453c0,.673,0,1.346,0,2.019" transform="translate(-166.984 -222.55)" fill="#fe3116"/>
                                <path id="Path_8198" data-name="Path 8198" d="M235.448,233.582c0-.668,0-1.336,0-2a.443.443,0,1,1,.885-.02q0,2.026,0,4.052a.447.447,0,0,1-.448.451.439.439,0,0,1-.436-.446c0-.678,0-1.356,0-2.033Z" transform="translate(-226.738 -222.562)" fill="#fe3116"/>
                            </g>
                        </svg>`
            });
        }

        if (!this.model.collection) {
            // While we were awaiting, this model got removed from the
            // collection (happens during tests)
            return [];
        }

        // this.addMediaRenderingToggle(buttons);

        /**
         * *Hook* which allows plugins to add more message action buttons
         * @event _converse#getMessageActionButtons
         * @example
         *  api.listen.on('getMessageActionButtons', (el, buttons) => {
         *      buttons.push({
         *          'i18n_text': 'Foo',
         *          'handler': ev => alert('Foo!'),
         *          'button_class': 'chat-msg__action-foo',
         *          'icon_class': 'fa fa-check',
         *          'name': 'foo'
         *      });
         *      return buttons;
         *  });
         */
        return api.hook('getMessageActionButtons', this, buttons);
    }
}

api.elements.define('converse-message-actions', MessageActions);
