import { _converse, api } from '@converse/headless/core';
import { html } from 'lit';

import { blobToFile, getPlaceOnGoogleMapDataByName, getNearByPlacesOnGoogleMapDataByLatLong, getGoogleMapDataByLatLong, getThumbnailForVideo, sendStoryWithPrivacy, uploadImageAndGetLink, uploadMedia } from '@converse/headless/utils/serverApis';

import '../../../styles/moment-upload-options.scss';
import Helper from '../../../../../utils/helper';
import { STORY_ACTIVITY } from '@converse/headless/plugins/muclight/constants';

export default (jsonData = {}) => {
    let checkin = `Check In`
    if(jsonData.storyCheckInData) {
        let split_storyCheckInData = jsonData.storyCheckInData.split(`@@@`)
        checkin = split_storyCheckInData.length>1 ? split_storyCheckInData[1] : checkin
    }
    if(jsonData.storyCheckIn) {
        jsonData.storyCheckInError = `Please Wait...`
        checkin = `Please Wait...`
        storyCheckIn()
    }
    if (jsonData?.capturedImage || jsonData?.capturedVideo) {
        uploadUserMedia(jsonData?.imageUrl, jsonData?.capturedImage, jsonData?.capturedVideo);
    }

    function storyTaggingList(ev){
        ev?.preventDefault()
        if(jsonData?.tag_friends) {
             const tagging_list = Object.entries(jsonData?.tag_friends).map( (key,index) => {
                return html `${key[1].nickname},`
            })
            return html `<span class="story-tag-friends">${tagging_list}</span>`
        }else{
            return html `Tag friends`
        }
    }
    async function CheckInSearch(ev) {
        ev?.preventDefault()
        try{
            const Lat_Long = await Helper.geoLocation()
            if(Lat_Long.status) {
                const latitude = Lat_Long.data.coords.latitude
                const longitude = Lat_Long.data.coords.longitude
            }
        }catch(e){
            return e
        }
        return true

    }
    function storyCheckIn(ev){
        ev?.preventDefault()
            return new Promise( async(resolve, reject) => {
                try{
                    const Lat_Long = await Helper.geoLocation()
                    if(Lat_Long.status) {
                        const latitude = Lat_Long.data.coords.latitude
                        const longitude = Lat_Long.data.coords.longitude
                        const gmapdata = await getGoogleMapDataByLatLong(latitude,longitude)
                        jsonData.storyCheckIn = !jsonData.storyCheckIn
                        if(gmapdata.status==`200`) {
                            let city_append = ``
                            if(gmapdata.data.results.length) {
                                for(const result of gmapdata.data.results) {
                                    const address_components = result.address_components
                                    if(address_components?.length) {
                                        for(const address_components_result of address_components) {
                                            const types = address_components_result.types
                                            if(types.includes("locality") || types.includes("administrative_area_level_3"))
                                            {
                                                city_append = address_components_result.long_name
                                            }
                                        }
                                    }
                                }
                            }
                            // const getnearplaces = await getNearByPlacesOnGoogleMapDataByLatLong(`latitude,longitude`)
                            // if(getnearplaces.status=="OK") {
                            //     jsonData.nearByPlaces = getnearplaces.results
                            // }
                            jsonData.storyCheckInData = `geo:${latitude},${longitude}@@@${gmapdata.data.results[0].formatted_address}@@@true@@@${gmapdata.data.results[0].formatted_address}`
                            jsonData.latitude = latitude
                            jsonData.longitude = longitude
                            jsonData.storyCheckInData = `geo:${latitude},${longitude}@@@${gmapdata.data.results[0].formatted_address}@@@true@@@${gmapdata.data.results[0].formatted_address}`
                            jsonData.storyCheckInSearchBar = true
                            jsonData.searchKey = gmapdata.data.results[0].formatted_address
                            jsonData.storyCheckInError = ``
                            api.trigger('moment-upload', jsonData);
                            resolve(true)
                        }else{
                            jsonData.storyCheckInData = ``
                            jsonData.storyCheckInError = `Unable Fetch Your Location`
                            jsonData.storyCheckInSearchBar = true
                            api.trigger('moment-upload', jsonData);
                            resolve(true)    
                        }
                    }
        
                }catch(e){
                    reject(e)
                }
                return true
            })
    
    }
    function showActivityIcon()
    {
        return `🔥`
        const random = Math.floor(Math.random() * Object.entries(STORY_ACTIVITY).length);
        return Object.entries(STORY_ACTIVITY)[random][0]
    }
    function storyActivity(ev){
        ev?.preventDefault()
        return Object.entries(STORY_ACTIVITY).map( (key,index) => {
            return html `<span class="story-activity-data" @click=${ (ev) => {
                selectActivity(key[0])
            }}>${key[0]} ${key[1]}</span>`
        })
    }
    function selectActivity(index) {
            jsonData.storyActivityIcon = index
            jsonData.storyActivityData = STORY_ACTIVITY[index] || ``
            api.trigger('moment-upload', jsonData)
    }
    function handleSelectionType(option) {
        const data = {
            step: 1,
            cameraSeleted: option === 1 ? !jsonData?.cameraSeleted : false,
            videoSelected: option === 2 ? !jsonData?.videoSelected : false,
        };
        let optionSelected = false;
        Object.values(data).forEach((value, index) => {
            if (index && value) {
                optionSelected = true;
            }
        });
        data.optionSelected = optionSelected
        api.trigger('moment-upload', data);
    }

    async function handleNext( cameraSeleted, videoSelected ) {
        // const { cameraSeleted, videoSelected } = jsonData;
        let step;
        let stream;
        let showBackBtn = false;
        let uploadError;
        if (cameraSeleted || videoSelected) {
            step = 4;
            stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
            showBackBtn = true;
        } else {
            step = 3;
            // uploadError = 'Please select one of the options';
        }
        api.trigger('moment-upload', {
            ...jsonData,
            step,
            stream,
            cameraSeleted,
            videoSelected,
            showBackBtn,
            uploadError,
        });
    }
    
    async function onFileSelection(e) {
        const files = e.target.files[0];
        if (files.type.startsWith('video/')) {
            const [file] = e.target.files;
            const fileUrl = URL.createObjectURL(file);
            const thumb_data = await getThumbnailForVideo(fileUrl,true);
            if(thumb_data.success) {
                jsonData.avatarUrl = thumb_data.data.url
            }
            const duration = await getVideoDuration(files);
            if (duration > 30) {
                api.trigger('moment-upload', {
                    ...jsonData,
                    step: 1,
                    showBackBtn: false,
                    uploadError: 'Video should not be more than 30 seconds',
                });
                return
            }
        }
        uploadUserMedia(e.target.files);
    }


    async function getVideoDuration (f) {
        const fileCallbackToPromise = (fileObj) => {
          return Promise.race([
            new Promise((resolve) => {
              if (fileObj instanceof HTMLVideoElement) fileObj.onloadedmetadata = resolve;
              else fileObj.onload = resolve;
            }),
            new Promise((_, reject) => {
              setTimeout(reject, 1000);
            }),
          ]);
        };
        const objectUrl = URL.createObjectURL(f);
        const video = document.createElement("video");
        video.src = objectUrl;
        await fileCallbackToPromise(video);
        return video.duration;
    }

    function generateRandomString(length) {
        let randomString = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            randomString += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return randomString;
    }

    function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
        else byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        let ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
    }

    async function uploadUserMedia(files, isImageCaptured, isVideoCaptured) {
        try {
            let file, step, imageUrl;
            if (isImageCaptured) {
                file = dataURItoBlob(jsonData?.imageUrl);
            } else if (isVideoCaptured) {
                file = jsonData?.imageUrl;
            } else {
                file = files[0];
            }
            const formData = new FormData();
            formData.append('image', file);
            const user = JSON.parse(localStorage.getItem('user'));
            const jid = user.jid;
            api.trigger('moment-upload', { ...jsonData, ...{ step: 1, showLoader: true } });
            const response = await uploadMedia(formData, jid);
            if(!response.status){
                step = 1
                jsonData.uploadError = response?.message;
            }else{
                step = 2
                imageUrl = await response?.result?.link;
            }
            api.trigger('moment-upload', {
                ...jsonData,
                step: step,
                imageUrl,
                file_type: file?.type,
                capturedImage: false,
                capturedVideo: false
            });
        } catch (e) {
            console.log(e);
        }
    }

    function updateCaption(ev) {
        // ev.target.value = ev.target.value.replace(/[^a-zA-Z0-9 _-~`!@#$%^&*()+=*]/g, '');
        jsonData.caption = Helper.escapeHtml(ev.target.value)
    }

    async function uploadMoment(data) {
        let uploadSuccess;
        let uploadMessage;
        try {
            data?.preventDefault();
            const user = JSON.parse(localStorage.getItem('user'));
            const jid = user.jid;
            const { storyTaggedFriend, selected_friends, tag_friends, imageUrl, file_type, caption, avatarUrl, storyCheckInData, storyActivityIcon } = jsonData;
            const custom_friends = selected_friends || []
            const currentTimeInMiliSeconds = Date.now();
            const randomString = generateRandomString(7);
            const phoneNumber = user?.mobile;
            const fullName = user?.profile?.nickName;
            const body = {
                storyId: randomString,
                payload: JSON.stringify({
                    uid: '',
                    phoneNumber,
                    storiesData: [
                        {
                            url: imageUrl,
                            storyCheckIn: storyCheckInData ? storyCheckInData : ``,
                            storyActivity: storyActivityIcon ? storyActivityIcon : ``,
                            id: randomString,
                            caption,
                            storyTaggedFriend: storyTaggedFriend ? JSON.stringify(storyTaggedFriend) : null,
                            mime_type: file_type,
                        },
                    ],
                    postDate: currentTimeInMiliSeconds,
                    avatarUrl: avatarUrl ? avatarUrl : ``,
                    fullName,
                }),
                story_privacy: custom_friends.length ? '1' : '2',
                custom_friends: custom_friends.join(`,`),
            };
            api.trigger('moment-upload', { ...jsonData, ...{ step: 1, showLoader: true } });
            const response = await sendStoryWithPrivacy(body);
            if (response.status) {
                const chat = await api.chats.get(_converse.bare_jid);
                await chat.createStoryMessage(JSON.parse(body.payload));
                uploadSuccess = true
                uploadMessage = 'Uploaded successfully!';
            } else {
                await api.confirm(`Error While Sending Story.`);
                uploadSuccess = false
                uploadMessage = 'Error While Sending Story.';
            }
        } catch (e) {
            console.log(e);
        } finally {
            api.trigger('moment-upload', { ...jsonData,  step: 1, uploadSuccess, uploadMessage });
        }
    }

    async function handleRetake(){
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
        api.trigger('moment-upload', {
            ...jsonData,
            step: 4,
            stream
        });
    }

    function handleFileSelect() {
        jsonData.cameraSeleted = false;
        jsonData.videoSelected = false;
        jsonData.librarySelected = true;
        const fileInput = document.getElementById('mediaFileInput');
        fileInput.click();
    }

      function handleClose() {
        delete jsonData?.uploadError;
        api.trigger('moment-upload', { ...jsonData, step: 0 });
    }

    function handleBack() {
        const { step } = jsonData;
        jsonData.capturedImage = false;
        jsonData.capturedVideo = false;
        api.trigger('moment-upload', { ...jsonData, step: step - 1, cameraSeleted: false, videoSelected: false });
    }

    if (jsonData?.showLoader || jsonData?.uploadSuccess === true) {
        if (jsonData?.uploadSuccess) {
            setTimeout(handleClose, 2000)
        }
        return html`
            <div class="add-moment-main-container">
                <div class="add-moment-sub-container d-flex flex-column px-5 py-4">
                    <div class="moments-media-selection-parent d-flex flex-column align-items-center" style="height: 30vh;">
                        ${jsonData?.uploadSuccess
                        ? html`
                        <h1>
                            <span class="${jsonData?.uploadSuccess ? 'text-success' : 'text-danger'}">${jsonData?.uploadMessage}</span>
                        </h1>`
                        : html`
                        <div class="loader"></div>
                        <h1>Uploading...</h1>`}
                    </div>
                </div>
            </div>`; 
    }

    return html` <div class="add-moment-main-container">
        <div class="add-moment-sub-container d-flex flex-column px-5 py-4">
            <div class="add-moment-heading mt-2 row">
                <div class="col-2 d-flex justify-content-start" role="button" @click=${handleBack}>
                    ${false
                        ? html`<img
                              src="../../../../images/moments_icons/back-icon.svg"
                              alt="back"
                              @click=${handleBack}
                          />`
                        : html``}
                </div>
                <div class="col-8 d-flex justify-content-center">
                    <div class="add-moment-heading-text">Upload your Moment</div>
                </div>
                <div class="col-2 d-flex justify-content-end" role="button">
                    <img src="../../../../images/moments_icons/close-icon.svg" alt="close" @click=${handleClose} />
                </div>
            </div>
            ${jsonData?.step === 1 || jsonData?.step === 2
                ? html` <div class="add-moment-error-text">${jsonData?.uploadError}</div>
                      <div class="moments-media-selection-parent d-flex mt-5">
                          ${jsonData?.step === 1
                              ? html` <div class="d-flex flex-column align-items-center mx-3" role="button">
                                        <div @click=${() => handleNext(true, false)}>
                                            <img
                                                src=${jsonData?.cameraSeleted
                                                    ? '../../../../images/moments_icons/camera-selected-icon.svg'
                                                    : '../../../../images/moments_icons/camera-icon.svg'}
                                                alt=${jsonData?.cameraSeleted ? 'camera-selected' : 'camera'}
                                            />
                                        </div>
                                        <div class="mt-4">Camera</div>
                                    </div>
                                    <div class="d-flex flex-column align-items-center mx-3" role="button">
                                        <div>
                                            <img
                                                src=${jsonData.librarySelected
                                                    ? '../../../../images/moments_icons/library-selected-icon.svg'
                                                    : '../../../../images/moments_icons/library-icon.svg'}
                                                alt=${jsonData.librarySelected ? 'library-selected' : 'library'}
                                                @click=${handleFileSelect}
                                            />
                                            <input
                                                type="file"
                                                @change=${onFileSelection}
                                                id="mediaFileInput"
                                                style="display: none;"
                                                accept="image/*, video/mp4, video/x-m4v, video/*"
                                            />
                                        </div>
                                        <div class="mt-4">Library</div>
                                    </div>
                                    <div class="d-flex flex-column align-items-center mx-3" role="button">
                                        <div @click=${() => handleNext(false, true)}>
                                            <img
                                                src=${jsonData?.videoSelected
                                                    ? '../../../../images/moments_icons/video-rec-selected-icon.svg'
                                                    : '../../../../images/moments_icons/video-rec-icon.svg'}
                                                alt=${jsonData?.videoSelected
                                                    ? 'video-recording-selected'
                                                    : 'video-recording'}
                                            />
                                        </div>
                                        <div class="mt-4">Video Recording</div>
                                    </div>`
                              : jsonData?.step === 2 && jsonData?.file_type?.includes('image')
                              ? html`<img class="image-video-preview-on-story" src="${jsonData?.imageUrl}" />`
                              : html` <video class="image-video-preview-on-story" controls>
                                    <source src="${jsonData?.imageUrl}" />
                                </video>`}
                      </div>
                      ${jsonData?.step !== 1
                          ? html` <div class="d-flex moments-media-btn-container justify-content-center my-5">
                                <button
                                    type="button"
                                    class="btn btn-light text-dark btn-rounded cancel-btn"
                                    @click=${jsonData?.librarySelected ? () => {
                                        api.trigger('moment-upload', {
                                            step: 1,
                                            librarySelected: false
                                        });
                                    } : handleRetake}
                                >
                                    ${jsonData?.librarySelected ? "Cancel": "Retake"}
                                </button>
                                <button
                                    type="button"
                                    class=${`btn ${
                                        jsonData?.optionSelected ? 'btn-danger' : 'btn-light text-white'
                                    } btn-rounded next-btn ml-3`}
                                    @click=${() => {
                                        api.trigger('moment-upload', {
                                            ...jsonData,
                                            step: 3,
                                        });
                                    }}
                                >
                                    Next
                                </button>
                            </div>`
                          : html``}`
                : html` <div class="moments-media-selection-parent mt-4">
                      ${jsonData?.step === 3
                          ? html` <div class="preview-story-uploaded-image d-flex">
                                ${jsonData?.file_type.includes('image')
                                    ? html` <img class="image-video-preview-on-story" src="${jsonData?.imageUrl}" />`
                                    : html` <video class="image-video-preview-on-story" controls>
                                          <source src="${jsonData?.imageUrl}" />
                                      </video>`}
                                <form method="POST" class="story-uploading-opion-main-menu-container ml-3">
                                    <div class="d-flex flex-column justify-content-between h-100">
                                        <div class="story-uploading-opion-main-sub-container">
                                            <div class="story-options-check-in-main-container">
                                                <div @click=${() => !jsonData.storyCheckInSearchBar ? api.trigger('moment-upload', { ...jsonData, ...{ storyCheckIn: jsonData.storyCheckIn ? false : true } }) : ``} class="story-options-check-in-sub-container">
                                                    <div style="display: inline-flex;">
                                                        <img style="height: 10px;" src="/images/location-icon.png" alt="?"/>
                                                    </div>
                                                    <div style="display: inline-flex; width: 80%; overflow: hidden;
                                                    text-overflow: ellipsis;
                                                    white-space: nowrap;">
                                                        ${jsonData.storyCheckInSearchBar ? 
                                                            html `<input class="moment-location-serachbar-input" type='text' data="custom-moments-map" @keyup=${ async (ev) => {
                                                                const resultSuggestions = await getPlaceOnGoogleMapDataByName({value: ev.target.value, mapDivId: ev.target.getAttribute('data'), latitude: jsonData.latitude, longitude: jsonData.longitude})
                                                                jsonData.nearByPlaces = resultSuggestions
                                                                api.trigger('moment-upload', jsonData);

                                                            }} value='${jsonData.searchKey || checkin || jsonData.storyCheckInError}'>
                                                            ` : `${checkin || jsonData.storyCheckInError}`}
                                                        
                                                    </div>
                                                    <div style="display: inline-flex; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                                                        <i class="fa fa-search" aria-hidden="true"></i>
                                                    </div>
                                                    <div id="custom-moments-map"></div>
                                                </div>
                                            </div>
                                            ${jsonData?.storyCheckInSearchBar && jsonData?.nearByPlaces?.length ? html `<div style="display: block; height: 100px; overflow: auto;">
                                            ${jsonData.nearByPlaces.map(data => {
                                                return html `<div class="story-options-check-in-suggestions-main-container" @click=${ (ev) => {
                                                    jsonData.storyCheckInData = `geo:${data.geometry.location.lat()},${data.geometry.location.lng()}@@@${data.vicinity}@@@true@@@${data.vicinity}`
                                                    jsonData.latitude = data.geometry.location.lat()
                                                    jsonData.longitude = data.geometry.location.lng()
                                                    jsonData.storyCheckInData = `geo:${data.geometry.location.lat()},${data.geometry.location.lng()}@@@${data.vicinity}@@@true@@@${data.vicinity}`
                                                    jsonData.storyCheckInSearchBar = false
                                                    jsonData.searchKey = data.vicinity
                                                    jsonData.storyCheckInError = ``
                                                    api.trigger('moment-upload', jsonData);
                                                }}>
                                                    <div class="story-options-check-in-sub-container">
                                                        <div style="display: inline-flex;">
                                                            <img style="height: 10px;" src="/images/location-icon.png" alt="?"/>
                                                        </div>
                                                        <div style="display: inline-flex; width: 90%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                                                            ${data.vicinity}
                                                        </div>
                                                    </div>
                                                </div>`
                                            })}</div>` : ``}
                                            <div class="story-options-activity-main-container margin-top-15px ${jsonData.storyActivity ? `height-auto` : ``}">
                                                <div @click=${() => api.trigger('moment-upload', { ...jsonData, ...{ storyActivity: jsonData.storyActivity ? false : true } })} class="story-options-activity-sub-container">
                                                    <div style="display: inline-flex; width:90%;">
                                                        ${jsonData.storyActivityIcon ? `${jsonData.storyActivityIcon} ${jsonData.storyActivityData}` : `${showActivityIcon()} Activity`}
                                                    </div>
                                                    <div style="display: inline-flex;">
                                                    <i class="fas ${ jsonData.storyActivity ? `fa-angle-down` : `fa-angle-right` }" aria-hidden="true"></i>
                                                    </div>
                                                    ${jsonData.storyActivity ? html `<hr/>
                                                    <div style="display: block; width: 100%; height: 100%; overflow: auto;">
                                                        ${storyActivity()}
                                                    </div>
                                                    ` : ``}
                                                </div>
                                            </div>
                                            <div class="story-options-tag-friends-main-container margin-top-15px">
                                                <div @click=${(ev) => api.modal.show('converse-moments-tagging', { model: _converse.roompanel, jsonData, ev }, ev)} class="story-options-tag-friends-sub-container">
                                                    <img style="height: 10px;" src="/images/tag-friends.png" alt="*" />
                                                    ${storyTaggingList()}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="story-options-add-caption-main-container">
                                                <textarea
                                                    @keyup=${updateCaption}
                                                    class="story-options-add-caption-main-container-textarea"
                                                    rows="7"
                                                ></textarea>
                                            </div>
                                            <div class="d-flex justify-content-between post-moment-container">
                                                <button
                                                    type="button"
                                                    class="btn btn-light text-dark btn-rounded cancel-btn"
                                                    @click=${() =>
                                                        api.trigger('moment-upload', { ...jsonData,  step: 1, cameraSelected: false, videoSelected: false })}
                                                >
                                                    Cancel
                                                </button>
                                                ${jsonData?.imageUrl
                                                    ? html` <button
                                                          type="button"
                                                          class=${`btn ${
                                                              jsonData?.optionSelected
                                                                  ? 'btn-danger'
                                                                  : 'btn-light text-white'
                                                          } btn-rounded next-btn ml-3`}
                                                          @click=${ev => uploadMoment(ev)}
                                                      >
                                                          Post
                                                      </button>`
                                                    : html``}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>`
                          : html`${jsonData?.step}-${jsonData?.imageUrl}`}
                  </div>`}
        </div>
    </div>`;
};
