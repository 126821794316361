import { html } from 'lit';
import { _converse, api, converse } from '@converse/headless/core';
import { openChat } from '@converse/headless/plugins/chat/utils';
import 'plugins/muclight-views/modals/add-occupants-muclight.js'
import { CHAT_TYPE } from '@converse/headless/plugins/muclight/constants';
import { isThemeDracula } from '../../../utils/helper.js'; 

const { Strophe, sizzle, u } = converse.env;

export default (o) => {
    const isGroupChat = o.chat_type === CHAT_TYPE.GROUP_CHAT;
    const occupantsLength = isGroupChat && Object.keys(o.info.occupants).length;
    const my_affilation = o.my_affilation
    const occupants = isGroupChat && o.info.occupants;
    let is_admin = false
    Object.keys(occupants).map(key => {
        if(occupants[key].jid  === _converse.bare_jid && occupants[key].type  === "owner"){
            is_admin = true
        }
    })

    function renderEncryptionDiv() {
        if (isThemeDracula()) {
            return html`
            <div id="encryption-div" class="d-flex align-self-center justify-content-center chat__content-encryption-hookzoffice-div mt-3">
                <div class="chat__content-encryption-text d-flex align-items-center my-2">
                    <h5 class="mr-2">This conversation is end-to-end encrypted</h5>
                </div>
            </div>`;
        }
        return html`
        <div id="encryption-div" class="d-flex align-self-center justify-content-center chat__content-encryption-div mt-3">
            <div class="chat__content-encryption-text d-flex align-items-center my-2">
                <h5 class="mr-2">This conversation is end-to-end encrypted</h5>
                <svg xmlns="http://www.w3.org/2000/svg" width="49.132" height="50.903" viewBox="0 0 49.132 50.903">
                    <g id="Group_16262" data-name="Group 16262" transform="translate(0.375 0.431)">
                        <g id="Group_16273" data-name="Group 16273">
                            <path
                                id="Path_11109"
                                data-name="Path 11109"
                                d="M16.731,44.4A22.424,22.424,0,0,1,2.443,23.5V12.758a.764.764,0,0,1,.593-.747l6.258-1.467V5.2a.77.77,0,0,1,.662-.76L24.191,2.467V0L9.622,2.015A3.211,3.211,0,0,0,6.85,5.2V8.607L2.478,9.631A3.211,3.211,0,0,0,0,12.758V23.5A24.867,24.867,0,0,0,15.845,46.674l8.346,3.25V47.3Z"
                                fill="#ff0083"
                                stroke="#fff"
                                stroke-width="0.75"
                            />
                            <path
                                id="Path_11110"
                                data-name="Path 11110"
                                d="M108.141,9.631l-4.372-1.025V5.2A3.211,3.211,0,0,0,101,2.015L86.428,0V2.467l14.235,1.969a.771.771,0,0,1,.663.76v5.348l6.258,1.467a.764.764,0,0,1,.592.747V23.5A22.424,22.424,0,0,1,93.888,44.4l-7.46,2.9v2.622l8.346-3.25A24.868,24.868,0,0,0,110.619,23.5V12.758a3.211,3.211,0,0,0-2.478-3.126"
                                transform="translate(-62.237)"
                                fill="#e91c82"
                                stroke="#fff"
                                stroke-width="0.75"
                            />
                            <path
                                id="Path_11111"
                                data-name="Path 11111"
                                d="M15.58,11.542V16.89L9.323,18.357a.764.764,0,0,0-.593.747V29.847a22.425,22.425,0,0,0,14.288,20.9l7.459,2.9V8.813L16.243,10.782a.771.771,0,0,0-.663.76"
                                transform="translate(-6.287 -6.346)"
                                fill="#e81b81"
                            />
                            <path
                                id="Path_11112"
                                data-name="Path 11112"
                                d="M86.428,52.337v1.311l7.46-2.9a22.425,22.425,0,0,0,14.288-20.9V19.1a.764.764,0,0,0-.593-.747l-6.258-1.467V11.542a.771.771,0,0,0-.663-.76L86.428,8.813V52.337Z"
                                transform="translate(-62.237 -6.346)"
                                fill="#d8006a"
                            />
                            <rect
                                id="Rectangle_5389"
                                data-name="Rectangle 5389"
                                width="21.259"
                                height="14.172"
                                transform="translate(13.561 21.713)"
                                fill="#d6f7ee"
                            />
                            <rect
                                id="Rectangle_5390"
                                data-name="Rectangle 5390"
                                width="2.688"
                                height="2.115"
                                transform="translate(16.249 19.598)"
                                fill="#9dd3e4"
                            />
                            <rect
                                id="Rectangle_5391"
                                data-name="Rectangle 5391"
                                width="2.688"
                                height="2.115"
                                transform="translate(29.078 19.598)"
                                fill="#9dd3e4"
                            />
                            <path
                                id="Path_11113"
                                data-name="Path 11113"
                                d="M73.572,42.555a7.758,7.758,0,1,0-15.516,0v2.1h2.688v-2.1a5.07,5.07,0,1,1,10.14,0v2.1h2.688Z"
                                transform="translate(-41.806 -25.057)"
                                fill="#d6f7ee"
                            />
                            <path
                                id="Path_11114"
                                data-name="Path 11114"
                                d="M83.54,91.472A1.837,1.837,0,1,0,80.7,93.012l-.838,4.043H83.54L82.7,93.012a1.833,1.833,0,0,0,.839-1.541"
                                transform="translate(-57.512 -64.547)"
                                fill="#252735"
                            />
                        </g>
                    </g>
                </svg>
            </div>
        </div>`;
    }

    function renderContactInfo() {
        return html`
        <div class="contactInfo-container profileSidebar sidebarShow px-4 py-3">
                <div class="row">
                    <div class="col-6 d-flex align-items-center">${isGroupChat ? 'Group Info' : 'Contact Info'}</div>
                    <div class="col-6 d-flex justify-content-end closeSidebar" @click=${ (ev) => o.model.save({show_contact_info_side_bar: false})} role="button"><img src="/images/general_assets/hookzApp-cross-gray.png" width="30"/></div>
                </div>
                <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                        <converse-avatar class="avatar contactInfo__avatar" .data=${o.model?.attributes?.image?.length ? o.model.attributes : o.model.vcard?.attributes} nonce=${o.model.vcard?.get('vcard_updated')} height="150" width="150"></converse-avatar>
                    </div>
                    <div class="col-12 mt-2 text-center">${isGroupChat ?
                        html`${o?.info?.roomname || ``}<br />
                                <small>${occupantsLength} members</small>` :
                                o.nickname || o.model.vcard?.get('fullname') || o.model.vcard?.get('nickname') || _converse.bare_jid}
                    </div>
                </div>
                <div class="contactInfo-container-control-features p-3">
                    <div class="contactInfo-container-control-features-mute">
                        <div class="contactInfo-container-control-features-mute-sub">
                            <div><img src="/images/general_assets/hookzApp-mute-icon-yellow.png" /></div>
                            <div>Mute</div>
                        </div>
                        <div>
                            <label class="form-switch">
                                <input type="checkbox" />
                                <i></i>
                            </label>
                        </div>
                    </div>
                    <hr />
                    <div class="contactInfo-container-control-features-mute">
                        <div class="contactInfo-container-control-features-mute-sub">
                            <div><img src="/images/general_assets/hookzApp-star-icon-blue.png" /></div>
                            <div>Starred Messages</div>
                        </div>
                    </div>
                    <hr />
                    <div class="contactInfo-container-control-features-mute">
                        <div class="contactInfo-container-control-features-mute-sub">
                            <div><img src="/images/general_assets/hookzApp-erase-yellow.png" /></div>
                            <div>Erase Message</div>
                        </div>
                        <div>
                            <label class="form-switch">
                                <input type="checkbox" />
                                <i></i>
                            </label>
                        </div>
                    </div>
                
                    ${
                        isGroupChat
                            ? html`<hr />
                                <div class="contactInfo-container-control-features-mute">
                                    <div class="contactInfo-container-control-features-mute-sub">
                                        <div><img src="/images/general_assets/HookzApp-group-settings.png" /></div>
                                        <div>Group settings</div>
                                    </div>
                                    <div></div>
                                </div>`
                            : ''
                    }
                </div>
                <div class="contactInfo-container-control-share-div">
                    <div class="contactInfo-container-control-share-div-info">
                        <div class="contactInfo-container-control-share-div-info-img">
                            <div><img src="/images/general_assets/hookzApp-share-icon-green.png" /></div>
                            <div>Shared</div>
                        </div>
                        <div class="contactInfo-container-control-share-div-info-seeAll">See All</div>
                    </div>
                </div>
                <div class="contactInfo-container-control-images-container">
                    <div class="contactInfo-container-control-images-div overflow-auto">
                        <div>
                            <img src="https://i.ibb.co/nnKNNbr/360-F-380747975-s-S1h-CVB0q-Pq-FCWBMZ3q-J5x-Tq-H6rta-DBI.jpg" />
                        </div>
                        <div>
                            <img src="https://i.ibb.co/ZxMBNfJ/for-desktop-nature-scenes-beautiful-scenery-1920x1080-wallpaper-preview.jpg" />
                        </div>
                        <div>
                            <img src="https://i.ibb.co/GxNNQht/photo-1597655601841-214a4cfe8b2c.jpg" />
                        </div>
                        <div>
                            <img src="https://i.ibb.co/vP68jw9/wp9526935.jpg" />
                        </div>
                        <div>
                            <img src="https://i.ibb.co/2yF97fk/ziUwcQ.webp" />
                        </div>
                    </div>
                </div>
                <div>
                    <div class="group-connection-div">
                        <div>${isGroupChat ? 'PARTICIPANTS' : 'Groups in Common'}</div>
                        <div><img src="/images/general_assets/hookzApp-search-gray-icon.png" /></div>
                    </div>
                </div>
                <div class="contactInfo-container-control-features">
                    ${isGroupChat && is_admin
                    ? html`
                    <div class="contactInfo-container-control-features-mute row">
                        <div class="d-flex justify-content-start col-6 py-2">
                            <span style="color: #1492FF;">Add Participants</span>
                        </div>
                        <div class="d-flex justify-content-end col-6 py-2">
                            <button 
                                type="button"
                                class="d-flex justify-content-center align-items-center btn btn-light btn-circle btn-sm custom-box-shadow"
                                @click=${(ev) => api.modal.show('converse-add-occupants-muclight-modal', { 'model': o.model }, ev)}
                            >
                                <converse-icon class="fa fa-plus right" size="1em"></converse-icon>
                            </button>
                        </div>
                    </div>`
                    : ''}
                    
                    <div style="max-height: 30vh; overflow: auto;">
                        ${isGroupChat 
                        ? Object.keys(occupants).map(key => {
                            let converse_avatar = {
                                image:occupants[key].jid  === _converse.bare_jid ? _converse.xmppstatus?.vcard?.get('image') : _converse.roster.get(occupants[key].jid)?.get(`image`) ? _converse.roster.get(occupants[key].jid)?.get(`image`) : occupants[key].image,
                                fullName: _converse.roster.get(occupants[key].jid)?.get(`nickname`)? _converse.roster.get(occupants[key].jid)?.get(`nickname`) : occupants[key].nickname,
                                image_type: occupants[key].jid  === _converse.bare_jid ? _converse.xmppstatus?.vcard?.get('image_type') : _converse.roster.get(occupants[key].jid)?.get(`image_type`) ? _converse.roster.get(occupants[key].jid)?.get(`image_type`) : '',
                            }
                            return html`
                            <div class="col-12 d-flex py-2 participants-list">
                                <div class="d-flex justify-content-start align-items-center col-8 p-0">
                                    <converse-avatar class="avatar" .data=${converse_avatar} height="${40}" width="${40}"></converse-avatar>
                                    <span class="ml-3">${occupants[key].jid  === _converse.bare_jid ? "You": _converse.roster.get(occupants[key].jid)?.get(`nickname`) || occupants[key].nickname || occupants[key].jid }</span>
                                </div>
                                <div class="d-flex justify-content-end align-items-center col-4 p-0">
                                    <span class="mr-1 participant-role-txt">${my_affilation==false ? occupants[key].jid  === _converse.bare_jid ? `Removed` : `` : occupants[key].type === 'owner' ? 'Admin' : ''}</span>
                                    ${ is_admin || occupants[key].jid  === _converse.bare_jid
                                    ? html`
                                    <div class="dropdown">
                                        <div class="dropbtn"><converse-icon class="fa fa-list-ul right" size="1em"></converse-icon></div>
                                        <div class="dropdown-content">
                                            ${is_admin
                                            ? html`<button class="button-4 controlbox-heading__btn" @click=${(ev) => u.removeParticipants({to:o.jid,jid:occupants[key].jid},ev)} title="${occupants[key].jid  === _converse.bare_jid ? `Leave Group` : `Remove Participant`}">${occupants[key].jid  === _converse.bare_jid ? `Leave Group` : `[x]`}</button>` : my_affilation==false ? `` : occupants[key].jid  === _converse.bare_jid ? html`<button class="button-4 controlbox-heading__btn" @click=${(ev) => u.removeParticipants({to:o.jid,jid:occupants[key].jid},ev)} title="Leave Group">Leave Group</button>` : ''}
                                                ${is_admin ? occupants[key].type === 'owner'
                                                ? html`<button class="button-4 controlbox-heading__btn" @click=${(ev) => u.addParticipants({to:o.jid,jid:occupants[key].jid},ev)} title="Remove From Admin">[Remove From Admin]</button>`
                                                : html`<button class="button-4 controlbox-heading__btn" @click=${(ev) => u.makeParticipantAsAdmin({to:o.jid,jid:occupants[key].jid},ev)} title="Make Admin">[Make Admin]</button>`
                                            : ''}
                                        </div>
                                    </div>`
                                    : `` }
                                </div>
                            </div>`;
                        })
                        : html `
                        <div class="col-12 d-flex py-2 participants-list">
                            <div class="d-flex justify-content-start align-items-center p-0">
                                <img class="profile-img" src="https://images.unsplash.com/photo-1459356979461-dae1b8dcb702?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Ym95fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60" />
                                <span class="ml-3">Family</span>
                            </div>
                        </div>
                        <div class="col-12 d-flex py-2 participants-list">
                            <div class="d-flex justify-content-start align-items-center p-0">
                                <img class="profile-img" src="https://i.ibb.co/GxNNQht/photo-1597655601841-214a4cfe8b2c.jpg" />
                                <span class="ml-3">Disco buddies</span>
                            </div>
                        </div>
                        <div class="col-12 d-flex py-2 participants-list">
                            <div class="d-flex justify-content-start align-items-center p-0">
                                <img class="profile-img" src="https://i.ibb.co/nnKNNbr/360-F-380747975-s-S1h-CVB0q-Pq-FCWBMZ3q-J5x-Tq-H6rta-DBI.jpg" />
                                <span class="ml-3">School Meetings</span>
                            </div>
                        </div>
                        <div class="col-12 d-flex py-2 participants-list">
                            <div class="d-flex justify-content-start align-items-center p-0">
                                <img class="profile-img" src="https://i.ibb.co/2yF97fk/ziUwcQ.webp" />
                                <span class="ml-3">Besties</span>
                            </div>
                        </div>`}
                    </div>
                </div>
                <div class="contactInfo-container-control-features">
                    <div class="col-12 d-flex py-2 participants-list" role="button" @click=${o.model.clearChat}>
                        <div class="d-flex justify-content-start align-items-center p-0">
                            <img class="profile-img" src="/images/general_assets/hookzApp-clear-chat-icon.png" />
                            <span class="ml-3">Clear chat</span>
                        </div>
                    </div>
                    <div class="col-12 d-flex py-2 participants-list" role="button">
                        <div class="d-flex justify-content-start align-items-center p-0">
                            <img class="profile-img" src="/images/general_assets/hookzApp-report-icon.png" />
                            <span class="ml-3">Report</span>
                        </div>
                    </div>
                    ${isGroupChat
                    ? html`
                    <div class="col-12 d-flex py-2 participants-list" role="button">
                        ${my_affilation === false
                        ? html`
                        <div class="d-flex justify-content-start align-items-center p-0" @click=${(ev) => u.destroyGroup({to:o.jid},ev)}>
                            <img class="profile-img" src="/images/general_assets/hookzApp-report-icon.png" />
                            <span class="ml-3">Delete Group</span>
                        </div>`
                        : html`
                        <div class="d-flex justify-content-start align-items-center p-0" @click=${(ev) => u.removeParticipants({to:o.jid,jid:_converse.bare_jid},ev)}>
                            <img class="profile-img" src="/images/general_assets/hookzApp-report-icon.png" />
                            <span class="ml-3">Leave Group</span>
                        </div>`}
                    </div>`
                    : ``}
                </div>
                ${!isGroupChat 
                ? html`
                <div class="contactInfo-container-control-features">
                    <div class="col-12 d-flex py-2 participants-list" role="button" @click=${(ev) => u.blockContact({action: o.is_blocked ? 'unblock' : 'block',model:o},ev)}>
                        <div class="d-flex justify-content-start align-items-center p-0">
                            <img class="profile-img" src="/images/general_assets/hookzApp-block-contact-icon.png" />
                            <span class="ml-3">${o.is_blocked ? 'Unblock' : 'Block'} Contact</span>
                        </div>
                    </div>
                </div>`
                : ``}
            </div>`
    }

    function renderDraculaContactInfo() {
        return html`
        <div class="contactInfo-container profileSidebar sidebarShow px-4 py-3">
            <div class="row">
                <div class="col-6 d-flex align-items-center">${isGroupChat ? 'Group Info' : 'Contact Info'}</div>
                <div class="col-6 d-flex justify-content-end closeSidebar" @click=${ (ev) => o.model.save({show_contact_info_side_bar: false})} role="button"><img src="/images/general_assets/hookzApp-cross-gray.png" width="30"/></div>
            </div>
        </div>`
    }
      
    return html`
        <div class="flyout box-flyout mainChat-div position-relative">
            <div style="position:fixed;z-index:100" id="imageWindow"></div>
            <converse-imgeditor
                class="image-editor-show"
            ></converse-imgeditor>           
            <button id="send-picture" style="display:none;position:absolute; top:10px;z-index:100; right:3%">
                <svg viewBox="0 0 24 24" height="24" width="24" preserveAspectRatio="xMidYMid meet" class="" version="1.1" x="0px" y="0px" enable-background="new 0 0 24 24" xml:space="preserve">
                    <path fill="white" d="M1.101,21.757L23.8,12.028L1.101,2.3l0.011,7.912l13.623,1.816L1.112,13.845 L1.101,21.757z"></path>
                </svg>
            </button> 
            <div id="retake-picture" style="position:absolute; top:10px;z-index:100; right:13%">
                <div>
                    <svg viewBox="0 0 24 24" height="24" width="24" preserveAspectRatio="xMidYMid meet" class="" version="1.1" x="0px" y="0px" xml:space="preserve">
                    <path fill="currentColor" d="M19.77,11.73c0,1.64-0.5,2.95-1.48,3.89c-1.38,1.32-3.26,1.41-3.75,1.41c-0.07,0-0.11,0-0.12,0l-5.41,0v-2.1 h5.46c0.05,0,1.47,0.04,2.38-0.84c0.55-0.53,0.82-1.32,0.82-2.37c0-1.27-0.33-2.23-0.99-2.84c-0.98-0.92-2.43-0.85-2.44-0.85 l-4.23,0v3.1L4,7.07L10.01,3v2.93h4.16c0.03,0,2.29-0.13,3.95,1.42C19.21,8.38,19.77,9.85,19.77,11.73z"></path>
                    </svg>
                </div>    
                <div>Retake</div>
            </div>
            <converse-dragresize></converse-dragresize>
            ${o.model
            ? html`
            <converse-chat-heading jid="${o.jid}" class="chat-head chat-head-chatbox row no-gutters"></converse-chat-heading>
            <div class="chat-body">
                <div class="chat-content  chat__background-img ${o.show_send_button ? 'chat-content-sendbutton' : ''}" aria-live="polite" style="height: ${isThemeDracula() ? "83%": "100%"};">
                    ${renderEncryptionDiv()}
                    <converse-chat-content
                        class="chat-content__messages"
                        jid="${o.jid}">
                    </converse-chat-content>
                    ${o.show_help_messages
                    ? html`
                    <div class="chat-content__help">
                        <converse-chat-help
                            .model=${o.model}
                            .messages=${o.help_messages}
                            ?hidden=${!o.show_help_messages}
                            type="info"
                            chat_type="${_converse.CHATROOMS_TYPE}"
                        ></converse-chat-help>
                    </div>`
                    : ''}
                </div>
                <converse-chat-bottom-panel jid="${o.jid}" class="bottom-panel">
                </converse-chat-bottom-panel>
            </div>`
            : ''}
        </div>
        ${o.show_contact_info_side_bar ? (!isThemeDracula ? renderContactInfo() : renderDraculaContactInfo()) : ''}
    `;
};