/**
 * @copyright 2022, the Converse.js contributors
 * @license Mozilla Public License (MPLv2)
 */
import './components/switch-workstations.js';
import { converse } from '@converse/headless/core';


converse.plugins.add('converse-switch-workstations', {
    dependencies: [],

    initialize () {
    }
});