import log from "@converse/headless/log.js";
import tpl_requesting_contact from "./templates/requesting_contact.js";
import tpl_roster_item from "./templates/roster_item.js";
import { CustomElement } from 'shared/components/element.js';
import { __ } from 'i18n';
import { _converse, api, converse } from "@converse/headless/core";
import { CHAT_TYPE } from "@converse/headless/plugins/muclight/constants.js";
const { Strophe, $iq, sizzle, u } = converse.env;

let selectedOptionContainer = null;
export default class RosterContact extends CustomElement {

    static get properties () {
        return {
            model: { type: Object }
        }
    }

    initialize () {
        this.listenTo(this.model, "change", () => this.requestUpdate());
        this.listenTo(this.model, "highlight", () => this.requestUpdate());
        this.listenTo(this.model, 'vcard:add', () => this.requestUpdate());
        this.listenTo(this.model, 'vcard:change', () => this.requestUpdate());
        if (this.model.presence) {
            this.listenTo(this.model.presence, 'change:show', () => {
                try{
                    _converse.chatboxes.get(this.jid)?.presence.save({jid:this.model.presence.attributes.jid,show:this.model.presence.attributes.show})
                }catch(error) {
                    console.log(error)
                }
                this.requestUpdate()
            } );
        }
        document.addEventListener('click', (ev) => {
            let rosterGroupContacts = document.querySelector(`.items-list.roster-group-contacts`);
            if (!rosterGroupContacts.contains(ev.composedPath()[0])) {
                this.hideMenu();
                selectedOptionContainer = null;
            }
        });
        this.addEventListener('keyup', ev => this.handleKeyUp(ev));
    }

    render () {
        if (this.model.get('requesting') === true) {
            const display_name = this.model.getDisplayName();
            return tpl_requesting_contact(
                Object.assign(this.model.toJSON(), {
                    display_name,
                    'openChat': ev => this.openChat(ev),
                    'acceptRequest': ev => this.acceptRequest(ev),
                    'declineRequest': ev => this.declineRequest(ev),
                    'desc_accept': __("Click to accept the contact request from %1$s", display_name),
                    'desc_decline': __("Click to decline the contact request from %1$s", display_name),
                })
            );
        } else {
            return tpl_roster_item(this, this.model);
        }
    }
    async openChat (ev) {
        ev?.preventDefault?.();
        await this.model.openChat();
    }

    async removeContact (ev) {
        ev?.preventDefault?.();
        if (!api.settings.get('allow_contact_removal')) { return; }

        const result = await api.confirm(__("Are you sure you want to remove this contact?"));
        if (!result)  return;

        try {
            this.model.removeFromRoster();
            if (this.model.collection) {
                // The model might have already been removed as
                // result of a roster push.
                this.model.destroy();
            }
        } catch (e) {
            log.error(e);
            api.alert('error', __('Error'),
                [__('Sorry, there was an error while trying to remove %1$s as a contact.', this.model.getDisplayName())]
            );
        }
    }

    async showOptions (ev) {
        ev?.preventDefault?.();
        this.hideMenu();
        if(selectedOptionContainer !== this.model.get('user_id')) {
            let optionContainer = document.querySelector(`converse-roster .roster-group-contacts .option-container-${this.model.get('user_id')}`);
            if(!optionContainer) return;
            optionContainer.classList.remove('d-none');
            selectedOptionContainer = this.model.get('user_id');
        } else {
            selectedOptionContainer = null;
        }
    }

    async acceptRequest (ev) {
        ev?.preventDefault?.();

        await _converse.roster.sendContactAddIQ(
            this.model.get('jid'),
            this.model.getFullname(),
            []
        );
        this.model.authorize().subscribe();
    }

    async declineRequest (ev) {
        if (ev && ev.preventDefault) { ev.preventDefault(); }
        const result = await api.confirm(__("Are you sure you want to decline this contact request?"));
        if (result) {
            this.model.unauthorize().destroy();
        }
        return this;
    }

    hideMenu () {
        let allOptionContainers = document.querySelectorAll(`converse-roster .roster-group-contacts [class*='option-container-']`);
        allOptionContainers.forEach((element) => {
            element.classList.add('d-none');
        });
    }

    handleKeyUp (ev) {
        if (ev.keyCode === converse.keycodes.ESCAPE) {
            this.hideMenu();
            selectedOptionContainer = null;
        }
    }
}

api.elements.define('converse-roster-contact', RosterContact);
