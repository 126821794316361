import { setAffiliations } from './utils.js';

export default {
    /**
     * The "affiliations" namespace groups methods relevant to setting and
     * getting MUCLIGHT affiliations.
     *
     * @namespace api.rooms.affiliations
     * @memberOf api.rooms
     */
    affiliations: {
        /**
         * Set the given affliation for the given JIDs in the specified MUCLIGHTs
         *
         * @param { String|Array<String> } muclight_jids - The JIDs of the MUCLIGHTs in
         *  which the affiliation should be set.
         * @param { Object[] } users - An array of objects representing users
         *  for whom the affiliation is to be set.
         * @param { String } users[].jid - The JID of the user whose affiliation will change
         * @param { ('outcast'|'member'|'admin'|'owner') } users[].affiliation - The new affiliation for this user
         * @param { String } [users[].reason] - An optional reason for the affiliation change
         * @returns { Promise }
         *
         * @example
         *  api.rooms.affiliations.set(
         *      [
         *          'muclight1@muclight.example.org',
         *          'muclight2@muclight.example.org'
         *      ], [
         *          {
         *              'jid': 'user@example.org',
         *              'affiliation': 'member',
         *              'reason': "You're one of us now!"
         *          }
         *      ]
         *  )
         */
        set (muclight_jids, users) {
            users = !Array.isArray(users) ? [users] : users;
            muclight_jids = !Array.isArray(muclight_jids) ? [muclight_jids] : muclight_jids;
            return setAffiliations(muclight_jids, users);
        }
    }
}
