import 'shared/avatar/avatar.js';
import { __ } from 'i18n';
import { _converse, api } from '@converse/headless/core';
import { getPrettyStatus, logOut } from '../../../utils.js';
import { html } from "lit";
import { LOCAL_STORAGE } from '../../../../../shared/constants.js';
import '../../../moment-listing.js';
import tpl_chatmenucontactlisting from "./chatmenucontactlisting.js";
import tpl_azchatmenucontactlisting from "./azchatmenucontactlisting.js";
import { contactsComparator, groupsComparator } from '@converse/headless/plugins/roster/utils.js';
import { repeat } from 'lit/directives/repeat.js';
import { shouldShowContact, shouldShowGroup, populateContactsMap } from '../../../../rosterview/utils.js';


import 'plugins/muc-views/modals/add-muc.js';
import 'plugins/muc-views/modals/muc-list.js';
import 'plugins/muclight-views/modals/add-muclight.js';
import 'plugins/muclight-views/modals/muclight-list.js';

import '../../../styles/newchatmenu.scss';
import { getNearByRosters } from '@converse/headless/utils/serverApis.js';
import { diffInSeconds } from '../../../../../utils/helper.js';

async function callGetNearByRosters(el) {
    let is_execute = true
    const interval = (60*2)
    if(el.model.get('getNearByRosters')?.time) {
        const last_updation = diffInSeconds(Date.now(),el.model.get('getNearByRosters')?.time)
        is_execute = last_updation>interval
    }
    if(is_execute) {
        const data = await getNearByRosters()
        if(data.success)
        {
            const jids = []
            for(const key of Object.keys(data.data)) {
                jids.push(data.data[key].jid)
            }
            await el.model.save({getNearByRosters: {
                time: Date.now(),
                visible:true,
                ...data,
                jids
            }})
        }
    }
    const data_getNearByRosters = el.model.get('getNearByRosters')
    data_getNearByRosters?.visible===false ? el.model.save({getNearByRosters:{...data_getNearByRosters,visible: true}}) : ``
    return true
}

export default (el) => {
    const roster = _converse.roster || [];
    roster?.models?.sort((a, b) => a.get(`stamp`) - b.get(`stamp`)).reverse();
    const contacts_map = roster.reduce((acc, contact) => populateContactsMap(acc, contact), {});
    const groupnames = Object.keys(contacts_map).filter(shouldShowGroup);
    groupnames.sort(groupsComparator);
    const nearbyRosters = el.model.get('getNearByRosters')?.jids || []
    const nearbyRostersVisibility = el.model.get('getNearByRosters')?.visible || false
    const searchKey = el.model.get(`newchatmenucontactsearch`) || ``
    return el.model.get(`chatmenu`) ? html
        `<div class="new-chat-menu-container">
            <div class="new-chat-menu-sub-container p-4">
                <div class="d-flex">
                    <img  
                        src="../../../../images/group-icons/back-arrow.svg" 
                        alt="back" 
                        @click=${ () => _converse.xmppstatus.save({chatmenu:!_converse.xmppstatus.get(`chatmenu`)})} 
                        style="cursor: pointer;"
                    />
                    <div class="new-chat-heading m-2" style="font-weight: bold; font-size: medium;">New Chat</div>
                </div>
            </div>
            
            <div class="new-chat-menu-search-bar-container px-4">
                <input
                    @keyup=${ (ev) => {
                        el.model.save({newchatmenucontactsearch: ev.target.value})
                    }}
                    class="search-icon"
                    type="text"
                    value="${searchKey}"
                    placeholder="${searchKey || `Search contacts`}"
                />
            </div>
            ${!searchKey ? html `
            <div class="d-flex align-items-center pl-4 my-2 new-chat-menu-option-container"
                @click=${(ev) => api.modal.show('converse-add-muclight-modal', { model: _converse.roompanel, ev }, ev)}
            >
                <converse-avatar
                class="avatar"
                .data=${{jid:`${Strophe.NS.MUCLIGHT_DOMAIN}@${Strophe.NS.MUCLIGHT_DOMAIN}`}}
                height="50" width="50"></converse-avatar>
                <div class="new-group-text">New group</div>
            </div>
            <div class="d-flex align-items-center pl-4 my-2 new-chat-menu-option-container"
            @click=${ async (ev) => await callGetNearByRosters(el)}>
                <div class="location">
                    <i class="location-icon fa-solid fa-location-dot" aria-hidden="true"></i>
                </div>
                <div class="new-group-text">Find people nearby</div>
            </div>
            ` : ``}
            
            <div class="frequently-contacted">
                <div class="frequently-contacted-heading pl-4 py-2 my-3">
                    <div class="heading-text">${nearbyRostersVisibility ? `Nearby Friends` : searchKey ? `Search Contact` : `Frequently Contacted`} ${nearbyRostersVisibility ? html ` <div @click=${ (ev) => el.model.save({getNearByRosters:{...el.model.get('getNearByRosters'),visible: false}})} class="close-near-by-roster-menu"><i class="fa fa-close"></i></div>` : ``}</div>
                </div>
                ${repeat(
                    groupnames,
                    n => n,
                    name => {
                        if(name === "New messages") {
                            return;
                        }
                        let contacts = contacts_map[name]
                        if(nearbyRostersVisibility) {
                            contacts = contacts.filter(c => {
                                return nearbyRosters.includes(c.get(`user_id`))
                            });
                        }
                        contacts = contacts.filter(c => {
                            return searchKey ? (c.get(`nickname`).toLowerCase().includes(searchKey.toLowerCase())) ? true : false : true
                        });
                        if(!searchKey)
                        {
                            contacts = _converse.roster_filter.get(`list_type`)===`archived` ? contacts.filter(function (el) {
                                return el.get(`is_archived`)===true
                            }
                            ) : contacts.filter(function (el) {
                                return el.get(`is_archived`)!==true
                            }
                            )
                            contacts.splice(3,contacts.length)    
                        }
                        // contacts.sort(contactsComparator);
                        return contacts.length ? tpl_chatmenucontactlisting({ contacts, name }) : '';
                    }
                )}
            </div>
            ${!searchKey ? html `
            <div class="alphabet-contacted">
                ${repeat(
                    groupnames,
                    n => n,
                    name => {
                        if(name === "New messages") {
                            return;
                        }
                        let contacts = contacts_map[name]
                        contacts = _converse.roster_filter.get(`list_type`)===`archived` ? contacts.filter(function (el) {
                            return el.get(`is_archived`)===true
                        }
                        ) : contacts.filter(function (el) {
                            return el.get(`is_archived`)!==true
                        }
                        )
                        let contact_data = []
                        for(const contactsdata of contacts) {
                            let nickname = contactsdata.get(`nickname`).trim()[0].toUpperCase().trim()
                            if(nickname==='+') {
                                nickname = contactsdata.get(`nickname`).trim()[1].toUpperCase().trim()
                            }
                            if(!contact_data[`${nickname}`]) {
                                contact_data[`${nickname}`] = []
                            }
                            contact_data[`${nickname}`].push(contactsdata)
                        }
                        contacts = contact_data
                        return contacts.length ? tpl_azchatmenucontactlisting({ contacts, name }) : '';
                    }
                )}
            </div>` : ``}
        </div>
    </div>`
        : ``
};
