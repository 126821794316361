import tpl_group from "./group.js";
import { __ } from 'i18n';
import { _converse, api } from "@converse/headless/core";
import { contactsComparator, groupsComparator } from '@converse/headless/plugins/roster/utils.js';
import { html } from "lit";
import { repeat } from 'lit/directives/repeat.js';
import { shouldShowContact, shouldShowGroup, populateContactsMap } from '../utils.js';


export default (el) => {
    const i18n_heading_contacts = __('Contacts');
    const i18n_toggle_contacts = __('Click to toggle contacts');
    const i18n_title_add_contact = __('Add a contact');
    const i18n_title_sync_contacts = __('Re-sync your contacts');
    const roster = _converse.roster || [];
    roster?.models?.sort((a, b) => a.get(`stamp`) - b.get(`stamp`)).reverse();
    const contacts_map = roster.reduce((acc, contact) => populateContactsMap(acc, contact), {});
    const groupnames = Object.keys(contacts_map).filter(shouldShowGroup);
    const is_closed = el.model.get('toggle_state') === _converse.CLOSED;
    groupnames.sort(groupsComparator);
    return html`
        <div class="controlbox-padded d-none">
            <span class="w-100 controlbox-heading controlbox-heading--contacts">
                <a class="list-toggle open-contacts-toggle" title="${i18n_toggle_contacts}" @click=${el.toggleRoster}>
                    <converse-icon
                        class="fa ${is_closed ? 'fa-caret-right' : 'fa-caret-down'}"
                        size="1em"
                        color="var(--chat-color)"
                    ></converse-icon>
                    ${i18n_heading_contacts}
                </a>
            </span>
            <a
                class="controlbox-heading__btn sync-contacts"
                @click=${ev => el.syncContacts(ev)}
                title="${i18n_title_sync_contacts}"
            >
                <converse-icon
                    class="fa fa-sync right ${el.syncing_contacts ? 'fa-spin' : ''}"
                    size="1em"
                ></converse-icon>
            </a>
            ${api.settings.get('allow_contact_requests')
                ? html` <a
                      class="controlbox-heading__btn add-contact "
                      @click=${ev => el.showAddContactModal(ev)}
                      title="${i18n_title_add_contact}"
                      data-toggle="modal"
                      data-target="#add-contact-modal"
                  >
                      <converse-icon class="fa fa-user-plus right" size="1.25em"></converse-icon>
                  </a>`
                : ''}
        </div>

        <div class="mt-2 list-container roster-contacts ${is_closed ? 'hidden' : ''}">
            <converse-roster-filter @update=${() => el.requestUpdate()}></converse-roster-filter>

            ${api.settings.get('is_notification_text_div_visible')
                ? html`<div class="list__container-notification-div">
                      <div class="list__container-notification-div-notification-off-icon">
                          <img src="/images/general_assets/notication-off-svgrepo-com.svg" />
                      </div>
                      <div class="list__container-notification-div-text">
                          <div class="list__container-notification-div-top">Get notified of new messages</div>
                          <div class="list__container-notification-div-bottom">
                              <div>Turn on desktop notifications</div>
                              <div><img src="/images/general_assets/arrow-right-svgrepo-com.svg" /></div>
                          </div>
                      </div>
                      <div class="list__container-notification-div-cross-icon">
                          <img src="/images/general_assets/hookzApp-cross-icon.png" />
                      </div>
                  </div>`
                : ''}
            ${api.settings.get('is_notification_text_div_visible')
                ? html`<div class="list__container-archived-chatrequest-div">
                      <div class="list__container-archived-chatrequest-div-archived">
                        ${_converse.roster_filter.get(`list_type`)===`archived` ? `` : html `<div><img src="/images/general_assets/hookzApp-archived.png"/></div>`}
                          <div @click=${(ev) => {
                            if(_converse.roster_filter.get(`list_type`)===`archived`) {
                                _converse.roster_filter.save({list_type: ``})
                            }else{
                                _converse.roster_filter.save({list_type: `archived`})
                            }
                            api.trigger('refresh_contact_list_for_archived')
                        }
                          }>${_converse.roster_filter.get(`list_type`)===`archived` ? html `<i class="fa fa-chevron-circle-left" aria-hidden="true"></i> Back` : html `Archived`}</div>
                      </div>
                      <div class="list__container-archived-chatrequest-div-archived chat">
                          <div><img src="/images/general_assets/chat-svgrepo-com.svg"/></div>
                          <div>Chat requests (3)</div>
                      </div>
                  </div>`
                : ''}
            ${repeat(
                groupnames,
                n => n,
                name => {
                    if(name === "New messages") {
                        return;
                    }
                    let contacts = contacts_map[name].filter(c => shouldShowContact(c, name));
                    contacts = _converse.roster_filter.get(`list_type`)===`archived` ? contacts.filter(function (el) {
                        return el.get(`is_archived`)===true
                    }
                    ) : contacts.filter(function (el) {
                        return el.get(`is_archived`)!==true
                    }
                    )
                    // contacts.sort(contactsComparator);
                    return contacts.length ? tpl_group({ contacts, name }) : '';
                }
            )}
        </div>
    `;
}
