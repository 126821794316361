export const ROLES = ['moderator', 'participant', 'visitor'];
export const AFFILIATIONS = ['owner', 'admin', 'member', 'outcast', 'none'];

export const MUCLIGHT_ROLE_WEIGHTS = {
    'moderator': 1,
    'participant': 2,
    'visitor': 3,
    'none': 2,
};

export const AFFILIATION_CHANGES = {
    OWNER: 'owner',
    ADMIN: 'admin',
    MEMBER: 'member',
    EXADMIN: 'exadmin',
    EXOWNER: 'exowner',
    EXOUTCAST: 'exoutcast',
    EXMEMBER: 'exmember',
};

export const AFFILIATION_CHANGES_LIST = Object.values(AFFILIATION_CHANGES);
export const MUCLIGHT_TRAFFIC_STATES = { ENTERED: 'entered', EXITED: 'exited' };
export const MUCLIGHT_TRAFFIC_STATES_LIST = Object.values(MUCLIGHT_TRAFFIC_STATES);
export const MUCLIGHT_ROLE_CHANGES = { OP: 'op', DEOP: 'deop', VOICE: 'voice', MUTE: 'mute' };
export const MUCLIGHT_ROLE_CHANGES_LIST = Object.values(MUCLIGHT_ROLE_CHANGES);

export const INFO_CODES = {
    'visibility_changes': ['100', '102', '103', '172', '173', '174'],
    'self': ['110'],
    'non_privacy_changes': ['104', '201'],
    'muclight_logging_changes': ['170', '171'],
    'nickname_changes': ['210', '303'],
    'disconnected': ['301', '307', '321', '322', '332', '333'],
    'affiliation_changes': [...AFFILIATION_CHANGES_LIST],
    'join_leave_events': [...MUCLIGHT_TRAFFIC_STATES_LIST],
    'role_changes': [...MUCLIGHT_ROLE_CHANGES_LIST],
};

export const ROOMSTATUS = {
    CONNECTED: 0,
    CONNECTING: 1,
    NICKNAME_REQUIRED: 2,
    PASSWORD_REQUIRED: 3,
    DISCONNECTED: 4,
    ENTERED: 5,
    DESTROYED: 6,
    BANNED: 7,
    CLOSING: 8,
};

export const ROOM_FEATURES = [
    'passwordprotected',
    'unsecured',
    'hidden',
    'publicroom',
    'membersonly',
    'open',
    'persistent',
    'temporary',
    'nonanonymous',
    'semianonymous',
    'moderated',
    'unmoderated',
    'mam_enabled',
];

export const MUCLIGHT_NICK_CHANGED_CODE = '303';

// No longer used in code, but useful as reference.
//
// const ROOM_FEATURES_MAP = {
//     'passwordprotected': 'unsecured',
//     'unsecured': 'passwordprotected',
//     'hidden': 'publicroom',
//     'publicroom': 'hidden',
//     'membersonly': 'open',
//     'open': 'membersonly',
//     'persistent': 'temporary',
//     'temporary': 'persistent',
//     'nonanonymous': 'semianonymous',
//     'semianonymous': 'nonanonymous',
//     'moderated': 'unmoderated',
//     'unmoderated': 'moderated'
// };

export const MESSAGE_TYPE = { TEXT: '0', LOCATION: '54', IMAGE: '50', VIDEO: '51', AUDIO: '53', DOCUMENT: '52', REACTION: '18', STORY_SEEN: '73', WEBRTC: '7' };

export const CHAT_TYPE = { CHAT: 'chat', GROUP_CHAT: 'groupchat', START_CALL: 'START_CALL' };
export const SIGNAL_NAME = { START_CALL: 'START_CALL', RINGING_CALL: 'RINGING_CALL', OFFER: 'OFFER', ICE: 'ICE', ANSWER: 'ANSWER', DECLINE: 'DECLINE', CANCEL: 'CANCEL', LEAVE: 'LEAVE' };
export const IOS_USER_CALL_API = { START: 'START',};
export const CALL_TYPE = { VIDEO: 'video', AUDIO: 'audio' };
export const CALL_TYPE_CONST = { VIDEO: 2, AUDIO: 1 };
export const CALL_VERSION = '2.4.9';
export const STORY_ACTIVITY = []
export const TURN_STUN_SERVER = {
    'iceServers': [{
        urls: ['turn:turn.hookzapp.com:3478', 'stun:stun.hookzapp.com:3478'],
        username: 'jaspreet',
        credential: '12345678',
    }]
}
STORY_ACTIVITY[`🎉`] = `Celebrating`;
STORY_ACTIVITY[`👀`] = `Watching`;
STORY_ACTIVITY[`🍔`] = `Eating`;
STORY_ACTIVITY[`🍻`] = `Drinking`;
STORY_ACTIVITY[`🎪`] = `Attending`;
STORY_ACTIVITY[`🛩️`] = `Traveling`;
STORY_ACTIVITY[`🎶`] = `Listening`;
STORY_ACTIVITY[`💭`] = `Thinking`;
STORY_ACTIVITY[`📖`] = `Reading`;
STORY_ACTIVITY[`🥰`] = `Supporting`;
STORY_ACTIVITY[`⛳`] = `Playing`;
STORY_ACTIVITY[`🛍`] = `Shopping`;
STORY_ACTIVITY[`🥳`] = `Partying`;
STORY_ACTIVITY[`🏖`] = `Relaxing`;
