import 'shared/modals/user-details.js';
import tpl_chatbox_head from './templates/chat-head.js';
import tpl_chatbox_head_dracula from './templates/themes/dracula/chat-head-dracula.js';
import { CustomElement } from 'shared/components/element.js';
import { __ } from 'i18n';
import { _converse, api } from "@converse/headless/core";
import { html } from 'lit';
import './incomming-call.js'
import './outgoing-call.js'
import './call-connected.js'
import './styles/chat-head.scss';

import { isThemeDracula } from '../../utils/helper.js';

export default class ChatHeading extends CustomElement {

    static get properties () {
        return {
            'jid': { type: String },
        }
    }

    initialize () {
        this.model = _converse.chatboxes.get(this.jid);
        this.roster_model = _converse.roster.get(this.jid)
        this.listenTo(this.model, 'change:status', () => this.requestUpdate());
        this.listenTo(this.model, 'vcard:add', () => this.requestUpdate());
        this.listenTo(this.model, 'vcard:change', () => this.requestUpdate());
        if (this.model.contact) {
            this.listenTo(this.model.contact, 'destroy', () => this.requestUpdate());
        }
        if (this?.roster_model?.presence) {
            this.listenTo(this?.roster_model?.presence, 'change:show', () => {
                this.requestUpdate()
            } );
        }
        this.model.rosterContactAdded?.then(() => {
            this.listenTo(this.model.contact, 'change:nickname', () => this.requestUpdate());
            this.requestUpdate();
        });
    }

    render () {
        const data = Object.assign(this.model.toJSON(), {
            'heading_buttons_promise': this.getHeadingButtons(),
            'model': this.model,
            'presence': this?.roster_model?.presence,
            'showUserDetailsModal': ev => this.showUserDetailsModal(ev),
        })
        return isThemeDracula() ? tpl_chatbox_head_dracula(data) : tpl_chatbox_head(data);
    }
    showUserDetailsModal (ev) {
        ev.preventDefault();
        api.modal.show('converse-user-details-modal', { model: this.model }, ev);
    }
    showUserProfileSidebar (ev) {
        ev.preventDefault();
        api.modal.show('converse-user-details-modal', { model: this.model }, ev);
    }

    close (ev) {
        ev.preventDefault();
        this.model.close();
    }

    /**
     * Returns a list of objects which represent buttons for the chat's header.
     * @async
     * @emits _converse#getHeadingButtons
     */
    getHeadingButtons () {
        const buttons = [
            /**
             * @typedef { Object } HeadingButtonAttributes
             * An object representing a chat heading button
             * @property { Boolean } standalone
             *  True if shown on its own, false if it must be in the dropdown menu.
             * @property { Function } handler
             *  A handler function to be called when the button is clicked.
             * @property { String } a_class - HTML classes to show on the button
             * @property { String } i18n_text - The user-visiible name of the button
             * @property { String } i18n_title - The tooltip text for this button
             * @property { String } icon_class - What kind of CSS class to use for the icon
             * @property { String } name - The internal name of the button
             */
            // {
            //     'a_class': 'show-user-details-modal',
            //     'handler': ev => this.showUserDetailsModal(ev),
            //     'i18n_text': __('Details'),
            //     'i18n_title': __('See more information about this person'),
            //     // 'icon_class': 'fa-id-card',
            //     'name': 'details',
            //     'standalone': api.settings.get('view_mode') === 'overlayed'
            // },
            {
                'a_class': 'show-user-details-modal contactInfoSlider',
                'handler': ev => this.model.showUserDetailsSideBar(ev),
                'i18n_text': __('Contact info'),
                'i18n_title': __('See more information about group'),
                // 'icon_class': 'fa-id-card',
                'name': 'GroupInfo',
                'standalone': api.settings.get('is_profile_info_visible') === 'overlayed',
                'svg': html`<svg id="Icon_ionic-ios-information-circle-outline" data-name="Icon ionic-ios-information-circle-outline" xmlns="http://www.w3.org/2000/svg" width="18.409" height="18.409" viewBox="0 0 18.409 18.409">
                                <path id="Path_11391" data-name="Path 11391" d="M16.552,11.589a.912.912,0,1,1,.907.885A.886.886,0,0,1,16.552,11.589Zm.062,1.58H18.3v6.377h-1.69Z" transform="translate(-8.259 -6.093)" fill="#003254"/>
                                <path id="Path_11392" data-name="Path 11392" d="M12.579,4.614A7.962,7.962,0,1,1,6.946,6.946a7.913,7.913,0,0,1,5.633-2.332m0-1.239a9.2,9.2,0,1,0,9.2,9.2,9.2,9.2,0,0,0-9.2-9.2Z" transform="translate(-3.375 -3.375)" fill="#003254"/>
                            </svg>`
            },
            {
                'a_class': 'show-user-details-modal',
                // 'handler': ev => this.showUserDetailsModal(ev),
                'i18n_text': __('Select messages'),
                'i18n_title': __('See more information about contact'),
                // 'icon_class': 'fa-id-card',
                'name': 'SelectMessages',
                'standalone': api.settings.get('view_mode') === 'overlayed',
                'svg': html`<svg
                    id="Icon_ionic-ios-information-circle-outline"
                    data-name="Icon ionic-ios-information-circle-outline"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.409"
                    height="18.409"
                    viewBox="0 0 18.409 18.409"
                >
                    <path
                        id="Path_11392"
                        data-name="Path 11392"
                        d="M12.579,4.614A7.962,7.962,0,1,1,6.946,6.946a7.913,7.913,0,0,1,5.633-2.332m0-1.239a9.2,9.2,0,1,0,9.2,9.2,9.2,9.2,0,0,0-9.2-9.2Z"
                        transform="translate(-3.375 -3.375)"
                        fill="#003254"
                    />
                </svg> `,
            },
            {
                'a_class': 'show-user-details-modal',
                // 'handler': ev => this.showUserDetailsModal(ev),
                'i18n_text': __('Mute notifications'),
                'i18n_title': __('See more information about group'),
                // 'icon_class': 'fa-id-card',
                'name': 'MuteNotifications',
                'standalone': api.settings.get('view_mode') === 'overlayed',
                'svg': html`<svg xmlns="http://www.w3.org/2000/svg" width="12.813" height="16.952" viewBox="0 0 12.813 16.952">
                                <path id="Icon_ionic-ios-volume-mute" data-name="Icon ionic-ios-volume-mute" d="M23.281,10.237a1.148,1.148,0,0,0-.5-.112,1.181,1.181,0,0,0-.723.245l-4.712,3.68H13.531a1.143,1.143,0,0,0-1.156,1.122V20.78A1.143,1.143,0,0,0,13.531,21.9h3.816l4.712,3.68a1.2,1.2,0,0,0,.723.245,1.148,1.148,0,0,0,.5-.112,1.119,1.119,0,0,0,.658-1.009V11.247A1.1,1.1,0,0,0,23.281,10.237Z" transform="translate(-11.75 -9.5)" fill="none" stroke="#003358" stroke-width="1.25"/>
                            </svg>`
            },
            {
                'a_class': 'show-user-details-modal',
                // 'handler': ev => this.showUserDetailsModal(ev),
                'i18n_text': __('Disappearing messages'),
                'i18n_title': __('See more information about group'),
                // 'icon_class': 'fa-id-card',
                'name': 'DisappearingMessages',
                'standalone': api.settings.get('view_mode') === 'overlayed',
                'svg': html`<svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.948"
                    height="17.952"
                    viewBox="0 0 17.948 17.952"
                >
                    <g id="Icon_ionic-ios-timer" data-name="Icon ionic-ios-timer" transform="translate(-3.938 -3.938)">
                        <path
                            id="Path_11389"
                            data-name="Path 11389"
                            d="M12.914,21.89A8.976,8.976,0,0,1,6.684,6.451a.721.721,0,0,1,1,1.037,7.533,7.533,0,1,0,5.947-2.074v2.81a.723.723,0,0,1-1.445,0V4.66a.722.722,0,0,1,.723-.723,8.976,8.976,0,0,1,0,17.952Z"
                            transform="translate(0 0)"
                            fill="#003254"
                        />
                        <path
                            id="Path_11390"
                            data-name="Path 11390"
                            d="M11.964,11.336l4.466,3.209a1.351,1.351,0,0,1-1.571,2.2,1.3,1.3,0,0,1-.314-.314l-3.209-4.466a.45.45,0,0,1,.628-.628Z"
                            transform="translate(-2.646 -2.645)"
                            fill="#003254"
                        />
                    </g>
                </svg> `,
            },
            {
                'a_class': 'show-user-details-modal',
                // 'handler': ev => this.showUserDetailsModal(ev),
                'i18n_text': __('Clear Messages'),
                'i18n_title': __('See more information about group'),
                // 'icon_class': 'fa-id-card',
                'name': 'ClearMessages',
                'handler': this.model.clearChat,
                'standalone': api.settings.get('view_mode') === 'overlayed',
                'svg': html`<svg id="Group_16594" data-name="Group 16594" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17.748" height="18.343" viewBox="0 0 17.748 18.343">
                    <defs>
                        <clipPath id="clip-path">
                                        <rect id="Rectangle_4109" data-name="Rectangle 4109" width="17.748" height="18.343" fill="#003254"/>
                        </clipPath>
                    </defs>
                    <g id="Group_14875" data-name="Group 14875" clip-path="url(#clip-path)">
                                    <path id="Path_8189" data-name="Path 8189" d="M6.686,15.429c-.948,0-1.9,0-2.844,0a.829.829,0,0,1-.629-.258c-.84-.844-1.686-1.681-2.523-2.528a2.165,2.165,0,0,1,0-3.166Q5.072,5.082,9.468.695a2.166,2.166,0,0,1,3.183,0q2.2,2.19,4.392,4.392a2.169,2.169,0,0,1,0,3.2q-3.421,3.432-6.85,6.857a.9.9,0,0,1-.687.286c-.94-.009-1.881,0-2.821,0m4.027-2.865L5.194,7.041C4.034,8.2,2.851,9.374,1.677,10.559a.716.716,0,0,0,.047,1.055q1.122,1.13,2.254,2.25a.4.4,0,0,0,.254.106q2.456.009,4.913,0a.321.321,0,0,0,.206-.058c.467-.454.926-.915,1.363-1.349m-4.4-6.6,5.457,5.458L13.6,9.615,8.13,4.143,6.317,5.962m8.409,2.582,1.206-1.206a.771.771,0,0,0,.007-1.285L11.7,1.808a1.9,1.9,0,0,0-.2-.184.7.7,0,0,0-.866,0c-.483.466-.939.962-1.389,1.427l5.486,5.491" transform="translate(0 0)" fill="#003254"/>
                                    <path id="Path_8190" data-name="Path 8190" d="M10.954,279.262H2.739c-.409,0-.509-.1-.509-.507,0-.182,0-.364,0-.546,0-.271.134-.4.409-.4H19.183c.425,0,.521.1.521.518,0,.174,0,.349,0,.523,0,.284-.133.412-.421.412q-1.661,0-3.322,0H10.954" transform="translate(-2.094 -260.92)" fill="#003254"/>
                    </g>
                            </svg>`
            },
            // {
            //     'a_class': 'show-user-details-modal',
            //     'handler': ev => this.showUserDetailsModal(ev),
            //     'i18n_text': __('Exit group'),
            //     'i18n_title': __('See more information about group'),
            //     // 'icon_class': 'fa-id-card',
            //     'name': 'ExitGroup',
            //     'standalone': api.settings.get('view_mode') === 'overlayed'
            // }
        ];
        if (!api.settings.get('singleton')) {
            buttons.push({
                'a_class': 'close-chatbox-button',
                'handler': ev => this.close(ev),
                'i18n_text': __('Close'),
                'i18n_title': __('Close and end this conversation'),
                // 'icon_class': 'fa-times',
                'name': 'close',
                'standalone': api.settings.get('view_mode') === 'overlayed',
                'svg': html`<svg xmlns="http://www.w3.org/2000/svg" width="17.952" height="17.952" viewBox="0 0 17.952 17.952">
                                <path id="Icon_material-block" data-name="Icon material-block" d="M11.976,3a8.976,8.976,0,1,0,8.976,8.976A8.979,8.979,0,0,0,11.976,3ZM4.8,11.976A7.179,7.179,0,0,1,11.976,4.8a7.093,7.093,0,0,1,4.4,1.517L6.312,16.375A7.093,7.093,0,0,1,4.8,11.976Zm7.181,7.181a7.093,7.093,0,0,1-4.4-1.517L17.64,7.578a7.093,7.093,0,0,1,1.517,4.4A7.179,7.179,0,0,1,11.976,19.157Z" transform="translate(-3 -3)" fill="#003357"/>
                            </svg>`
            });
        }
        const el = _converse.chatboxviews.get(this.getAttribute('jid'));
        if (el) {
            /**
             * *Hook* which allows plugins to add more buttons to a chat's heading.
             *
             * Note: This hook is fired for both 1 on 1 chats and groupchats.
             * If you only care about one, you need to add a check in your code.
             *
             * @event _converse#getHeadingButtons
             * @param { HTMLElement } el
             *      The `converse-chat` (or `converse-muc`) DOM element that represents the chat
             * @param { Array.<HeadingButtonAttributes> }
             *      An array of the existing buttons. New buttons may be added,
             *      and existing ones removed or modified.
             * @example
             *  api.listen.on('getHeadingButtons', (el, buttons) => {
             *      buttons.push({
             *          'i18n_title': __('Foo'),
             *          'i18n_text': __('Foo Bar'),
             *          'handler': ev => alert('Foo!'),
             *          'a_class': 'toggle-foo',
             *          'icon_class': 'fa-foo',
             *          'name': 'foo'
             *      });
             *      return buttons;
             *  });
             */
            return _converse.api.hook('getHeadingButtons', el, buttons);
        } else {
            return buttons; // Happens during tests
        }
    }
}

api.elements.define('converse-chat-heading', ChatHeading);
