import { Model } from '@converse/skeletor/src/model.js';
import { _converse, api, converse } from "@converse/headless/core";
import { getOpenPromise } from '@converse/openpromise';
import { rejectPresenceSubscription } from './utils.js';
import { CHAT_TYPE, MESSAGE_TYPE } from '../muclight/constants.js';

const { Strophe, u, $iq, $pres } = converse.env;

/**
 * @class
 * @namespace StoryData
 */
const StoryData = Model.extend({
    // idAttribute: 'jid',

    defaults: {
        'message_type': CHAT_TYPE.CHAT,
    },

    async initialize (attributes) {
        this.initialized = getOpenPromise();
        const { jid } = attributes;
        this.set({
            ...attributes,
            ...{
                'jid': Strophe.getBareJidFromJid(jid).toLowerCase(),
                'user_id': Strophe.getNodeFromJid(jid)
            }
        });
        /**
         * When a contact's presence status has changed.
         * The presence status is either `online`, `offline`, `dnd`, `away` or `xa`.
         * @event _converse#contactPresenceChanged
         * @type { _converse.StoryData }
         * @example _converse.api.listen.on('contactPresenceChanged', contact => { ... });
         */
        // this.listenTo(this.presence, 'change:show', () => api.trigger('contactPresenceChanged', this));
        // this.listenTo(this.presence, 'change:show', () => this.trigger('presenceChanged'));
        /**
         * Synchronous event which provides a hook for further initializing a StoryData
         * @param { _converse.StoryData } contact
         */
        this.initialized.resolve();
    },

    getDisplayName () {
        // Gets overridden in converse-vcard where the fullname is may be returned
        if (this.get('nickname')) {
            return this.get('nickname');
        } else {
            return this.get('jid');
        }
    },

    getFullname () {
        // Gets overridden in converse-vcard where the fullname may be returned
        return this.get('jid');
    },
});

export default StoryData;
