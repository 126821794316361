import tpl_newchatmenu from './templates/newchatmenu.js';
import tpl_newchatmenu_dracula from './templates//themes/dracula/newchatmenu.js';
import { CustomElement } from 'shared/components/element.js';
import { _converse, api } from '@converse/headless/core';
import { isThemeDracula } from '../../utils/helper.js';

class NewChatMenu extends CustomElement {
    initialize () {
        this.model = _converse.xmppstatus;
        this.listenTo(this.model, "change", () => this.requestUpdate());
    }

    render() {
        return isThemeDracula() ? tpl_newchatmenu_dracula(this) : tpl_newchatmenu(this);
    }

    showProfileModal (ev) {
        ev?.preventDefault();
        api.modal.show('converse-profile-modal', { model: this.model }, ev);
    }

    showStatusChangeModal (ev) {
        ev?.preventDefault();
        api.modal.show('converse-chat-status-modal', { model: this.model }, ev);
    }

    showUserSettingsModal (ev) {
        ev?.preventDefault();
        api.modal.show('converse-user-settings-modal', { model: this.model, _converse }, ev);
    }
}

api.elements.define('converse-new-chat-menu', NewChatMenu);
