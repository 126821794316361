import tpl_delete_message from "./templates/delete-message.js";
import tpl_delete_message_footer from "./templates/delete-message_footer.js";
import BaseModal from "plugins/modal/modal.js";
import { __ } from 'i18n';
import { _converse, api, converse } from "@converse/headless/core";

const u = converse.env.utils;
const { Strophe, $iq, sizzle } = converse.env;


export default class DeleteMessageModal extends BaseModal {

    initialize () {
        super.initialize();
        this.render();
    }

    renderModal () {
        return tpl_delete_message(this);
    }

    getModalTitle () { // eslint-disable-line class-methods-use-this
        return __(`Delete`);
    }
    renderModalFooter() {
        return tpl_delete_message_footer(this);
    }

}

api.elements.define('converse-delete-message-modal', DeleteMessageModal);
