import log from '@converse/headless/log.js';
import { CustomElement } from 'shared/components/element.js';
import { __ } from 'i18n';
import { _converse, api, converse } from '@converse/headless/core.js';
import { getAppSettings } from '@converse/headless/shared/settings/utils.js';
import { getMediaURLs } from '@converse/headless/shared/chat/utils.js';
import { html } from 'lit';
import { isMediaURLDomainAllowed, isDomainWhitelisted } from '@converse/headless/utils/url.js';
import { until } from 'lit/directives/until.js';
import 'plugins/chatview/forward-message.js'

import './styles/message-reactionview.scss';

const { Strophe, u } = converse.env;

class MessageReactionView extends CustomElement {
    static get properties () {
        return {
            is_retracted: { type: Boolean },
            el: { type: Object }
        };
    }

    initialize () {
        this.model = this.el.model
        const settings = getAppSettings();
        this.listenTo(settings, 'change:allowed_audio_domains', () => this.requestUpdate());
        this.listenTo(settings, 'change:allowed_image_domains', () => this.requestUpdate());
        this.listenTo(settings, 'change:allowed_video_domains', () => this.requestUpdate());
        this.listenTo(settings, 'change:render_media', () => this.requestUpdate());
        this.listenTo(this.model, 'change', () => this.requestUpdate());
        api.listen.on('refresh_chat_message', () => {
            this.requestUpdate()
        });
    }

    render () {
        return html`${until(this.renderActions(), '')}`;
    }

    async renderActions () {
        // We want to let the message actions menu drop upwards if we're at the
        // bottom of the message history, and down otherwise. This is to avoid
        // the menu disappearing behind the bottom panel (toolbar, textarea etc).
        // That's difficult to know from state, so we're making an approximation here.

        const buttons = await this.getActionButtons();
        const items = buttons.map(b => MessageReactionView.getActionsDropdownItem(b));
        if (items.length) {
            return html`<converse-reactionview-dropdown
                class="reaction-content-container message-from-${this?.model?.get(`sender`)}"
                .items=${items}
                .model=${this.model}
            ></converse-reactionview-dropdown>`;
        } else {
            return '';
        }
    }

    static getActionsDropdownItem (o) {
        if(o.is_heading) {
            let heading_subdata_html = ``
            let reaction_data = o.model.get(`reaction_data`)
            const counts = {};
            if(reaction_data) {

                for(const o_model_reaction_data of reaction_data) {
                    counts[o_model_reaction_data?.reaction] = (counts[o_model_reaction_data?.reaction] || 0) + 1;
                }
            }
            heading_subdata_html = Object.entries(counts).map(Udata => {
                return html`
                <div class="reaction-view-sub-class">
                <span class="${Udata[0]}">${Udata[0]}</span> ${Udata[1]}
                </div>`
            })
            return html `
            <div class="reactionview-heading-container ${o.class}" @click=${o.handler}>
            <div class="reaction-view-sub-class">
            ${o.title}
            </div>
            ${heading_subdata_html}
            </div>
            `
        }
        return html`
            <div class="reactionview-heading-container-listing ${o.class}" @click=${o.handler}>
                <div class="reaction-view-sub-class-listing">
                    <converse-avatar class="avatar align-self-center"
                    .data=${o.cmodel}
                    nonce=${o.cmodel}
                    height="40" width="40"></converse-avatar>
                </div>
                <div class="reaction-view-sub-class-listing">
                ${o?.cmodel?.nickname} ${o?.cmodel.jid===_converse.bare_jid ? html `<br/><small>Tap to remove</small>` : ``}
                </div>
                <div class="reaction-view-sub-class-listing">
                <span class="${o?.cmodel?.reaction}">${o?.cmodel?.reaction}</span>
                </div>
            </div>
        `;
    }

    async selectedReaction (ev) {
        api.confirm(`We're working on filters.`)
    }
    async removeReaction (ev,data,is_true=false) {
        ev?.preventDefault?.();
        if(is_true) {
            const reaction_data = data.model.get(`reaction_data`) ? data.model.get(`reaction_data`) : []

            let reaction_data_revert_i = 0
            for (const reaction_data_revert of reaction_data) {
                if(reaction_data_revert?.jid===_converse.bare_jid) {
                    reaction_data.splice(reaction_data_revert_i,1)
                }
                reaction_data_revert_i++
            }
            data.model.save({reaction_data: reaction_data})
            api.trigger('refresh_chat_message')
            const reaction_body = reaction_data.length ? reaction_data[reaction_data.length-1].reaction : `_`
            const attrs = {
                'body': reaction_body,
                'is_reaction_message': true,
                'reaction_message_id': data.model.get(`msgid`),
                'reaction_reaction': reaction_data,
                'reaction_removed': '1',
                'reaction_data': [],
                }
                await this.el.chatboxviews.model.sendMessage(attrs)

        }
    }


    /**
     * Adds a media rendering toggle to this message's action buttons if necessary.
     *
     * The toggle is only added if the message contains media URLs and if the
     * user is allowed to show or hide media for those URLs.
     *
     * Whether a user is allowed to show or hide domains depends on the config settings:
     * * allowed_audio_domains
     * * allowed_video_domains
     * * allowed_image_domains
     *
     * Whether media is currently shown or hidden is determined by the { @link hasHiddenMedia } method.
     *
     * @param { Array<MessageActionAttributes> } buttons - An array of objects representing action buttons
     */
    async getActionButtons () {
        const buttons = [];
        let reaction_data = this.model.get(`reaction_data`)
        if(!reaction_data?.length) {
            return api.hook('getMessageActionButtons', this, buttons);
        }
        buttons.push({
            'title': __(`All ${reaction_data?.length}`),
            'is_heading': true,
            'model': this.model,
            'view_type': 'all',
            'handler': ev => this.selectedReaction(ev,this),
        });
        for (const reaction_data_revert of reaction_data) {
            buttons.push({
                'title': __(reaction_data_revert?.reaction),
                'is_heading': false,
                'model' : this.model,
                'cmodel': reaction_data_revert,
                'handler': ev => this.removeReaction(ev,this,reaction_data_revert?.jid===_converse.bare_jid),
            });
    
        }
        /*
        buttons.push({
            'i18n_text': __('Reply'),
            'handler': ev => this.onMessageReply(ev,this),
            'button_class': 'chat-msg__action-reply',
            'icon_class': 'fas fa-trash-alt',
            'name': 'reply',
        });
        */

        /**
         * *Hook* which allows plugins to add more message action buttons
         * @event _converse#getMessageActionButtons
         * @example
         *  api.listen.on('getMessageActionButtons', (el, buttons) => {
         *      buttons.push({
         *          'i18n_text': 'Foo',
         *          'handler': ev => alert('Foo!'),
         *          'button_class': 'chat-msg__action-foo',
         *          'icon_class': 'fa fa-check',
         *          'name': 'foo'
         *      });
         *      return buttons;
         *  });
         */
        return api.hook('getMessageActionButtons', this, buttons);
    }
}

api.elements.define('converse-message-reactionview', MessageReactionView);
