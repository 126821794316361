import { converse } from '@converse/headless/core.js';
import axios from 'axios';
const { u } = converse.env;


  
export function getData (key) {
    return new Promise(async (resolve, reject) => {
        try{
            let data = JSON.parse(localStorage.getItem(key))
            resolve(data)
        }catch(error){
            reject(error);
        }
      })    
}

export function setData (key,data) {
    return new Promise(async (resolve, reject) => {
        try{
            localStorage.setItem(key,JSON.stringify(data))
            resolve(true)
        }catch(error){
            reject(`ERROR SETDATA`,error);
        }
      })    
}



Object.assign(u, { getLocalStore: getData, setLocalStore: setData });
