import { html } from 'lit';
import '../../../styles/camera-capture.scss';
import { api } from '@converse/headless/core';

export default data => {
    const { cameraSeleted, videoSelected, stream } = data;
    let videoElement;
    const chunks = [];
    videoElement = document.createElement('video');
    videoElement.setAttribute('max-width', '400px');
    videoElement.setAttribute('max-height', '400px');
    videoElement.srcObject = stream;
    videoElement.id = stream.id;
    videoElement.playsInline = true;
    videoElement.autoplay = true;
    
    return html`
    <div class="add-moment-main-container">
        <div class="add-moment-sub-container d-flex flex-column px-5 py-4">
            <div class="add-moment-heading mt-2 row">
                <div class="col-4 d-flex justify-content-start">
                ${false
                    ? html`<img src="../../../../images/moments_icons/back-icon.svg" alt="back" @click=${handleBack} />`
                    : html``}
                </div>
                <div class="col-4 d-flex justify-content-center">
                   <div class="add-moment-heading-text">Upload your Moments</div>
                </div>
                <div class="col-4 d-flex justify-content-end">
                    <img src="../../../../images/moments_icons/close-icon.svg" alt="close" @click=${() => {
                           delete data?.uploadError;
                           api.trigger('moment-upload', { ...data, step: 0 });
                       }}
                   />
                </div>
            </div>
            <div class="moments-media-selection-parent d-flex flex-column align-items-center mt-4">
                ${videoElement}
                ${cameraSeleted
                ? html`
                <img class="mt-4" src="../../../../images/moments_icons/capture-btn.svg" alt="Capture" width="60%" @click=${() => handleCaptureImage(videoElement)} />`
                : videoSelected
                ? html`
                <img class="mt-4" src="../../../../images/moments_icons/start-rec-btn.svg" alt="start-recording" @click=${() => handleStartRecording(1)} />`
                : html`
                <img class="mt-4" src="../../../../images/moments_icons/stop-rec-btn.svg" alt="stop-recording" @click=${() => handleStartRecording(2)} />`}
            </div>
        </div>
    </div>`;

    function handleCaptureImage(video) {
        let canvas = document.createElement('canvas');
        canvas.width = 500;
        canvas.height = 300;
        let context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        // get image data from canvas
        const fileType = 'image/png';
        let imageUrl = canvas.toDataURL(fileType);
        api.trigger('moment-upload', {
            step: 2,
            imageUrl,
            file_type: fileType,
            capturedImage: true,
            cameraSeleted: true
        });
    }

    function handleStartRecording(btnAction) {
        if (btnAction === 1) {
            const file_type = 'video/webm';
            let mediaRecorder;
            mediaRecorder = new MediaRecorder(stream, { mimeType: file_type });
            mediaRecorder.ondataavailable = function (e) {
                chunks.push(e.data);
            };
            mediaRecorder.addEventListener('stop', function () {
                const blob = new Blob(chunks, { type: file_type });
                api.trigger('moment-upload', {
                    step: 2,
                    capturedVideo: true,
                    imageUrl: blobToFile(blob, file_type),
                    file_type,
                    stream,
                    videoSelected: true
                });
            });
            api.trigger('moment-upload', {
                step: 4,
                stream,
                cameraSeleted: false,
                videoSelected: false,
                mediaRecorder,
            });
            mediaRecorder.start();
        } else {
            data.mediaRecorder.stop();
        }
    }

    function blobToFile(blob, fileName) {
        const fileType = blob.type;
        const arrayOfBlobParts = [blob];
        return new File(arrayOfBlobParts, fileName, { type: fileType });
    }

    function handleBack() {
        api.trigger('moment-upload', {
            ...data,
            cameraSeleted: false,
            librarySelected: false,
            videoSelected: false,
            step: 1,
        });
    }
};
