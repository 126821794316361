import DropdownBase from "shared/components/dropdown.js";
import { __ } from 'i18n';
import { _converse, api, converse } from "@converse/headless/core";
import { html } from "lit";
import { initStorage } from '@converse/headless/utils/storage.js';
import { until } from 'lit/directives/until.js';

const u = converse.env.utils;


export default class EmojiDropdown extends DropdownBase {

    static get properties() {
        return {
            chatview: { type: Object }
        };
    }

    constructor () {
        super();
        // This is an optimization, we lazily render the emoji picker, otherwise tests slow to a crawl.
        this.render_emojis = false;
    }

    initModel () {
        if (!this.init_promise) {
            this.init_promise = (async () => {
                await api.emojis.initialize()
                const id = `converse.emoji-${_converse.bare_jid}-${this.chatview.model.get('jid')}`;
                this.model = new _converse.EmojiPicker({'id': id});
                initStorage(this.model, id);
                await new Promise(resolve => this.model.fetch({'success': resolve, 'error': resolve}));
                // We never want still be in the autocompleting state upon page load
                this.model.set({'autocompleting': null, 'ac_position': null});
            })();
        }
        return this.init_promise;
    }

    render() {
        if(this.chatview){
            const is_groupchat = this.chatview?.model?.get('type') === _converse.CHATROOMS_TYPE;
            const color = is_groupchat ? '--muc-toolbar-btn-color' : '--chat-toolbar-btn-color';
            return html`
                <div class="dropup">
                    <button class="toggle-emojis"
                            title="${__('Insert emojis')}"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            style="border: none;">
                            <svg id="Group_15702" data-name="Group 15702" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20.25" height="20.247" viewBox="0 0 20.25 20.247">
                                <defs>
                                    <linearGradient id="linear-gradient" x1="-0.048" x2="0.975" y2="1.036" gradientUnits="objectBoundingBox">
                                    <stop offset="0" stop-color="#ff5850"/>
                                    <stop offset="1" stop-color="#fe2b51"/>
                                    </linearGradient>
                                    <clipPath id="clip-path">
                                    <rect id="Rectangle_4895" data-name="Rectangle 4895" width="20.25" height="20.247" fill="url(#linear-gradient)"/>
                                    </clipPath>
                                </defs>
                                <g id="Group_15701" data-name="Group 15701" clip-path="url(#clip-path)">
                                    <path id="Path_9438" data-name="Path 9438" d="M11.695,15.044v5.1c-.078.041-.12.083-.161.083-1.815-.014-3.643.1-5.441-.078A6.687,6.687,0,0,1,.071,13.786,68.894,68.894,0,0,1,.142,6.206,6.473,6.473,0,0,1,6.192.15,64.194,64.194,0,0,1,13.821.084a6.817,6.817,0,0,1,6.405,6.779c.028,1.2,0,2.4.023,3.6.008.382-.14.455-.484.457-1.28.008-2.561.029-3.84.077A4.2,4.2,0,0,0,12.9,12.45,3.578,3.578,0,0,1,7.2,12.038c-.073-.107-.135-.222-.213-.325a.761.761,0,0,0-1.023-.263.749.749,0,0,0-.3,1.05,5.187,5.187,0,0,0,5.538,2.626c.156-.031.313-.053.486-.081m1.725-8.8a1.148,1.148,0,0,0-1.127,1.16,1.165,1.165,0,0,0,1.155,1.143A1.186,1.186,0,0,0,14.58,7.378,1.16,1.16,0,0,0,13.42,6.246M5.832,7.369A1.135,1.135,0,1,0,8.1,7.415a1.135,1.135,0,1,0-2.269-.046" transform="translate(0 0)" fill="url(#linear-gradient)"/>
                                    <path id="Path_9439" data-name="Path 9439" d="M143.11,128.564c.212,3.174-3.47,7.2-6.886,7.51a1.03,1.03,0,0,1-.064-.255c.01-1.426-.018-2.855.052-4.278a2.8,2.8,0,0,1,2.888-2.958c1.319-.09,2.648-.018,4.01-.018" transform="translate(-122.912 -116.03)" fill="url(#linear-gradient)"/>
                                </g>
                            </svg>
                    </button>
                    <div class="dropdown-menu">
                        ${until(this.initModel().then(() => html`
                            <converse-emoji-picker
                                    .chatview=${this.chatview}
                                    .model=${this.model}
                                    @emojiSelected=${() => this.hideMenu()}
                                    ?render_emojis=${this.render_emojis}
                                    current_category="${this.model.get('current_category') || ''}"
                                    current_skintone="${this.model.get('current_skintone') || ''}"
                                    query="${this.model.get('query') || ''}"
                            ></converse-emoji-picker>`), '')}
                    </div>
                </div>`;    
        }else{
            return ``
        }
    }

    connectedCallback () {
        super.connectedCallback();
        this.render_emojis = false;
    }

    toggleMenu (ev) {
        ev.stopPropagation();
        ev.preventDefault();
        if (u.hasClass('show', this.menu)) {
            if (u.ancestor(ev.target, '.toggle-emojis')) {
                this.hideMenu();
            }
        } else {
            this.showMenu();
        }
    }

    async showMenu () {
        await this.initModel();
        if (!this.render_emojis) {
            // Trigger an update so that emojis are rendered
            this.render_emojis = true;
            this.requestUpdate();
            await this.updateComplete;
        }
        super.showMenu();
        setTimeout(() => this.querySelector('.emoji-search')?.focus());
    }
}

api.elements.define('converse-emoji-dropdown', EmojiDropdown);
