import { api } from '@converse/headless/core';
import { __ } from 'i18n';
import { html } from 'lit';
import { until } from 'lit/directives/until.js';

function tpl_audio_recording_button (el) {
    const i18n_audio_recording_button = __('Record Audio')
    return html`<button type="submit" class="send-button" @click=${(ev) => {
        el.model.audioRecording(ev)
    }} title="${ i18n_audio_recording_button }">
    <i class="${el.model.get(`audioRecordingState`)===1 ? `fa fa-microphone` : `fas fa-stop-circle`} text-white"  aria-hidden="true"></i>
    </button>`
}

function tpl_audio_recording_timer (el) {
    if(el.model.get(`audioRecordingState`)===1)
    {
        return ``
    }
    if(el.model.get(`audioRecordingState`)===2)
    {
        const timer = el.model.get(`recordingtime`) ? el.model.get(`recordingtime`) : 0
        return html`<div style="display: inline-flex; z-index: 1; margin-right: 20px; margin-top: 23px;">Recording : (${timer})</div>`    
    }
}

function tpl_send_button () {
    const i18n_send_message = __('Send the message');
    return html`<button type="submit" class="btn send-button send-button-toggle" title="${ i18n_send_message }">   
        <converse-icon color="var(--toolbar-btn-text-color)"  class="fa fa-paper-plane"  size="1em"></converse-icon>
    </button>  
 
    `
}

export default (el) => {
    let message_limit = api.settings.get('message_limit');
    message_limit = false;
    return html`
        <span class="toolbar-buttons" >${until(el.getButtons(), '')}</span>
        ${tpl_audio_recording_timer(el)}
        <div style="display: inline-flex; z-index: 1; margin-right: 20px; margin-top: 23px;">
            ${ el.model.get(`draft`)?.length ? tpl_send_button() : tpl_audio_recording_button(el) }
            ${message_limit ? html `<converse-message-limit-indicator .model=${el.model} style="
            margin-top: 30%;
            margin-left: 10px;
        ">
            </converse-message-limit-indicator>` : ``}
        </div>`;
}
