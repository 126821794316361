import { _converse } from '@converse/headless/core';
import tpl_camera_capture from './camera-capture.js';
import tpl_moment_upload_options from './moment-upload-options.js';

export default (jsonData) => {
    return jsonData?.step === 1 || jsonData?.step === 2 || jsonData?.step === 3
        ? tpl_moment_upload_options(jsonData)
        : jsonData?.step === 4
        ? tpl_camera_capture(jsonData)
        : '';
};