import { generateFingerprint, getDevicesForContact, } from '../utils.js';
import { _converse, api, converse } from '@converse/headless/core.js';


const ConverseMixins = {

    async generateFingerprints (jid) {
        const devices = await getDevicesForContact(jid);
        return Promise.all(devices.map(d => generateFingerprint(d)));
    },

    getDeviceForContact (jid, device_id) {
        return getDevicesForContact(jid).then(devices => devices.get(device_id));
    },

    async contactHasOMEMOSupport (jid) {
        let devices_length = 0

        if(jid.includes(api.settings.get(`muclight_domain`)))
        {
            devices_length = 1
        }else{
            const devices = await getDevicesForContact(jid);
            devices_length = (devices_length+devices.length)
    }
        /* Checks whether the contact advertises any OMEMO-compatible devices. */
        return devices_length > 0;
    }
}

export default ConverseMixins;
