import 'shared/components/font-awesome.js';
import { _converse, api } from '@converse/headless/core';
import { html } from 'lit';
import '../styles/hookzOffice-no-flyout.scss';
import { logOut } from '../../profile/utils';

export default el => {
    const extra_classes = api.settings.get('singleton') ? ['converse-singleton'] : [];
    extra_classes.push(`converse-${api.settings.get('view_mode')}`);
    const workstation = el?.model?.get({ id: el?.modeldata?.get('selected_workstation') });
    // console.log('el.showUserAccountDropdown', el.showUserAccountDropdown);

    // document.addEventListener('click', (ev) => {
    //     // if(ev.contains('user-profile-dropdown-menu')) {
    //     //     console.log('hello world');
    //     // }
    //     let clickedElement = ev.composedPath();
    //     // console.log('clickedElement', clickedElement);
    //     let hideDropdownMenu = true;
    //     clickedElement.forEach((element) => {
    //         // console.log(element.classList?.contains('user-profile-dropdown-menu'));
    //         console.log(element.classList?.contains('user-avatar-to-open-dropdown'), el.showUserAccountDropdown);
    //         if(element.classList?.contains('user-avatar-to-open-dropdown')){
    //             if(!el.showUserAccountDropdown){
    //                 api.trigger('showUserAccountDropdown', true);
    //             }else if(el.showUserAccountDropdown) {
    //                 api.trigger('showUserAccountDropdown', false);
    //             }
    //         }
    //         else if(!element.classList?.contains('user-profile-dropdown-menu')){
    //             // api.trigger('showUserAccountDropdown', false);
    //         }
    //     })
    //     // console.log('hideDropdownMenu', hideDropdownMenu);
    //     // api.trigger('showUserAccountDropdown', hideDropdownMenu);
    // });

    function handleTogglePauseNotifications(e) {
        api.trigger('showPauseNotificationsDropdown', !el.showPauseNotificationsDropdown);
    }

    function handleMouseLeave(e) {
        api.trigger('showPauseNotificationsDropdown', false);
    }

    const allUserAccounts = [
        {
            id: 1,
            name: 'Abdulla Ahmad',
            email: 'abdulla@wingtrill.com',
        },
        {
            id: 2,
            name: 'Kashish Gandhi',
            email: 'kashish@wingtrill.com',
        },
        {
            id: 3,
            name: 'Manjit Singh',
            email: 'manjit@wingtrill.com',
        },
    ];

    return html`
        <div class="d-flex hookzoffice-main-header px-3 py-2 align-items-center">
            <div class="col-md-2 col-sm-12 d-flex align-items-center p-0">
                <span class="workstation-titles-top-navigation">${workstation?.get('name') || ``}</span>
                <svg xmlns="http:/ ml-2/www.w3.org/2000/svg" width="9.622" height="5.501" viewBox="0 0 9.622 5.501">
                    <path
                        id="Icon_ionic-ios-arrow-back"
                        data-name="Icon ionic-ios-arrow-back"
                        d="M12.909,11,16.55,7.366a.688.688,0,1,0-.974-.971l-4.125,4.122a.686.686,0,0,0-.02.948l4.142,4.151a.688.688,0,0,0,.974-.971Z"
                        transform="translate(-6.194 16.752) rotate(-90)"
                        fill="#f8f8f8"
                    />
                </svg>
            </div>
            <div class="col-md-8 col-sm-12">
                <div class="d-flex align-items-center search-bar p-2">
                    <span class="ml-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16.861"
                            height="16.865"
                            viewBox="0 0 16.861 16.865"
                        >
                            <path
                                id="Icon_ionic-ios-search"
                                data-name="Icon ionic-ios-search"
                                d="M21.163,20.138,16.474,15.4a6.683,6.683,0,1,0-1.014,1.027l4.659,4.7a.722.722,0,0,0,1.019.026A.726.726,0,0,0,21.163,20.138Zm-9.941-3.649a5.277,5.277,0,1,1,3.732-1.546A5.244,5.244,0,0,1,11.222,16.489Z"
                                transform="translate(-4.5 -4.493)"
                                fill="#d1d1d1"
                            />
                        </svg>
                    </span>
                    <input type="text" class="search-input ml-2" placeholder="Search" />
                    <img
                        src="/images/hookz-office/chat-header/vertical-line.svg"
                        alt=""
                        class="top-header-search-bar-vertical-line-margin"
                    />
                    <img
                        src="/images/hookz-office/chat-header/bell.svg"
                        alt=""
                        class="top-header-search-bar-notification-bell-margin"
                    />
                    <span class="turn-on-notifications">Turn on notifications</span>
                </div>
                ${
                    ''
                    //  <div class="has-search w-100">
                    //                 <span class="form-control-feedback">
                    //                     <svg xmlns="http://www.w3.org/2000/svg" width="16.861" height="16.865" viewBox="0 0 16.861 16.865">
                    //                     <path id="Icon_ionic-ios-search" data-name="Icon ionic-ios-search" d="M21.163,20.138,16.474,15.4a6.683,6.683,0,1,0-1.014,1.027l4.659,4.7a.722.722,0,0,0,1.019.026A.726.726,0,0,0,21.163,20.138Zm-9.941-3.649a5.277,5.277,0,1,1,3.732-1.546A5.244,5.244,0,0,1,11.222,16.489Z" transform="translate(-4.5 -4.493)" fill="#d1d1d1"/>
                    //                     </svg>
                    //                 </span>
                    //                 <input type="text" class="form-control" placeholder="Search">
                    //             </div>
                }
            </div>
            <div class="col-md-2 col-sm-12 d-flex align-items-center p-0 position-relative">
                <span class="top-bar-right-profile">
                    <img src="/images/hookz-office/hookz-connect-logo.svg" alt="hookz connect" />
                </span>
                <span
                    class="cursor-pointer user-avatar-to-open-dropdown"
                    @click=${() => api.trigger('showUserAccountDropdown', !el.showUserAccountDropdown)}
                >
                    <converse-avatar height="40px" width="40px"></converse-avatar>
                </span>
                ${el.showUserAccountDropdown
                    ? html`<div class="user-profile-dropdown-menu p-2 p-lg-3 p-xl-4">
                          <span class="d-flex align-items-center py-2 py-xl-3 btrlr-15">
                              <converse-avatar height="50px" width="50px"></converse-avatar>
                              <span class="d-flex flex-column ml-2 ml-xxl-3">
                                  <span class="d-flex align-items-center">
                                      <span class="user-name-font-size">John Doe</span>
                                      <img class="ml-1 admin" src="/images/hookz-office/admin.svg" alt="admin" />
                                  </span>
                                  <span class="sections-font-size">Active</span>
                              </span>
                          </span>
                          <section class="d-flex flex-column my-1">
                              <span
                                  class="pl-2 pl-lg-3 pl-xl-4 py-1 py-lg-2 py-xl-3 menu-section admin-console sections-font-size btrlr-15"
                              >
                                  Admin console
                              </span>
                              <span
                                  class="pl-2 pl-lg-3 pl-xl-4 py-1 py-lg-2 py-xl-3 menu-section change-online-status sections-font-size"
                              >
                                  Set yourself as away
                              </span>
                              <span
                                  class="pl-2 pl-lg-3 pl-xl-4 pr-2 pr-lg-3 pr-xl-4 py-1 py-lg-2 py-xl-3 bbrlr-15 change-notifications d-flex justify-content-between align-items-center sections-font-size"
                                  @click=${handleTogglePauseNotifications}
                                  >Pause notifications</span
                              >
                          </section>
                          <section class="d-flex flex-column my-1">
                              <span
                                  class="pl-2 pl-lg-3 pl-xl-4 py-1 py-lg-2 py-xl-3 btrlr-15 menu-section profile sections-font-size"
                              >
                                  Profile
                              </span>
                              <span
                                  class="pl-2 pl-lg-3 pl-xl-4 py-1 py-lg-2 py-xl-3 menu-section preferences sections-font-size"
                              >
                                  Preferences
                              </span>
                              <span
                                  class="pl-2 pl-lg-3 pl-xl-4 py-1 py-lg-2 py-xl-3 bbrlr-15 upgrade sections-font-size"
                              >
                                  Upgrade ${workstation?.get('name') || ``}</span
                              >
                          </section>
                          <section class="d-flex flex-column my-1">
                              <span class="accounts-wrapper">
                                  ${allUserAccounts.map((account, index) => {
                                      const isFirstItem = index === 0;
                                      if (!isFirstItem && !el.showAllUserAccountsDropdown) return '';
                                      return html`<span
                                          class="pl-2 pl-lg-3 pl-xl-4 py-1 py-lg-2 py-xl-3 align-items-center menu-section sign-out ${isFirstItem
                                              ? 'btrlr-15'
                                              : ''} d-flex"
                                      >
                                          <converse-avatar height="30px" width="30px"></converse-avatar>
                                          <span class="d-flex align-items-center justify-content-between w-100">
                                              <span class="d-flex flex-column ml-2">
                                                  <span class="sections-font-size">Sign out of ${account.name}</span>
                                                  <span class="email-font-size mt-1">${account.email}</span>
                                              </span>
                                              ${isFirstItem
                                                  ? html`<converse-icon
                                                        size="1em"
                                                        class="fa fa-angle-${el.showAllUserAccountsDropdown
                                                            ? 'up'
                                                            : 'down'} fa-bars mr-2 mr-lg-3 mr-xl-4"
                                                        @click=${() => {
                                                            api.trigger(
                                                                'showAllUserAccountsDropdown',
                                                                !el.showAllUserAccountsDropdown
                                                            );
                                                        }}
                                                    ></converse-icon>`
                                                  : ''}
                                          </span>
                                      </span>`;
                                  })}
                              </span>
                              <span class="pl-2 pl-lg-3 pl-xl-4 py-1 py-lg-2 py-xl-3 menu-section sign-out d-flex">
                                  <img src="/images/hookz-office/add.svg" alt="add" width="30" />
                                  <span class="d-flex align-items-center justify-content-between w-100">
                                      <span class="d-flex flex-column ml-2">
                                          <span class="sections-font-size">Add another account</span>
                                      </span>
                                  </span>
                              </span>
                              <span
                                  class="pl-2 pl-lg-3 pl-xl-4 py-1 py-lg-2 py-xl-3 sign-out bbrlr-15 d-flex"
                                  @click=${logOut}
                              >
                                  <img src="/images/hookz-office/sign-out-round.svg" alt="add" width="30" />
                                  <span class="d-flex align-items-center justify-content-between w-100">
                                      <span class="d-flex flex-column ml-2">
                                          <span class="sections-font-size">Sign out of all accounts</span>
                                      </span>
                                  </span>
                              </span>
                          </section>
                          ${el.showPauseNotificationsDropdown
                              ? html`<div class="pause-notifications-dropdown-menu sections-font-size p-2 p-xl-3">
                                    <span><small>Mute notifications for...</small></span>
                                    <span class="durations-wrapper mt-2">
                                        <span
                                            class="pl-2 pl-lg-3 pl-xl-4 py-1 py-lg-2 py-xl-3 menu-section admin-console d-flex flex-column btrlr-15"
                                        >
                                            <span class="mt-1">For 30 minutes</span>
                                        </span>
                                        <span
                                            class="pl-2 pl-lg-3 pl-xl-4 py-1 py-lg-2 py-xl-3 menu-section change-online-status"
                                        >
                                            For 1 hour
                                        </span>
                                        <span
                                            class="pl-2 pl-lg-3 pl-xl-4 pr-2 pr-lg-3 pr-xl-4 py-1 py-lg-2 py-xl-3 menu-section change-notifications d-flex justify-content-between align-items-center"
                                            ><span>For 2 hour</span></span
                                        >
                                        <span class="pl-2 pl-lg-3 pl-xl-4 py-1 py-lg-2 py-xl-3 menu-section profile">
                                            Until tomorrow
                                        </span>
                                        <span class="pl-2 pl-lg-3 pl-xl-4 py-1 py-lg-2 py-xl-3 preferences bbrlr-15">
                                            Custom
                                        </span>
                                    </span>
                                </div>`
                              : ''}
                      </div>`
                    : ''}
            </div>
        </div>
        <div style="display: block; height: 70px; position: inherit;"></div>
        <div class="position-relative">
            <converse-chats class="converse-chatboxes row no-gutters ${extra_classes.join(' ')}"></converse-chats>
            <div class="hookzOffice">
                <div class="hookzoffice-no-flyout overflow-auto">
                    <div class="hookzoffice-no-flyout-innerdiv">
                        <div class="row mb-3 w-100">
                            <div class="w-100 d-flex justify-content-center">
                                <img
                                    id="welcome-to-hoozoffice-logo"
                                    src="../../../../images/hookz-office/welcome-to-hookzoffice.svg"
                                    alt="Welcome to HookzOffice"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="secondary-heading">
                                Think social media; purpose built for business communication.
                            </div>
                        </div>
                        <div class="row py-5 justify-content-between cards-row">
                            <div class="col-auto d-flex flex-column bg-secondary-color p-3 br-15 column">
                                <div>
                                    <img
                                        class="w-100"
                                        src="../../../../images/hookz-office/invite-your-team.svg"
                                        alt="invite-your-team"
                                    />
                                </div>
                                <div class="mt-3">
                                    <div class="card-primary-heading">Invite your teams</div>
                                    <div class="mt-2 column-text-content card-secondary-heading">
                                        Effortlessly onboard your co-workers for unparalleled collaboration, it's
                                        simple!
                                    </div>
                                    <div class="d-flex justify-content-center mt-5">
                                        <button
                                            type="button"
                                            class="btn text-white py-1 card-btn"
                                            @click=${ev =>
                                                api.modal.show(
                                                    'converse-invite-member-to-workstation-modal',
                                                    { 'model': el.model, ev },
                                                    ev
                                                )}
                                        >
                                            Invite
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto d-flex flex-column bg-secondary-color p-3 br-15 column">
                                <div>
                                    <img
                                        class="w-100"
                                        src="../../../../images/hookz-office/chat-like-social.svg"
                                        alt="invite-your-team"
                                    />
                                </div>
                                <div class="mt-3">
                                    <div class="card-primary-heading">Chat like social</div>
                                    <div class="mt-2 column-text-content card-secondary-heading">
                                        Initiate lively conversations, secure and simple
                                    </div>
                                    <div class="d-flex justify-content-center mt-5">
                                        <button
                                            type="button"
                                            class="btn text-white py-1 card-btn"
                                            @click=${ev =>
                                                api.modal.show(
                                                    'converse-initiate-new-modal',
                                                    { 'model': el.model, ev },
                                                    ev
                                                )}
                                        >
                                            Start a chat
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto d-flex flex-column bg-secondary-color p-3 br-15 column">
                                <div>
                                    <img
                                        class="w-100"
                                        src="../../../../images/hookz-office/connect-now.svg"
                                        alt="invite-your-team"
                                    />
                                </div>
                                <div class="mt-3">
                                    <div class="card-primary-heading">Connect now</div>
                                    <div class="mt-2 column-text-content card-secondary-heading">
                                        Create an instant meeting or schedule a video conference within the app
                                    </div>
                                    <div class="d-flex justify-content-center mt-5">
                                        <button
                                            type="button"
                                            class="btn text-white py-1 card-btn"
                                        >
                                            Meet now
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row secondary-heading mt-4">Get the HookZOffice mobile app</div>
                        <div class="row mt-2 tertiary-heading">
                            Stay connected across all your devices by downloading the app
                        </div>
                        <div class="d-flex justify-content-center mt-3 w-25">
                            <button
                                type="button"
                                class="btn text-white py-1 card-btn"
                            >
                                Download App
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="converse-modals" class="modals"></div>
            <converse-fontawesome></converse-fontawesome>
        </div>
    `;
};
