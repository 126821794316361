import { __ } from 'i18n';
import { api, converse } from "@converse/headless/core";
import { html } from "lit";
import { resetElementHeight } from '../utils.js';


export default (o,chatbox) => {
    const label_message = o.composing_spoiler ? __('Hidden message') : __('Type a message');
    const label_spoiler_hint = __('Optional hint');
    const show_send_button = api.settings.get('show_send_button');
    let text = o.getMessageText();
    const message_type = o.get('message_type');

    function closeReplyBox() {
      chatbox.save({reply_msg_id: null})
    }
    let icon = ``
    if (message_type == converse.MESSAGE_TYPE.LOCATION) {
      icon = html `<i class="fas fa-map-marker-alt"></i>`
      text = `LOCATION`
    }else if (message_type == converse.MESSAGE_TYPE.IMAGE) {
      icon = html `<i class="fas fa-camera-retro"></i>`
      text = `IMAGE`
    }else if(message_type == converse.MESSAGE_TYPE.VIDEO) {
      icon = html `<i class="fas fa-video-camera"></i>`
      text = `VIDEO`
    }else if(message_type == converse.MESSAGE_TYPE.AUDIO) {
      icon = html `<i class="fas fa-play"></i>`
      text = `AUDIO`
    }else if(message_type == converse.MESSAGE_TYPE.DOCUMENT) {
      icon = html `<i class="fas fa-file"></i>`
      text = `DOCUMENT`
    }
    let nickName = "Unknown";
    if (o.get(`sender`) === "me") {
        nickName = "You";
    } else {
        if (o.get(`nickname`)) {
          nickName = o.get(`nickname`);
        } else if (o.get(`nick`)) {
          nickName = o.get(`nick`);
        }
    }

    return html`
    <div class="reply-main-container p-2">
      <div class="reply-main-sub-container">
        <div class="reply-sub-container text-left font-weight-bold">
          ${nickName}
        </div>
        <div class="reply-sub-child-container text-align-right"></div>
        <div class="reply-sub-child-close-container text-align-right">
          <div class="cursor-grab " @click=${closeReplyBox}>&times;</div>
        </div>
      </div>
      <div class="reply-sub-chat-msg-container mt-1">
        ${icon} ${text}
      </div>
    </div>`;
}
