import DOMPurify from 'dompurify';
import { __ } from 'i18n';
import { api, _converse } from '@converse/headless/core.js';
import { html } from "lit";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { getAutoCompleteList } from "../../search.js";
import { CHAT_TYPE } from '@converse/headless/plugins/muclight/constants.js';

import '../styles/add-muclight.scss';


export default (contacts, el) => {
    const selected_occupants = el.model.get('group_occupants') || [];

    function scrollContacts(direction,ev) {
        const scrollContainer = ev.target.parentElement
        let scrollContainerOffsetWidth = scrollContainer.offsetWidth;
        if (direction === 'left') {
            scrollContainerOffsetWidth = -scrollContainerOffsetWidth;
        }
        scrollContainer.scrollBy({ left: scrollContainerOffsetWidth, behavior: 'smooth' });
    }

    return html`<div class="d-flex align-items-center contacts-container">
            <img
                class="cursor-pointer position-absolute"
                style="left: 0; z-index: 1"
                src="../../../../../images/moments_icons/left-moments-arrow.svg"
                alt="view more"
                @click=${ev => scrollContacts('left', ev)}
            />
            <div class="d-flex mx-2" style="z-index: 0;">
                ${contacts.map(contact => {
                    return html` <div class="selected-occupants-list-sub-container">
                        <div class="selected-occupant-avatar position-relative">
                            <converse-avatar
                                class="avatar align-self-center"
                    .data=${contact?.attributes?.image?.length
                        ? contact?.attributes
                        : contact?.vcard?.attributes}
                    nonce=${contact?.attributes?.image?.length
                        ? contact?.attributes
                        : contact?.vcard?.attributes}
                                height="40"
                                width="40"
                            ></converse-avatar>
                            <img
                                @click=${async ev => {
                                    selected_occupants.includes(contact.get(`jid`))
                                        ? selected_occupants.indexOf(contact.get(`jid`)) > -1
                                            ? selected_occupants.splice(selected_occupants.indexOf(contact.get(`jid`)), 1)
                                            : ``
                                        : selected_occupants.push(contact.get(`jid`));
                                    await el.model.save({ group_occupants: selected_occupants });
                                    api.trigger('refresh_group_occupants');
                                }}
                                class="position-absolute"
                                src="../../../../../images/group-icons/remove-participant.svg"
                                alt="remove"
                                style="right: 0;"
                            />
                        </div>
                        <span>${contact.get('nickname')}</span>
                    </div>`;
                })}
            </div>
            <img
                class="cursor-pointer position-absolute"
                style="right: 0; z-index: 1"
                src="../../../../../images/moments_icons/right-moments-arrow.svg"
                alt="view more"
                @click=${ev => scrollContacts('right', ev)}
            />
    </div>`;
};
