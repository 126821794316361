import { IV_KEY } from "../shared/constants";
const CryptoJS = require("crypto-js");

export async function encryptData (encryptedData) {
  try {
    const iv = CryptoJS.lib.WordArray.random(16);
    var cipher = CryptoJS.AES.encrypt(
      encryptedData,
      CryptoJS.enc.Hex.parse(IV_KEY.AES_KEY),
      { iv: iv }
    );
    return { iv: iv.toString(), encryptedData: cipher.ciphertext.toString() };
  } catch (error) {
    console.log(`Error in encryptData : `, error);
    return error;
  }
}

export async function decryptData (data) {
  try {
    data = data.replace(/(\r\n|\n|\r)/gm, "")
    var cipher = CryptoJS.algo.AES.createDecryptor(
      CryptoJS.enc.Hex.parse(IV_KEY.AES_KEY),
      { iv: CryptoJS.enc.Hex.parse(IV_KEY.AES_IV) }
    );
    let datacipher = cipher
      .process(CryptoJS.enc.Hex.parse(data))
      .concat(cipher.finalize())
      .toString(CryptoJS.enc.Utf8);
    return datacipher;
  } catch (error) {
    console.log(`Error in decryptData : `, error);
    return error;
  }
}
export async function encryptDataWithCBC (data) {
  try {
    let cipher = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(IV_KEY.AES_KEY), {
      iv: CryptoJS.enc.Utf8.parse(IV_KEY.AES_IV), // parse the IV 
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
   });
   return cipher.toString();
  } catch (error) {
    console.log(`Error in decryptData : `, error);
    return error;
  }
}

export async function decryptDataWithCBC (data) {
  try {
    data = data.replace(/(\r\n|\n|\r)/gm, "")
    let decipher = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(IV_KEY.AES_KEY), {
      iv: CryptoJS.enc.Utf8.parse(IV_KEY.AES_IV), // parse the IV 
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
   });
   return decipher.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.log(`Error in decryptData : `, error);
    return error;
  }
}
