import { _converse } from '@converse/headless/core.js';
import { html } from "lit";
import { modal_close_button } from "plugins/modal/templates/buttons.js";

export default (el) => {
    const i18n_Tag = `Tag`
    return html`
    <div class="modal-footer">
        ${modal_close_button}
        <button @click=${(ev) => el.tagMembers({ model: el.model, occupants: _converse.roompanel.get('group_occupants') || [] })} class="btn btn-danger btn-rounded mt-2 tag-btn" />${i18n_Tag}</button>
    </div>`;
}
