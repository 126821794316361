import tpl_roster from "./templates/roster.js";
import tpl_roster_dracula from "./templates/themes/dracula/roster_dracula.js";
import { CustomElement } from 'shared/components/element.js';
import { Model } from '@converse/skeletor/src/model.js';
import { _converse, api, converse } from "@converse/headless/core";
import { initStorage } from '@converse/headless/utils/storage.js';
import { slideIn, slideOut } from 'utils/html.js';
import { isThemeDracula } from "../../utils/helper.js";
import log from "@converse/headless/log.js";
import { __ } from 'i18n';

const { Strophe, $iq } = converse.env;

/**
 * @class
 * @namespace _converse.RosterView
 * @memberOf _converse
 */
export default class RosterView extends CustomElement {

    async initialize () {
        const id = `converse.contacts-panel${_converse.bare_jid}`;
        this.model = new Model({ id });
        initStorage(this.model, id);
        this.model.fetch();

        await api.waitUntil('rosterInitialized')
        await api.waitUntil('rosterContactsFetched')

        const { chatboxes, presences, roster } = _converse;
        this.listenTo(_converse, 'rosterContactsFetched', () => this.requestUpdate());
        this.listenTo(presences, 'change:show', () => this.requestUpdate());
        this.listenTo(chatboxes, 'change:hidden', () => this.requestUpdate());
        this.listenTo(roster, 'add', () => this.requestUpdate());
        this.listenTo(roster, 'destroy', () => this.requestUpdate());
        this.listenTo(roster, 'remove', () => this.requestUpdate());
        this.listenTo(roster, 'change', () => this.requestUpdate());
        this.listenTo(roster.state, 'change', () => this.requestUpdate());
        this.listenTo(this.model, 'change', () => this.requestUpdate());
        api.listen.on(`refresh_contact_list`, (jid) => {
            this.requestUpdate()
        });
        api.listen.on(`refresh_contact_list_for_archived`, (tab) => {
            this.activeTab = tab;
            this.requestUpdate()
        });
        api.listen.on(`updateTab`, (tab) => {
            this.chatHeaderActiveTab = tab;
            this.requestUpdate()
        });
        /**
         * Triggered once the _converse.RosterView instance has been created and initialized.
         * @event _converse#rosterViewInitialized
         * @example _converse.api.listen.on('rosterViewInitialized', () => { ... });
         */
        api.trigger('rosterViewInitialized');
    }

    render () {
        return isThemeDracula() ? tpl_roster_dracula(this) : tpl_roster(this);
    }

    showAddContactModal (ev) { // eslint-disable-line class-methods-use-this
        api.modal.show('converse-add-contact-modal', {'model': new Model()}, ev);
    }

    async syncContacts (ev) { // eslint-disable-line class-methods-use-this
        ev.preventDefault();
        const { roster, story } = _converse;
        this.syncing_contacts = true;
        this.requestUpdate();

        roster.data.save('version', null);
        await roster.fetchFromServer();
        api.user.presence.send();

        this.syncing_contacts = false;
        this.requestUpdate();
    }

    toggleRoster (ev) {
        ev?.preventDefault?.();
        const list_el = this.querySelector('.list-container.roster-contacts');
        if (this.model.get('toggle_state') === _converse.CLOSED) {
            slideOut(list_el).then(() => this.model.save({'toggle_state': _converse.OPENED}));
        } else {
            slideIn(list_el).then(() => this.model.save({'toggle_state': _converse.CLOSED}));
        }
    }

    removeFromRoster (jid) {
        const iq = $iq({type: 'set'})
            .c('query', {xmlns: Strophe.NS.ROSTER})
            .c('item', {jid, subscription: "remove"});
        return api.sendIQ(iq);
    }

    async removeContact (ev, jid) {
        ev?.preventDefault?.();
        if (!api.settings.get('allow_contact_removal')) { return; }

        const result = await api.confirm(__("Are you sure you want to remove this contact?"));
        if (!result)  return;

        try {
            this.removeFromRoster(jid);
            if (this.model.collection) {
                // The model might have already been removed as
                // result of a roster push.
                this.model.destroy();
            }
        } catch (e) {
            log.error(e);
            api.alert('error', __('Error'),
                [__('Sorry, there was an error while trying to remove %1$s as a contact.', this?.model?.getDisplayName())]
            );
        }
    }

    clearChat (contact) {
        const messages_data = _converse?.chatboxes?.models?.find(model => model.id === contact.id);
        const messages = messages_data?.messages?.models;
        for(const message of messages) {
            message.save({is_deleted: true})
        }
        return true
    }
}

api.elements.define('converse-roster', RosterView);
