/**
 * @copyright The Converse.js contributors
 * @license Mozilla Public License (MPLv2)
 */
import '../modal/index.js';
import './modals/chat-status.js';
import './modals/profile.js';
import './modals/moments-tagging.js';
import './modals/user-settings.js';
import './statusview.js';
import './newchatmenu.js';
import '@converse/headless/plugins/status';
import '@converse/headless/plugins/vcard';
import '../chatview/bottom-panel.js';
import './user-moments.js';
import './moment-upload.js';


import { _converse, api, converse } from '@converse/headless/core';

converse.plugins.add('converse-profile', {
    dependencies: [
        'converse-status',
        'converse-modal',
        'converse-vcard',
        'converse-chatboxviews',
        'converse-adhoc-views',
    ],

    initialize() {
        api.settings.extend({
            'show_client_info': true,
            'is_reset_password_tab_visible': false,
            'is_change_theme_tab_visible': false,
            'is_Url_Email_role_visible': false,
            'is_setting_icon_visible': false,
            'is_omemo_tab_visible': false,
            'is_profile_tab_visible': false,
            'is_profile_jid_visible': false,
        });
    },

});
