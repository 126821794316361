import { _converse } from '@converse/headless/core.js';
import { html } from "lit";
import { __ } from 'i18n';

export default (el) => {
    const { message_type, message} = el.model.attributes;
    const chatbox = el.model.collection.chatbox;
    const { TEXT, LOCATION, IMAGE, VIDEO, AUDIO, DOCUMENT } = converse.MESSAGE_TYPE;
    let messageContent;
    let messageIcon;
    if (!message_type || message_type === TEXT) {
        messageContent = message;
        messageIcon = 'message'
    } else if (message_type === IMAGE) {
        messageContent = "Image";
        messageIcon = 'image'
    } else if (message_type === VIDEO) {
        messageContent = "Video";
        messageIcon = 'video'
    } else if (message_type === AUDIO) {
        messageContent = "Audio";
        messageIcon = 'audio'
    } else if (message_type === DOCUMENT) {
        messageContent = "Document";
        messageIcon = 'document'
    } else if (message_type === LOCATION) {
        messageContent = "Location";
        messageIcon = 'location'
    }
    return html`<div class="px-4">
        <div class="d-flex align-items-center">
            <img src=${`../../../../images/delete_message_icons/${messageIcon}.svg`} alt=${messageContent} />
            <div class="ml-2" style="font-size: 1rem;max-width: 90%; max-height: 50px; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; text-overflow: ellipsis;">${messageContent}</div>
        </div>
        <div class="d-flex flex-column align-items-end mt-4">
            <button
                style="width: 40% !important; padding: 12px 0 !important; border-radius: 30px !important; background: transparent linear-gradient(90deg, #FD4828 0%, #FF205B 100%) 0% 0% no-repeat padding-box;"
                type="button"
                class="btn btn-danger btn-rounded"
            >
                Delete for everyone
            </button>
            <button
                style="width: 40% !important; padding: 12px 0 !important; border-radius: 30px !important; background: transparent linear-gradient(90deg, #FD4828 0%, #FF205B 100%) 0% 0% no-repeat padding-box;"
                type="button"
                class="btn btn-danger btn-rounded mt-2"
                @click=${async () => {
                    await chatbox.retractOwnMessage(el.model)
                    el.modal.hide()
                }}
            >
                Delete for me
            </button>
            <button type="button" class="btn btn-light text-dark mt-2 add-grp-btn" data-dismiss="modal" style="width: 20%;">${__('Cancel')}</button>
        </div>
    </div>`;

}
