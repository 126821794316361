import './emoji-picker.js';
import 'shared/chat/message-limit.js';
import "shared/imageEditor/imageEditor.js"
import tpl_toolbar from './templates/toolbar.js';
import { CustomElement } from 'shared/components/element.js';
import { __ } from 'i18n';
import { _converse, api, converse } from '@converse/headless/core';
import { html } from 'lit';
import { until } from 'lit/directives/until.js';
import model from "../../../src/headless/plugins/chat/model"
import './styles/toolbar.scss';
import cameraCapture from './templates/camera-capture.js';
import cameraCapturePreview from './templates/camera-capture-preview.js';
import  ImageEditor from 'tui-image-editor';
import defaultTheme from "/src/shared/imageEditor/editor-theme"

const Strophe = converse.env.Strophe


export class ChatToolbar extends CustomElement {
    static get properties() {
        return {
            hidden_occupants: { type: Boolean },
            is_groupchat: { type: Boolean },
            message_limit: { type: Number },
            model: { type: Object },
            show_call_button: { type: Boolean },
            show_emoji_button: { type: Boolean },
            show_send_button: { type: Boolean },
            show_spoiler_button: { type: Boolean },
        };
    }

    connectedCallback() {
        super.connectedCallback();
        this.listenTo(this.model, 'change:composing_spoiler', () => this.requestUpdate());
        this.listenTo(this.model, 'change:toggleShowFileAttach', () => {
            this.requestUpdate()
        });
        this.listenTo(this.model, 'change:draft', () => {
            this.requestUpdate()
        });
        this.listenTo(this.model, 'change:audioRecordingState', () => {
            this.requestUpdate()
        })
        this.listenTo(this.model, 'change:recordingtime', () => {
            this.requestUpdate()
        })

    }

    render() {
        return tpl_toolbar(this);
    }

    firstUpdated() {
        /**
         * Triggered once the _converse.ChatBoxView's toolbar has been rendered
         * @event _converse#renderToolbar
         * @type { _converse.ChatBoxView }
         * @example _converse.api.listen.on('renderToolbar', this => { ... });
         */
        api.trigger('renderToolbar', this);
    }

    getButtons() {
        const buttons = [];
        // if (this.show_emoji_button) {
        //     const chatview = _converse.chatboxviews.get(this.model.get('jid'));
        //     buttons.push(html`<converse-emoji-dropdown .chatview=${chatview}></converse-emoji-dropdown>`);
        // }

        if (this.show_call_button) {
            const color = this.is_groupchat ? '--muc-toolbar-btn-color' : '--chat-toolbar-btn-color';
            const i18n_start_call = __('Start a call');
            buttons.push(html` <button class="toggle-call" @click=${this.toggleCall} title="${i18n_start_call}">
                <converse-icon color="var(${color})" class="fa fa-phone" size="1em"></converse-icon>
            </button>`);
        }

        if (this.show_spoiler_button) {
            buttons.push(this.getSpoilerButton());
        }

        const http_upload_promise = api.disco.supports(Strophe.NS.HTTPUPLOAD, _converse.domain);
        buttons.push(
            html`${until(
                http_upload_promise.then(is_supported => this.getHTTPUploadButton(is_supported)),
                ''
            )}`
        );

        if (this.is_groupchat && api.settings.get('visible_toolbar_buttons')?.toggle_occupants) {
            const i18n_hide_occupants = __('Hide participants');
            const i18n_show_occupants = __('Show participants');
            buttons.push(html` <button
                class="toggle_occupants right"
                title="${this.hidden_occupants ? i18n_show_occupants : i18n_hide_occupants}"
                @click=${this.toggleOccupants}
            >
                <converse-icon
                    color="var(--muc-toolbar-btn-color)"
                    class="fa ${this.hidden_occupants ? `fa-angle-double-left` : `fa-angle-double-right`}"
                    size="1em"
                ></converse-icon>
            </button>`);
        }
        /**
         * *Hook* which allows plugins to add more buttons to a chat's toolbar
         * @event _converse#getToolbarButtons
         * @example
         *  api.listen.on('getToolbarButtons', (toolbar_el, buttons) {
         *      buttons.push(html`
         *          <button @click=${() => alert('Foo!')}>Foo</button>`
         *      );
         *      return buttons;
         *  }
         */
        return _converse.api.hook('getToolbarButtons', this, buttons);
    }
    getHTTPUploadButton(is_supported) {
        if (is_supported) {
            // const level = el.model?.get('level') ?? '';
            const i18n_choose_file = __('Choose a file to send');
            const toggleCameraUpload = async () => {
                const video = document.querySelector('#video');
                const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
                    if(stream.active===true) {
                    video.srcObject = stream;
                }
                let toggleCamera = false;
                toggleCamera = !toggleCamera;
                const videoDiv = document.getElementById('camera');
                const chatbox = document.getElementsByClassName('chat-content__messages');
                if (toggleCamera) {
                    videoDiv.classList.remove('d-none');
                    chatbox[0].className = 'chat-content__messages cameraOnChat';
                } else {
                    chatbox[0].className = 'chat-content__messages';
                    videoDiv.classList.add('d-none');
                }
            };
            
            let textAreaCameraButton = document.getElementsByClassName('bottompanel__message-form-camera-icon')[0];
            if (textAreaCameraButton) {
                textAreaCameraButton.addEventListener('click', () => {
                    toggleCameraUpload();
                });
            }

            const closeCamera = () => {
                const videoDiv = document.getElementById('camera');
                const chatbox = document.getElementsByClassName('chat-content__messages');
                const videoElem = document.querySelector("#video");
                chatbox[0].className = 'chat-content__messages';
                videoDiv.classList.add('d-none');
                document.getElementById('preview').style.display="none";
                removeMediaTrack(videoElem)
            }
           const removeMediaTrack = async (videoElem) => {  
                const stream = await videoElem.srcObject;
                if(!stream) {
                    return true
                }
                 const tracks = await stream.getTracks();
                 tracks.forEach(async (track) => {
                    await track.stop();
                 })
                 videoElem.srcObject = null;        
             }


            setTimeout(() => {

                const click_button = document.querySelector('#click-photo');
                if(click_button) {
                click_button.addEventListener('click', function () {
                    const canvas = document.querySelector('#canvas');
                    const video = document.querySelector('#video');
                    var hRatio = canvas.width  / video.width    ;
                    var vRatio =  canvas.height / video.height  ; 
                    var ratio  = Math.min(hRatio, vRatio );
                    var centerShift_x = ( canvas.width - video.width*ratio ) / 2;
                    var centerShift_y = ( canvas.height - video.height*ratio ) / 2;
                    canvas.getContext('2d').drawImage(video, 0, 0, video.width, video.height, centerShift_x,centerShift_y, video.width*ratio, video.height*ratio);
                    const image_data_url = canvas.toDataURL('image/jpg');
          
                    canvas.style.display = 'none';
                    var img = new Image();
                    img.src = image_data_url;
                    document.querySelector('#tui-image-editor').style.display="block";
                    const instance = new ImageEditor(document.querySelector('#tui-image-editor'), {
                        includeUI: {
                            loadImage: {
                                path: `${image_data_url}`,
                                name: 'SampleImage',
                            },
                            theme: defaultTheme, // or whiteTheme
                            initMenu: 'filter',
                            menuBarPosition: 'bottom'
                        },
                        cssMaxWidth: 700,
                        cssMaxHeight: 1000,
                        selectionStyle: {
                            cornerSize: 20,
                            rotatingPointOffset: 70,
                        },
                    });                      
                    document.querySelector('#send-picture').style.display="block";
                    document.querySelector('#retake-picture').style.display="flex";
                });
            }
            }, 2000);

            const retakePicBtn = document.querySelector('#retake-picture');
            if (retakePicBtn) {
                retakePicBtn.addEventListener('click', () => {
                    const  queries = document.querySelectorAll('#send-picture, #tui-image-editor, #retake-picture');
                    queries.forEach((query) => {
                        query.style.display="none";
                    });
                });
            }       
             
            const uploadPicClickImg = () => {

                closeCamera();
                document.querySelector('#tui-image-editor').style.display="none";
                document.querySelector('#send-picture').style.display="none";
                document.querySelector('#retake-picture').style.display="none";

                // clearInterval(getPreviewImg);
                document.getElementById('preview').style.display="none";
                // const canvas = document.querySelector('#canvas');
                const canvas = document.getElementsByClassName('lower-canvas')[0]
                const canvasEditedImg =   canvas.toDataURL('image/jpg')
                canvas.toBlob(blob => {
                        const selectedFile = new File([blob], `img_${new Date().getTime()}.jpg`, { type: 'image/jpg' });
                        this.onPicSelection(selectedFile);
                }, 'image/jpg');
            }

     setTimeout(() => {
         let sendImg = document.querySelector('#send-picture');
         sendImg.onclick = function() {
             uploadPicClickImg();
         }
     }, 1000);              
         
            return html`
            <canvas id="canvas" style="position:absolute;display:none;"></canvas>
            ${cameraCapturePreview()}
            ${cameraCapture(closeCamera)}         
<div class="dropup ">
   <button id="paperClip" style="margin-left:20px" class="toggle-emojis" @click=${ (ev) => {
       /*toggleShowFileAttach*/
    this.model.save({toggleShowFileAttach : !this.model.get(`toggleShowFileAttach`)
    })
   }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" title="Send Files">
      <converse-icon class="fa fa-paperclip" size="1.3em" color="var(--chat-toolbar-btn-color)" path-prefix="/dist">
   </button>
   <div class="attach-files">
      <div class="dropdown-menu newToggleClass ${this.model.get(`toggleShowFileAttach`) ? `show` : ``}" >
         <div style="width: auto;" class"emoji-lists__container emoji-lists__container--browse">
            <div class="attach-body" id="checkbox">
               ${
                   /*<div class="grid-item tooltip">
                  <span class="tooltiptext">Contact</span>
                  <button title="${i18n_choose_file}" @click=${this.toggleFileUpload} >
                     <svg viewBox="0 0 53 53" height="53" width="53" preserveAspectRatio="xMidYMid meet" class="" version="1.1" x="0px" y="0px" enable-background="new 0 0 53 53" xml:space="preserve">
                        <g>
                           <defs>
                              <circle id="contact-SVGID_1_" cx="26.5" cy="26.5" r="25.5"></circle>
                           </defs>
                           <clipPath id="contact-SVGID_2_">
                              <use xlink:href="#contact-SVGID_1_" overflow="visible"></use>
                           </clipPath>
                           <g clip-path="url(#contact-SVGID_2_)">
                              <g>
                                 <path fill="#0795DC" d="M26.5-1.1C11.9-1.1-1.1,5.6-1.1,27.6h55.2C54,8.6,41.1-1.1,26.5-1.1z"></path>
                                 <path fill="#0EABF4" d="M53,26.5H-1.1c0,14.6,13,27.6,27.6,27.6s27.6-13,27.6-27.6C54.1,26.5,53,26.5,53,26.5z"></path>
                              </g>
                           </g>
                        </g>
                        <g fill="#F5F5F5">
                           <path id="svg-contact" d="M26.5 26.5C28.986 26.5 31 24.486 31 22 31 19.514 28.986 17.5 26.5 17.5 24.014 17.5 22 19.514 22 22 22 24.486 24.014 26.5 26.5 26.5ZM26.5 28.75C23.496 28.75 17.5 30.258 17.5 33.25L17.5 34.375C17.5 34.994 18.006 35.5 18.625 35.5L34.375 35.5C34.994 35.5 35.5 34.994 35.5 34.375L35.5 33.25C35.5 30.258 29.504 28.75 26.5 28.75Z"></path>
                        </g>
                     </svg>
                  </button>
                  <input type="file" @change=${this.onFileSelection} class="fileupload"  multiple="" style="display:none" name="img" accept="image/*"/>
        </div>*/ ''
               }
                <div class="grid-item tooltip">
                <span class="tooltiptext">Document</span>
                <button title="${i18n_choose_file}" @click=${this.toggleDocumentUpload}>
                    <svg viewBox="0 0 53 53" height="53" width="53" preserveAspectRatio="xMidYMid meet" class="" version="1.1" x="0px" y="0px" enable-background="new 0 0 53 53" xml:space="preserve">
                        <g>
                            <defs>
                            <circle id="document-SVGID_1_" cx="26.5" cy="26.5" r="25.5"></circle>
                            </defs>
                            <clipPath id="document-SVGID_2_">
                            <use xlink:href="#document-SVGID_1_" overflow="visible"></use>
                            </clipPath>
                            <g clip-path="url(#document-SVGID_2_)">
                            <path fill="#5157AE" d="M26.5-1.1C11.9-1.1-1.1,5.6-1.1,27.6h55.2C54,8.6,41.1-1.1,26.5-1.1z"></path>
                            <path fill="#5F66CD" d="M53,26.5H-1.1c0,14.6,13,27.6,27.6,27.6s27.6-13,27.6-27.6C54.1,26.5,53,26.5,53,26.5z"></path>
                            </g>
                        </g>
                        <g fill="#F5F5F5">
                            <path id="svg-document" d="M29.09 17.09c-.38-.38-.89-.59-1.42-.59H20.5c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H32.5c1.1 0 2-.9 2-2V23.33c0-.53-.21-1.04-.59-1.41l-4.82-4.83zM27.5 22.5V18L33 23.5H28.5c-.55 0-1-.45-1-1z"></path>
                        </g>
                    </svg>
                </button>
                <input type="file" @change=${
                    this.onDocumentSelection
                } class="documentUpload"  multiple="" style="display:none" accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                    text/plain, application/pdf"/>
                </div>
               <div class="grid-item tooltip">
                  <span class="tooltiptext">Camera</span>
                  <button  title="${i18n_choose_file}" @click=${toggleCameraUpload}>
                     <svg viewBox="0 0 53 53" height="53" width="53" preserveAspectRatio="xMidYMid meet" class="" version="1.1" x="0px" y="0px" enable-background="new 0 0 53 53" xml:space="preserve">
                        <g>
                           <defs>
                              <circle id="camera-SVGID_1_" cx="26.5" cy="26.5" r="25.5"></circle>
                           </defs>
                           <clipPath id="camera-SVGID_2_">
                              <use xlink:href="#camera-SVGID_1_" overflow="visible"></use>
                           </clipPath>
                           <g clip-path="url(#camera-SVGID_2_)">
                              <path fill="#D3396D" d="M26.5-1.1C11.9-1.1-1.1,5.6-1.1,27.6h55.2C54,8.6,41.1-1.1,26.5-1.1z"></path>
                              <path fill="#EC407A" d="M53,26.5H-1.1c0,14.6,13,27.6,27.6,27.6s27.6-13,27.6-27.6C54.1,26.5,53,26.5,53,26.5z"></path>
                              <rect x="17" y="24.5" fill="#D3396D" width="15" height="9"></rect>
                           </g>
                        </g>
                        <g fill="#F5F5F5">
                           <path id="svg-camera" d="M27.795 17C28.742 17 29.634 17.447 30.2 18.206L30.5 18.609C31.066 19.368 31.958 19.815 32.905 19.815L34.2 19.815C35.746 19.815 37 21.068 37 22.615L37 32C37 34.209 35.209 36 33 36L20 36C17.791 36 16 34.209 16 32L16 22.615C16 21.068 17.254 19.815 18.8 19.815L20.095 19.815C21.042 19.815 21.934 19.368 22.5 18.609L22.8 18.206C23.366 17.447 24.258 17 25.205 17L27.795 17ZM26.5 22.25C23.601 22.25 21.25 24.601 21.25 27.5 21.25 30.399 23.601 32.75 26.5 32.75 29.399 32.75 31.75 30.399 31.75 27.5 31.75 24.601 29.399 22.25 26.5 22.25ZM26.5 24C28.433 24 30 25.567 30 27.5 30 29.433 28.433 31 26.5 31 24.567 31 23 29.433 23 27.5 23 25.567 24.567 24 26.5 24Z"></path>
                        </g>
                     </svg>
                  </button>
                  <input type="file" @change=${
                      this.onFileSelection
                  } class="cameraUpload"  multiple="" style="display:none" name="img" accept="image/*"/>
               </div>
             ${
                 /* <div class="grid-item tooltip">
                  <span class="tooltiptext">Sticker</span>
                  <button title="${i18n_choose_file}" @click=${this.toggleFileUpload}>
                     <svg viewBox="0 0 53 53" height="53" width="53" preserveAspectRatio="xMidYMid meet" class="" fill="none">
                        <g clip-path="url(#clip0_850:74884)">
                           <circle cx="26.5" cy="26.5" r="26.5" fill="#0063CB"></circle>
                           <path d="M53 26.5C53 41.1356 41.1355 53 26.5 53C11.8645 53 0 41.1356 0 26.5L53 26.5Z" fill="#0070E6"></path>
                           <path fill-rule="evenodd" clip-rule="evenodd" d="M36.0017 22.17V26.4891C35.762 26.8114 35.3783 27.0202 34.9458 27.0202H33.1413C29.7615 27.0202 27.0216 29.76 27.0216 33.1398V34.9443C27.0216 35.3777 26.812 35.7621 26.4886 36.0017H22.17C19.3147 36.0017 17 33.687 17 30.8317V22.17C17 19.3147 19.3147 17 22.17 17H30.8317C33.687 17 36.0017 19.3147 36.0017 22.17ZM30.5216 25.5L31.4591 23.4375L33.5216 22.5L31.4591 21.5625L30.5216 19.5L29.5841 21.5625L27.5216 22.5L29.5841 23.4375L30.5216 25.5ZM23.5 22.5L24.9062 25.5938L28 27L24.9062 28.4062L23.5 31.5L22.0938 28.4062L19 27L22.0938 25.5938L23.5 22.5Z" fill="#F7F7F7"></path>
                           <path d="M34.9458 28.5202C35.2984 28.5202 35.6358 28.4554 35.9469 28.337C35.8132 29.1226 35.439 29.8536 34.868 30.4246L30.4246 34.868C29.8539 35.4388 29.1232 35.8129 28.338 35.9467C28.4566 35.6353 28.5216 35.2974 28.5216 34.9443V33.1398C28.5216 30.5885 30.5899 28.5202 33.1413 28.5202H34.9458Z" fill="#F7F7F7"></path>
                        </g>
                        <defs>
                           <clipPath id="clip0_850:74884">
                              <rect width="53" height="53" fill="white"></rect>
                           </clipPath>
                        </defs>
                     </svg>
                  </button>
                  <input type="file" @change=${this.onFileSelection} class="fileupload"  multiple="" style="display:none"/>
        </div>*/ ''
             }
               <div class="grid-item tooltip">
                  <span class="tooltiptext">Photos & Videos</span>
                  <button title="${i18n_choose_file}" @click=${this.togglImgVidUpload}>
                     <svg viewBox="0 0 53 53" height="53" width="53" preserveAspectRatio="xMidYMid meet" class="" version="1.1" x="0px" y="0px" enable-background="new 0 0 53 53" xml:space="preserve">
                        <g>
                           <defs>
                              <circle id="image-SVGID_1_" cx="26.5" cy="26.5" r="25.5"></circle>
                           </defs>
                           <clipPath id="image-SVGID_2_">
                              <use xlink:href="#image-SVGID_1_" overflow="visible"></use>
                           </clipPath>
                           <g clip-path="url(#image-SVGID_2_)">
                              <path fill="#AC44CF" d="M26.5-1.1C11.9-1.1-1.1,5.6-1.1,27.6h55.2C54,8.6,41.1-1.1,26.5-1.1z"></path>
                              <path fill="#BF59CF" d="M53,26.5H-1.1c0,14.6,13,27.6,27.6,27.6s27.6-13,27.6-27.6C54.1,26.5,53,26.5,53,26.5z"></path>
                              <rect x="17" y="24.5" fill="#AC44CF" width="18" height="9"></rect>
                           </g>
                        </g>
                        <g fill="#F5F5F5">
                           <path id="svg-image" d="M18.318 18.25 34.682 18.25C35.545 18.25 36.409 19.077 36.493 19.946L36.5 20.083 36.5 32.917C36.5 33.788 35.68 34.658 34.818 34.743L34.682 34.75 18.318 34.75C17.368 34.75 16.582 34.005 16.506 33.066L16.5 32.917 16.5 20.083C16.5 19.213 17.32 18.342 18.182 18.257L18.318 18.25 34.682 18.25ZM23.399 26.47 19.618 31.514C19.349 31.869 19.566 32.25 20.008 32.25L32.963 32.25C33.405 32.239 33.664 31.848 33.384 31.492L30.702 28.043C30.486 27.774 30.077 27.763 29.861 28.032L27.599 30.759 24.26 26.459C24.045 26.179 23.614 26.179 23.399 26.47ZM31.75 21.25C30.784 21.25 30 22.034 30 23 30 23.966 30.784 24.75 31.75 24.75 32.716 24.75 33.5 23.966 33.5 23 33.5 22.034 32.716 21.25 31.75 21.25Z"></path>
                        </g>
                     </svg>
                  </button>
                  <input type="file" @change=${
                      this.onFileSelection
                  } class="imgVideoUpload"  multiple="" style="display:none" accept="image/*,video/mp4,video/x-m4v,video/*"/>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>`;
        } else {
            return '';
        }
    }

    getSpoilerButton() {
        const model = this.model;
        if (!this.is_groupchat && !model.presence?.resources.length) {
            return;
        }

        let i18n_toggle_spoiler;
        if (model.get('composing_spoiler')) {
            i18n_toggle_spoiler = __('Click to write as a normal (non-spoiler) message');
        } else {
            i18n_toggle_spoiler = __('Click to write your message as a spoiler');
        }
        const color = this.is_groupchat ? '--muc-toolbar-btn-color' : '--chat-toolbar-btn-color';
        const markup = html` <button
            class="toggle-compose-spoiler"
            title="${i18n_toggle_spoiler}"
            @click=${this.toggleComposeSpoilerMessage}
            style="display:none;"
        >
            <converse-icon
                color="var(${color})"
                class="fa ${model.get('composing_spoiler') ? 'fa-eye-slash' : 'fa-eye'}"
                size="1em"
            ></converse-icon>
        </button>`;

        if (this.is_groupchat) {
            return markup;
        } else {
            const contact_jid = model.get('jid');
            const spoilers_promise = Promise.all(
                model.presence.resources.map(r =>
                    api.disco.supports(Strophe.NS.SPOILER, `${contact_jid}/${r.get('name')}`)
                )
            ).then(results => results.reduce((acc, val) => acc && val, true));
            return html`${until(
                spoilers_promise.then(() => markup),
                ''
            )}`;
        }
    }

    toggleFileUpload(ev) {
        ev?.preventDefault?.();
        ev?.stopPropagation?.();
        this.querySelector('.fileupload').click();
        const el = document.getElementsByClassName('newToggleClass');
        el[0].className = 'dropdown-menu newToggleClass';
    }

    toggleDocumentUpload(ev) {
        ev?.preventDefault?.();
        ev?.stopPropagation?.();
        this.querySelector('.documentUpload').click();
        const el = document.getElementsByClassName('newToggleClass');
        el[0].className = 'dropdown-menu newToggleClass';
    }

    togglImgVidUpload(ev) {
        ev?.preventDefault?.();
        ev?.stopPropagation?.();
        this.querySelector('.imgVideoUpload').click();
        const el = document.getElementsByClassName('newToggleClass');
        el[0].className = 'dropdown-menu newToggleClass';
    }

    onFileSelection(evt) {
        let message_type = evt.target.files[0].type.includes("video") ? converse.MESSAGE_TYPE.VIDEO : converse.MESSAGE_TYPE.IMAGE
        return this.model.sendFiles(evt.target.files,{message_type});
    }

    onDocumentSelection(evt) {
        let message_type = converse.MESSAGE_TYPE.DOCUMENT
        return this.model.sendFiles(evt.target.files,{message_type});
    }

    onPicSelection(evt) {
        let message_type = converse.MESSAGE_TYPE.IMAGE
        return this.model.sendFiles([evt],{message_type});
    }

    onAudioSelection(evt) {
        let message_type = converse.MESSAGE_TYPE.DOCUMENT
        return this.model.sendFiles([evt],{message_type});
    }

    toggleComposeSpoilerMessage(ev) {
        ev?.preventDefault?.();
        ev?.stopPropagation?.();
        this.model.set('composing_spoiler', !this.model.get('composing_spoiler'));
    }

    toggleOccupants(ev) {
        ev?.preventDefault?.();
        ev?.stopPropagation?.();
        this.model.save({ 'hidden_occupants': !this.model.get('hidden_occupants') });
    }

    shareFilesModal(ev) {
        ev?.preventDefault?.();
        ev?.stopPropagation?.();
        this.model.set({ 'share-files': !this.model.get('share-files') });
    }

    toggleCall(ev) {
        ev?.preventDefault?.();
        ev?.stopPropagation?.();
        /**
         * When a call button (i.e. with class .toggle-call) on a chatbox has been clicked.
         * @event _converse#callButtonClicked
         * @type { object }
         * @property { Strophe.Connection } _converse.connection - The XMPP Connection object
         * @property { _converse.ChatBox | _converse.ChatRoom } _converse.connection - The XMPP Connection object
         * @example _converse.api.listen.on('callButtonClicked', (connection, model) => { ... });
         */
        api.trigger('callButtonClicked', {
            connection: _converse.connection,
            model: this.model,
        });
    }
}

api.elements.define('converse-chat-toolbar', ChatToolbar);
