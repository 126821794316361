import tpl_chat_header from "../templates/chat-header.js";
import tpl_chat_header_dracula from "../templates/themes/dracula/chat-header-dracula.js";
import { CustomElement } from 'shared/components/element.js';
import { _converse, api } from '@converse/headless/core.js';
import { isThemeDracula } from "../../../utils/helper.js";

class ChatHeader extends CustomElement {

    async initialize () {
        this.model = _converse.xmppstatus
        this.listenTo(this.model, 'change', () => {
            this.requestUpdate()
        });
        this.listenTo(this.model, 'add', () => {
            this.requestUpdate()
        });
        api.listen.on(`updateTab`, (tab) => {
            this.activeTab = tab;
            this.requestUpdate()
        });
    }

    render () { // eslint-disable-line class-methods-use-this
        return isThemeDracula() ? tpl_chat_header_dracula(this) : tpl_chat_header(this);
    }
}

api.elements.define('converse-chat-header', ChatHeader);
