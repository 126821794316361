import { html } from 'lit';
import { _converse, api } from '@converse/headless/core';

import '../styles/moments.scss';


export default (el) => {
    const story = el.story.get({jid:el.model.get('jid')}).get('stories').filter(data => data.story_id===el.model.get('story_id'))[0]
    return html`<div class="d-flex align-items-center position-absolute w-100 p-3" style="z-index: 1; top: 0;">
        ${el.story.get({jid:el.model.get('jid')}).get('stories').map(data => {
            return html`<span class="story_span">
                ${data.story_id===story?.story_id ?
                    html `<span class="bg-dark" style="width: ${el.model.get(`story_span_width`)}%"></span>`
                        :
                    ``}            
            </span>`
        })}
    </div>`;

};
