import tpl_spinner from "templates/spinner.js";
import { _converse, api, converse } from "@converse/headless/core.js";
import { html } from 'lit';
// import tpl_settings from "templates/settings.js";
import tpl_subscribeHookzapp from "templates/subscribeHookzapp.js";
import { isThemeDracula } from "../../../utils/helper";
import '../styles/_controlbox_dracula.scss';
import '../styles/controlbox-head_dracula.scss';
const { Strophe } = converse.env;

function whenNotConnected (o) {
    const connection_status = _converse.connfeedback.get('connection_status');
    if ([Strophe.Status.RECONNECTING, Strophe.Status.CONNECTING].includes(connection_status)) {
        return tpl_spinner();
    }
    if (o['active-form'] === 'register') {
        return html`<converse-register-panel></converse-register-panel>`;
    }
    return html`<converse-login-form id="converse-login-panel" class="controlbox-pane fade-in row no-gutters"></converse-login-form>`;
}

export default (el) => {
    const o = el.model.toJSON();
    const sticky_controlbox = api.settings.get('sticky_controlbox');
    return html
    `<div class="flyout box-flyout">
        <converse-dragresize></converse-dragresize>
        <div class="chat-head controlbox-head">
            ${sticky_controlbox
                ? ''
                : html`
                      <a class="chatbox-btn close-chatbox-button" @click=${ev => el.close(ev)}>
                          <converse-icon class="fa fa-times" size="1em"></converse-icon>
                      </a>
                  `}
        </div>
        <div class="d-flex controlbox-panes draculla-control-box"> 
            <div class="switch-workstations-wrapper">
                ${isThemeDracula() ? html`<converse-switch-workstations></converse-switch-workstations>`: ''}
            </div>         
            <div class="controlbox-pane w-100">
                ${o.connected
                    ? html
                    `<converse-new-chat-menu></converse-new-chat-menu>
                    <converse-user-profile></converse-user-profile>
                        <converse-headlines-feeds-list class="controlbox-section"></converse-headlines-feeds-list>
                        <div id="chatrooms" class="controlbox-section d-none">
                            <converse-rooms-list></converse-rooms-list>
                        </div>
                        ${/*tpl_settings()*/''}
                        ${/*tpl_subscribeHookzapp()*/''}
                        ${api.settings.get('authentication') === _converse.ANONYMOUS
                            ? ''
                            : // html`<div id="converse-inbox" class="controlbox-section"><converse-inbox></converse-inbox></div><div id="converse-roster" class="controlbox-section"><converse-roster></converse-roster></div>`
                                html`<div id="converse-roster" class="controlbox-section">
                                    <converse-roster></converse-roster>
                                </div>`}`
                    : whenNotConnected(o)}
                
            </div>
        </div>    
    </div>`;
};
