import { Model } from '@converse/skeletor/src/model.js';
import { _converse, api, converse } from "@converse/headless/core";
import { MUCLIGHT } from '../../shared/constants';

const { Strophe } = converse.env;

const RoomsListModel = Model.extend({

    defaults: function () {
        return {
            'muclight_domain': MUCLIGHT.DOMAIN,
            'muc_domain': api.settings.get('muc_domain'),
            'nick': _converse.getDefaultMUCNickname(),
            'toggle_state':  _converse.OPENED,
            'is_create_group':  true,
            'group_occupants_search': '',
            'group_screen':  0,
            'group_name':  '',
            'group_description':  '',
            'group_cover':  '',
            'group_occupants':  [],
            'alert': {},
        };
    },

    initialize () {
        api.settings.listen.on('change:muc_domain', (muc_domain) => this.setDomain(muc_domain));
    },

    setDomain (jid) {
        if (!api.settings.get('locked_muc_domain')) {
            this.save('muc_domain', Strophe.getDomainFromJid(jid));
        }
    }
});

export default RoomsListModel;
