import { html } from 'lit';
import { __ } from 'i18n';
import { _converse, api } from '@converse/headless/core';
import '../styles/chat-header.scss';
import { logOut } from '../../profile/utils';

export default () => {
    let addedEventListner = false;
    const addChatHeaderTooltipEventListner = () => {
        if(!addedEventListner) {
            addedEventListner = true;
            let customBoxShadowToggler = document.querySelector('.custom-box-shadow-toggler');
            let chatHeaderTooltipList = document.querySelector('.chat-header-tooltip-list');
            customBoxShadowToggler.addEventListener("mouseenter", () => {
                chatHeaderTooltipList.classList.remove('d-none');
                chatHeaderTooltipList.classList.add('d-flex');
            });
            chatHeaderTooltipList.addEventListener("mouseleave", (event) => {
                chatHeaderTooltipList.classList.remove('d-flex');
                chatHeaderTooltipList.classList.add('d-none');
            });
        }
    }

    const openNewGroupModal = () => {
        document.querySelector('.show-add-muc-modal').click();
    }
    
    return html 
        `<div style="background: white;" class="d-flex position-relative pt-3 pb-3" @mouseenter=${addChatHeaderTooltipEventListner}>
            <div class="d-flex" style="width: 20%;">
                <span style="font-size: 26px;font-weight: 600;">Chats</span>
            </div>
            <div class="d-flex justify-content-around pl-5" style="width: 80%;">
                <button type="button" class="d-flex justify-content-center align-items-center btn btn-light btn-circle btn-sm custom-box-shadow">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17.551" height="13" viewBox="0 0 17.551 15.01">
                        <defs>
                            <linearGradient id="linear-gradient" x1="0.114" y1="0.37" x2="0.916" y2="0.422" gradientUnits="objectBoundingBox">
                                <stop offset="0" stop-color="#fd4c22"/>
                                <stop offset="1" stop-color="red"/>
                            </linearGradient>
                        </defs>
                        <g id="Group_14565" data-name="Group 14565" transform="translate(0)">
                            <g id="Group_14104" data-name="Group 14104" transform="translate(0 0)">
                                <path id="Path_6750" data-name="Path 6750" d="M481.011,714.566v1.922a5.253,5.253,0,0,1-2.01,4.194L474,724.47l-.012-.015v-2.3a1.156,1.156,0,0,1,.458-.922l3.163-2.394a2.934,2.934,0,0,0,1.094-2.35v-1.922a2.612,2.612,0,0,0-2.373-2.793h-8.181a2.831,2.831,0,0,0,0,5.587l3.559-.026a1.156,1.156,0,0,1,1.165,1.148h0a1.156,1.156,0,0,1-1.148,1.165l-3.572.026a5.125,5.125,0,0,1,0-10.212h8.181A4.914,4.914,0,0,1,481.011,714.566Z" transform="translate(-463.46 -709.46)" fill="url(#linear-gradient)"/>
                            </g>
                            <path id="Rectangle_3763" data-name="Rectangle 3763" d="M1.137,0H2.425a0,0,0,0,1,0,0V2.274a0,0,0,0,1,0,0H1.137A1.137,1.137,0,0,1,0,1.137v0A1.137,1.137,0,0,1,1.137,0Z" transform="translate(4.503 4)" fill="#a900ff"/>
                            <path id="Rectangle_3780" data-name="Rectangle 3780" d="M0,0H1.289A1.137,1.137,0,0,1,2.425,1.137v0A1.137,1.137,0,0,1,1.289,2.274H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(11.173 4)" fill="#0ac97f"/>
                            <ellipse id="Ellipse_520" data-name="Ellipse 520" cx="1.137" cy="1.137" rx="1.137" ry="1.137" transform="translate(7.99 4)" fill="#19b6f6"/>
                        </g>
                    </svg>
                </button>
                <button type="button" class="d-flex justify-content-center align-items-center btn btn-light btn-circle custom-box-shadow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16.153" height="13" viewBox="0 0 16.153 16.171">
                        <g id="Group_14574" data-name="Group 14574" transform="translate(0.25 0.274)">
                            <g id="Group_16548" data-name="Group 16548">
                                <path id="Path_7219" data-name="Path 7219" d="M11.636,15.647c-.219-.036-.439-.068-.657-.11a9.472,9.472,0,0,1-2.387-.851,15.982,15.982,0,0,1-4.154-2.912A15.811,15.811,0,0,1,.359,5.616,5.914,5.914,0,0,1,0,3.54,2.487,2.487,0,0,1,.693,1.8C1.136,1.343,1.571.874,2.051.46a1.493,1.493,0,0,1,2.21.06c.72.689,1.419,1.4,2.1,2.127a1.428,1.428,0,0,1,.007,1.968c-.382.422-.792.819-1.2,1.217a.193.193,0,0,0-.046.269,8.345,8.345,0,0,0,1.28,1.85A11.7,11.7,0,0,0,8.86,10.114c.23.148.479.266.715.406.085.05.119-.005.164-.05q.513-.512,1.026-1.025a1.791,1.791,0,0,1,.919-.54,1.4,1.4,0,0,1,1.293.382c.735.711,1.462,1.431,2.169,2.17a1.466,1.466,0,0,1,.048,2.047c-.484.548-1.005,1.065-1.535,1.569a2.149,2.149,0,0,1-1.267.547.427.427,0,0,0-.085.028Zm.192-.792a1.815,1.815,0,0,0,1.4-.445q.673-.653,1.31-1.343a.721.721,0,0,0-.009-1.1c-.681-.7-1.374-1.389-2.073-2.072a.678.678,0,0,0-.954-.03,2.2,2.2,0,0,0-.192.174c-.379.377-.755.756-1.135,1.132a.649.649,0,0,1-.833.136c-.222-.113-.444-.227-.657-.355a11.985,11.985,0,0,1-2.73-2.3A9.058,9.058,0,0,1,4.336,6.3a.693.693,0,0,1,.179-.941c.369-.357.739-.714,1.1-1.08A.763.763,0,0,0,5.62,3.014q-.939-.95-1.89-1.887A1.222,1.222,0,0,0,3.373.874a.766.766,0,0,0-.888.257q-.567.556-1.123,1.122a1.832,1.832,0,0,0-.444.667A2.822,2.822,0,0,0,.877,4.455,9.914,9.914,0,0,0,2,7.338a16.474,16.474,0,0,0,3.457,4.293,15.064,15.064,0,0,0,4.4,2.748,6.087,6.087,0,0,0,1.975.476" transform="translate(-0.001 0)" fill="#484848" stroke="#484848" stroke-width="0.5"/>
                            </g>
                        </g>
                    </svg>
                </button>
                <button type="button" class="d-flex justify-content-center align-items-center btn btn-light btn-circle btn-sm custom-box-shadow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17.587" height="13" viewBox="0 0 17.587 17.58">
                        <g id="location" transform="translate(0.253 0.25)">
                            <path id="Path_6588" data-name="Path 6588" d="M53.469,24.369a2.123,2.123,0,1,0-2.123,2.123A2.123,2.123,0,0,0,53.469,24.369Zm-3.743,0a1.62,1.62,0,1,1,1.62,1.62A1.62,1.62,0,0,1,49.726,24.369Z" transform="translate(-42.806 -19.705)" fill="#484848" stroke="#484848" stroke-width="0.5"/>
                            <path id="Path_6589" data-name="Path 6589" d="M21.62,21.3l-2.05-5.373a.252.252,0,0,0-.235-.162H15.456l.071-.115A12.912,12.912,0,0,0,17.76,9.224a4.664,4.664,0,1,0-9.328,0,12.911,12.911,0,0,0,2.233,6.425l.071.115H6.857a.252.252,0,0,0-.235.162L4.573,21.3a.251.251,0,0,0,.235.341H21.385a.251.251,0,0,0,.235-.341ZM8.935,9.223a4.161,4.161,0,0,1,8.322,0C17.279,12.8,13.94,17.2,13.1,18.257,12.252,17.2,8.913,12.8,8.935,9.223Zm3.97,9.594a.251.251,0,0,0,.383,0,26.682,26.682,0,0,0,1.853-2.551h4.021l.322.844L11.626,19.9l-2.8-3.639h2.225A26.682,26.682,0,0,0,12.9,18.817ZM11.618,20.44h0l4.687-1.667,1.818,2.362H9.663ZM7.03,16.265H8.191l2.937,3.816L8.162,21.136H5.173ZM18.76,21.136,16.8,18.6l2.859-1.017,1.357,3.557Z" transform="translate(-4.556 -4.559)" fill="#484848" stroke="#484848" stroke-width="0.5"/>
                        </g>
                    </svg>
                </button>
                <button @click=${ (ev) => {
                    api.modal.show('converse-profile-modal', { model: _converse.xmppstatus }, ev)
                }
                } type="button" class="d-flex justify-content-center align-items-center btn btn-light btn-circle btn-sm custom-box-shadow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16.525" height="13" viewBox="0 0 16.525 16.525">
                        <g id="Icon_feather-settings" data-name="Icon feather-settings" transform="translate(0.5 0.5)">
                            <path id="Path_7949" data-name="Path 7949" d="M17.734,15.617A2.117,2.117,0,1,1,15.617,13.5,2.117,2.117,0,0,1,17.734,15.617Z" transform="translate(-7.854 -7.854)" fill="none" stroke="#484848" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            <path id="Path_7950" data-name="Path 7950" d="M14.485,11.38a1.164,1.164,0,0,0,.233,1.284l.042.042a1.412,1.412,0,1,1-2,2l-.042-.042a1.174,1.174,0,0,0-1.99.833v.12a1.411,1.411,0,1,1-2.823,0V15.55a1.164,1.164,0,0,0-.762-1.066,1.164,1.164,0,0,0-1.284.233l-.042.042a1.412,1.412,0,1,1-2-2l.042-.042a1.174,1.174,0,0,0-.833-1.99h-.12a1.411,1.411,0,0,1,0-2.823h.064A1.164,1.164,0,0,0,4.04,7.146a1.164,1.164,0,0,0-.233-1.284l-.042-.042a1.412,1.412,0,1,1,2-2l.042.042A1.164,1.164,0,0,0,7.089,4.1h.056a1.164,1.164,0,0,0,.706-1.066v-.12a1.411,1.411,0,1,1,2.823,0v.064a1.174,1.174,0,0,0,1.99.833l.042-.042a1.412,1.412,0,1,1,2,2l-.042.042a1.164,1.164,0,0,0-.233,1.284v.056a1.164,1.164,0,0,0,1.066.706h.12a1.411,1.411,0,0,1,0,2.823H15.55a1.164,1.164,0,0,0-1.066.706Z" transform="translate(-1.5 -1.5)" fill="none" stroke="#484848" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                        </g>
                    </svg>
                </button>
                <button type="button" class="d-flex justify-content-center align-items-center btn btn-light btn-circle btn-sm custom-box-shadow custom-box-shadow-toggler">
                    <svg xmlns="http://www.w3.org/2000/svg" width="9.622" height="13" viewBox="0 0 9.622 5.501">
                        <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M12.909,11,16.55,7.366a.688.688,0,1,0-.974-.971l-4.125,4.122a.686.686,0,0,0-.02.948l4.142,4.151a.688.688,0,0,0,.974-.971Z" transform="translate(-6.194 16.752) rotate(-90)" fill="#484848"/>
                    </svg>
                </button>
                <button @click=${ (ev) => _converse.xmppstatus.save({chatmenu:!_converse.xmppstatus.get(`chatmenu`)})} type="button" class="d-flex justify-content-center align-items-center btn btn-danger btn-circle btn-sm custom-box-shadow-last">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19.081" height="13" viewBox="0 0 19.081 19.081">
                        <g id="Group_15700" data-name="Group 15700" transform="translate(-323.348 -692.572)">
                            <g id="Icon_feather-edit" data-name="Icon feather-edit" transform="translate(321.098 690.417)">
                                <path id="Path_8100" data-name="Path 8100" d="M10.9,6H4.756A1.756,1.756,0,0,0,3,7.756V20.049A1.756,1.756,0,0,0,4.756,21.8H17.049A1.756,1.756,0,0,0,18.8,20.049V13.9" transform="translate(0 -1.319)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                            </g>
                            <path id="Path_9267" data-name="Path 9267" d="M19.835,3.282A1.583,1.583,0,0,1,22.073,5.52l-7.089,7.088L12,13.355l.746-2.985Z" transform="translate(319 690.646)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        </g>
                    </svg>
                </button>
            </div>
            <div class="d-none position-absolute flex-column align-items-center chat-header-tooltip-list">
                <div class="chat-header-tooltip-list-triangle-up"></div>
                <div class="list-group  ">
                    <div class="d-none">
                        <converse-rooms-list></converse-rooms-list>
                    </div>
                    <button type="button" class="list-group-item list-group-item-action d-flex justify-content-between" @click=${openNewGroupModal}>
                        <span>New Group</span>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16.967" viewBox="0 0 29.179 16.967">
                                <g id="Group_16592" data-name="Group 16592" transform="translate(0.125 0.127)">
                                    <g id="Group_16593" data-name="Group 16593" transform="translate(0 0)">
                                        <path id="Path_11385" data-name="Path 11385" d="M28.928,14.856c-.15.2-.326.35-.593.26s-.3-.291-.317-.538a4.791,4.791,0,0,0-7.461-3.838,1.86,1.86,0,0,0-.158.118c-.013.011-.018.032-.006.01.381.582.785,1.126,1.111,1.713a7.658,7.658,0,0,1,.913,3.429,1.807,1.807,0,0,1,0,.254.474.474,0,0,1-.458.45.453.453,0,0,1-.45-.422c-.072-.511-.093-1.033-.208-1.533a7.036,7.036,0,0,0-13.777.418c-.058.323-.064.655-.094.982-.034.366-.2.56-.472.555s-.463-.23-.452-.6a7.924,7.924,0,0,1,1.887-4.993c.054-.065.11-.128.175-.2a.786.786,0,0,0-.114-.119,4.63,4.63,0,0,0-4.715-.456A4.584,4.584,0,0,0,.948,14.139c-.024.177-.034.356-.047.534a.445.445,0,0,1-.46.463A.436.436,0,0,1,0,14.657a5.531,5.531,0,0,1,1.667-3.977A6.065,6.065,0,0,1,2.63,9.9c.341-.225.722-.391,1.111-.6a3.347,3.347,0,0,1-1.4-3A3.213,3.213,0,0,1,3.573,3.951,3.361,3.361,0,1,1,7.614,9.318c.162.074.315.131.454.211.35.2.691.417,1.042.615.065.036.191.058.235.022A8.337,8.337,0,0,1,12.2,8.631,4.588,4.588,0,0,1,9.862,4.58a4.442,4.442,0,0,1,1.5-3.38,4.612,4.612,0,1,1,5.379,7.425c.19.069.362.13.531.194a7.724,7.724,0,0,1,2.21,1.282.262.262,0,0,0,.381.021c.454-.294.93-.553,1.412-.835A3.333,3.333,0,0,1,19.9,6.3a3.229,3.229,0,0,1,1.085-2.227,3.367,3.367,0,1,1,4.2,5.25c.127.057.244.106.359.161a5.649,5.649,0,0,1,3.334,4.421.921.921,0,0,0,.054.155ZM10.778,4.592A3.684,3.684,0,1,0,14.487.915a3.691,3.691,0,0,0-3.708,3.677m14.9,1.971a2.442,2.442,0,0,0-4.884-.029,2.449,2.449,0,0,0,2.433,2.451,2.422,2.422,0,0,0,2.451-2.423M3.249,6.537a2.442,2.442,0,0,0,4.884.037,2.442,2.442,0,0,0-4.884-.037" transform="translate(0 0.001)" fill="#003254" stroke="#003356" stroke-width="0.25"/>
                                    </g>
                                </g>
                            </svg>
                        </span>
                    </button>
                    <button type="button" class="list-group-item list-group-item-action d-flex justify-content-between">
                        <span>Explore</span>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="13" height="18" viewBox="0 0 18 18">
                                <defs>
                                    <clipPath id="clip-path">
                                        <rect id="Rectangle_5533" data-name="Rectangle 5533" width="18" height="18" transform="translate(0 0)" fill="#003357"/>
                                    </clipPath>
                                </defs>
                                <g id="Group_16504" data-name="Group 16504" transform="translate(-0.066 -0.066)">
                                    <g id="Group_16504-2" data-name="Group 16504" transform="translate(0.066 0.066)" clip-path="url(#clip-path)">
                                        <path id="Path_11325" data-name="Path 11325" d="M8.333,4.168c0,.644,0,1.287,0,1.931A2.226,2.226,0,0,1,6.1,8.334q-1.931.007-3.861,0A2.226,2.226,0,0,1,0,6.089q0-1.92,0-3.84A2.225,2.225,0,0,1,2.241,0Q4.172,0,6.1,0a2.226,2.226,0,0,1,2.23,2.234c0,.644,0,1.287,0,1.931M4.167,6.948c.615,0,1.23,0,1.844,0a.866.866,0,0,0,.934-.934q0-1.845,0-3.689a.864.864,0,0,0-.934-.933q-1.844,0-3.689,0a.864.864,0,0,0-.933.934q0,1.845,0,3.689a.865.865,0,0,0,.934.934c.615,0,1.23,0,1.844,0" transform="translate(-0.059 -0.059)" fill="#003357"/>
                                        <path id="Path_11326" data-name="Path 11326" d="M.01,171.862c0-.644,0-1.287,0-1.931a2.226,2.226,0,0,1,2.207-2.212q1.952-.008,3.9,0a2.223,2.223,0,0,1,2.218,2.223q.007,1.942,0,3.884a2.224,2.224,0,0,1-2.218,2.223q-1.941.008-3.883,0A2.227,2.227,0,0,1,.01,173.815c0-.651,0-1.3,0-1.953m4.179,2.8c.608,0,1.215,0,1.823,0a.868.868,0,0,0,.943-.947q0-1.834,0-3.667a.867.867,0,0,0-.947-.942q-1.834,0-3.667,0a.866.866,0,0,0-.942.947q0,1.834,0,3.667a.867.867,0,0,0,.947.943c.615,0,1.23,0,1.844,0" transform="translate(-0.067 -158.051)" fill="#003357"/>
                                        <path id="Path_11327" data-name="Path 11327" d="M167.707,171.884c0-.651,0-1.3,0-1.953a2.226,2.226,0,0,1,2.207-2.213q1.952-.008,3.9,0a2.223,2.223,0,0,1,2.218,2.223q.007,1.942,0,3.884a2.224,2.224,0,0,1-2.217,2.224q-1.941.008-3.883,0a2.227,2.227,0,0,1-2.23-2.234c0-.644,0-1.287,0-1.931m4.161,2.779q.911,0,1.823,0a.872.872,0,0,0,.96-.952q0-1.823,0-3.646a.871.871,0,0,0-.952-.96q-1.823,0-3.645,0a.87.87,0,0,0-.96.951q0,1.823,0,3.646a.87.87,0,0,0,.951.96c.608,0,1.215,0,1.823,0" transform="translate(-158.042 -158.051)" fill="#003357"/>
                                        <path id="Path_11328" data-name="Path 11328" d="M171.9,8.368A4.166,4.166,0,1,1,176.058,4.2,4.157,4.157,0,0,1,171.9,8.368M174.67,4.2a2.778,2.778,0,1,0-2.776,2.776A2.8,2.8,0,0,0,174.67,4.2" transform="translate(-158.061 -0.092)" fill="#003357"/>
                                    </g>
                                </g>
                            </svg>
                        </span>
                    </button>
                    <button type="button" class="list-group-item list-group-item-action d-flex justify-content-between">
                        <span>Starred Messages</span>
                        <span>    
                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17.611" viewBox="0 0 18.965 17.611">
                                <path id="Icon_ionic-ios-star-outline" data-name="Icon ionic-ios-star-outline" d="M20.5,9.471H14.268L12.376,3.824a.686.686,0,0,0-1.287,0L9.2,9.471H2.927a.679.679,0,0,0-.677.677.5.5,0,0,0,.013.114.651.651,0,0,0,.284.478l5.118,3.607L5.7,20.058a.679.679,0,0,0,.233.762.655.655,0,0,0,.381.165.83.83,0,0,0,.423-.152l5-3.56,5,3.56a.793.793,0,0,0,.423.152.608.608,0,0,0,.377-.165.671.671,0,0,0,.233-.762L15.8,14.348l5.076-3.641L21,10.6a.71.71,0,0,0,.22-.453A.717.717,0,0,0,20.5,9.471Zm-5.385,3.916a1.189,1.189,0,0,0-.432,1.346l1.274,3.713a.17.17,0,0,1-.258.195L12.418,16.3a1.183,1.183,0,0,0-.69-.22,1.161,1.161,0,0,0-.686.22L7.766,18.636a.169.169,0,0,1-.258-.195l1.274-3.713a1.193,1.193,0,0,0-.436-1.355L4.917,10.957a.17.17,0,0,1,.1-.309H9.193a1.184,1.184,0,0,0,1.122-.809l1.253-3.734a.17.17,0,0,1,.322,0l1.253,3.734a1.184,1.184,0,0,0,1.122.809h4.119a.167.167,0,0,1,.1.3Z" transform="translate(-2.25 -3.375)" fill="#003254"/>
                            </svg>
                        </span>
                    </button>
                    <button type="button" class="list-group-item list-group-item-action d-flex justify-content-between" @click=${logOut}>
                        <span>${__('Log out')}</span>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="15.281" viewBox="0 0 15.281 15.281">
                                <g id="Icon_feather-log-out" data-name="Icon feather-log-out" transform="translate(-3.875 -3.875)">
                                    <path id="Path_11386" data-name="Path 11386" d="M9.177,18.531H6.059A1.559,1.559,0,0,1,4.5,16.972V6.059A1.559,1.559,0,0,1,6.059,4.5H9.177" fill="none" stroke="#003254" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
                                    <path id="Path_11387" data-name="Path 11387" d="M24,18.295l3.9-3.9L24,10.5" transform="translate(-9.367 -2.882)" fill="none" stroke="#003254" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
                                    <path id="Path_11388" data-name="Path 11388" d="M22.854,18H13.5" transform="translate(-4.323 -6.485)" fill="none" stroke="#003254" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
                                </g>
                            </svg>
                        </span>
                    </button>
                </div>
            </div>
        </div>`
};