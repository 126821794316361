import { __ } from 'i18n';
import { html } from "lit";
import "../styles/file.scss"
export default (url, name) => {
    const i18n_download =  __('Download file "%1$s"', name) 
    const extension =  name.substring(name.lastIndexOf('.')+1, name.length) || name;
    let className = ""
    if(extension==="pdf"){
        className="pdf"
    }
    else if(extension==="docx"){
        className="docx"
    }
    else if(extension==="doc"){
        className="doc"
    }
    else if(extension==="xlsx"){
        className="xlsx"
    }
    else if(extension==="pptx"){
        className="pptx"
    }
    else if(extension==="txt"){
        className="txt"
    }
    else if(extension==="wav"){
        className="wav"
    } 
    else if(extension==="mpeg"){
        className="mpeg"
    } 
    else if(extension==="accdb"){
        className="accdb"
    } 
    else if(extension==="pub"){
        className="pub"
    } 
    else if(extension==="avi"){
        className="avi"
    } 
    else if(extension==="csv"){
        className="csv"
    } 
    else if(extension==="html"){
        className="html"
    } 
    else if(extension==="css"){
        className="css"
    }    
    else if(extension==="avif"){
        className="avif"
    } else{
        className="default"
    }   
    return html`
    <div class="chat__file">
    <a style="margin:0;padding:0" target="_blank" href="${url}">
    <img class=${className} style="width: 60px;
    height: 50px;
    margin-right: 10px;"/>
    </a>     
      <a target="_blank" rel="noopener" href="${url}">${i18n_download}</a>
    </div> `;
}
