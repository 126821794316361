import { html } from "lit";
import { __ } from 'i18n';
import { _converse, api } from "@converse/headless/core";


export default (o) => {
    const i18n_placeholder = __('Search');
    const title_contact_filter = __('Filter by contact name');
    const title_group_filter = __('Filter by group name');
    const title_status_filter = __('Filter by status');
    const label_any = __('Any');
    const label_unread_messages = __('Unread');
    const label_online = __('Online');
    const label_chatty = __('Chatty');
    const label_busy = __('Busy');
    const label_away = __('Away');
    const label_xa = __('Extended Away');
    const label_offline = __('Offline');

    return html`
        <form class="controlbox-padded roster-filter-form input-button-group ${ (!o.visible) ? 'hidden' : 'fade-in' }"
            @submit=${o.submitFilter}>
            <div class="d-flex justify-content-between pt-1">
                <button @click=${ () => {
                    _converse.roster_filter.save({roster_section_edit:!o.roster_section_edit})
                }
                } type="button" class="btn btn-light btn-sm col-2" style="color: #007fff; border-radius: 30px;">${o.roster_section_edit ? `Cancel` : `Edit`}</button>
                <div class="filter-by flex-nowrap d-none">
                    <converse-icon size="1em" @click=${o.changeTypeFilter} class="fa fa-user clickable ${ (o.filter_type === 'contacts') ? 'selected' : '' }" data-type="contacts" title="${title_contact_filter}"></converse-icon>
                    <converse-icon size="1em" @click=${o.changeTypeFilter} class="fa fa-users clickable ${ (o.filter_type === 'groups') ? 'selected' : '' }" data-type="groups" title="${title_group_filter}"></converse-icon>
                    <converse-icon size="1em" @click=${o.changeTypeFilter} class="fa fa-circle clickable ${ (o.filter_type === 'state') ? 'selected' : '' }" data-type="state" title="${title_status_filter}"></converse-icon>
                </div>
                <div class="d-flex col-9">
                    <button type="button" class="btn btn-light btn-circle shadow-none d-flex justify-content-center align-items-center pl-3 roster-filter-btn" style="border-radius: 30px 0 0 30px; height: 32px;">
                        <img src="../../../../images/general_assets/sidebar-search-icon.svg" alt="search"/>
                    </button>
                    <input .value="${o.filter_text || ''}"
                        @keydown=${o.liveFilter}
                        class="roster-filter form-control ${ (o.filter_type === 'state') ? 'hidden' : '' } shadow-none"
                        placeholder="${i18n_placeholder}"
                        style="border: none; background: #f8f9fa; border-radius: 0 30px 30px 0;"
                    />
                    <div class="d-flex align-items-center">
                        <converse-icon size="1em" class="fa fa-times clear-input ${ (!o.filter_text || o.filter_type === 'state') ? 'hidden' : '' }"
                            @click=${o.clearFilter}>
                        </converse-icon>
                    </div>
                    <select class="form-control state-type ${ (o.filter_type !== 'state') ? 'hidden' : '' }"
                            @change=${o.changeChatStateFilter}>
                        <option value="">${label_any}</option>
                        <option ?selected=${o.chat_state === 'unread_messages'} value="unread_messages">${label_unread_messages}</option>
                        <option ?selected=${o.chat_state === 'online'} value="online">${label_online}</option>
                        <option ?selected=${o.chat_state === 'chat'} value="chat">${label_chatty}</option>
                        <option ?selected=${o.chat_state === 'dnd'} value="dnd">${label_busy}</option>
                        <option ?selected=${o.chat_state === 'away'} value="away">${label_away}</option>
                        <option ?selected=${o.chat_state === 'xa'} value="xa">${label_xa}</option>
                        <option ?selected=${o.chat_state === 'offline'} value="offline">${label_offline}</option>
                    </select>
                </div>
                <button type="button" class="btn btn-light btn-circle btn-sm col-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14.11" height="12.799" viewBox="0 0 14.11 12.799">
                        <path id="Icon_feather-filter" data-name="Icon feather-filter" d="M16.11,4.5H3l5.244,6.2v4.287L10.866,16.3V10.7Z" transform="translate(-2.5 -4)" fill="none" stroke="#242424" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                    </svg>
                </button>
            </div>
        </form>`
};
