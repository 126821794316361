import { _converse, api, converse } from '@converse/headless/core.js';
import { fetchGroupAffiliations, isArchived, isGroupAffiliationMessage, isGroupExistInRoster, isGroupStanza, isHeadline, isInboxMessage, isMAMMessage, isReactionMessage, isServerMessage, responseToAnswer, responseToCallCancel, responseToIce, generateAnswer, generateDecline, responseToRingingCall, responseToStartCall, setRemoteDescription, } from '@converse/headless/shared/parsers';
import { parseMessage } from './parsers.js';
import log from '@converse/headless/log.js';
import axios from 'axios';
import { getDefaultStore } from '../../utils/storage.js';
import { CALL_TYPE, CALL_TYPE_CONST, CHAT_TYPE, MESSAGE_TYPE, SIGNAL_NAME } from '../muclight/constants.js';

const { Strophe, u, sizzle } = converse.env;

export function roster_section_edit (ev)
{
    ev?.preventDefault();
    return new Promise(async (resolve, reject) => {
        try{
            if(ev.target.getAttribute(`name`)!=="delete" && ev.target.getAttribute(`name`)!=="read" && ev.target.getAttribute(`name`)!=="archive") {
                await api.confirm(`there might be something wrong with ${ev.target.getAttribute(`name`)}! Please retry.`)
                    resolve(ev)
                    return true
            }
            const jids = []
            const formElements = Array.from(ev.target.form);
            for(const formElementsData of formElements) {
                if(formElementsData.getAttribute('type')=="checkbox" && formElementsData.checked) {
                    jids.push(formElementsData.getAttribute('jid'))
                }
            }
            if(jids.length) {
                if(ev.target.getAttribute(`name`)==="delete") {
                    for(const jid of jids) {
                        const this_chatbox_model = await _converse.chatboxes.get(jid)

                        let messages_data = await this_chatbox_model?.messages?.models
                        messages_data = await messages_data ? await messages_data : []
                        for(const messages of messages_data) {
                            messages.save({is_deleted: true})
                        }
                        this_chatbox_model?.save({hidden:true,num_unread:0})
                        _converse.roster.get(jid)?.save({hidden:true})
                    }
                    _converse.roster_filter.save({roster_section_edit:false, selected_jids: [], roster_section_edit_dropdown: false})
                }else if(ev.target.getAttribute(`name`)==="read") {
                    for(const jid of jids) {
                        const this_chatbox_model = await _converse.chatboxes.get(jid)
                        this_chatbox_model?.save({num_unread:0})
                    }
                    _converse.roster_filter.save({roster_section_edit:false, selected_jids: [], roster_section_edit_dropdown: false})
                }else if(ev.target.getAttribute(`name`)==="archive") {
                    for(const jid of jids) {
                        _converse.roster.get(jid)?.save({is_archived:!_converse.roster.get(jid)?.get('is_archived')})
                    }
                    await api.confirm(`Chat Moved to Archived.`)
                    _converse.roster_filter.save({roster_section_edit:false, selected_jids: [], roster_section_edit_dropdown: false})
                }
            }
            resolve(ev)
            // ev.target.reset();
        }catch(error){
            reject(`roster_section_edit error : `,error)
        }
    })
}
export function openChat (jid) {
    if (!u.isValidJID(jid)) {
        return log.warn(`Invalid JID "${jid}" provided in URL fragment`);
    }
    api.chats.open(jid);
}
export function sessionBuild (session_id) {
    return;
    if(session_id || session_id!='')
    {
        return new Promise(async (resolve, reject) => {
            try{
              let session_idObj = {session_id:session_id};
              const res = await axios.post(GlobalConfig.APIM_APIS.getFireBaseSession, session_idObj);
              resolve(res.data)
              }catch(error){
              reject(error);
            }
        })        
    }
}

export async function onClearSession () {
    if (_converse.shouldClearCache()) {
        await Promise.all(
            _converse.chatboxes.map(c => c.messages && c.messages.clearStore({ 'silent': true }))
        );
        const filter = o => o.get('type') !== _converse.CONTROLBOX_TYPE;
        _converse.chatboxes.clearStore({ 'silent': true }, filter);
    }
}

async function handleErrorMessage (stanza) {
    const from_jid = Strophe.getBareJidFromJid(stanza.getAttribute('from'));
    if (u.isSameBareJID(from_jid, _converse.bare_jid)) {
        return;
    }
    const chatbox = await api.chatboxes.get(from_jid);
    if (chatbox?.get('type') === _converse.PRIVATE_CHAT_TYPE) {
        chatbox?.handleErrorMessageStanza(stanza);
    }
}

export function autoJoinChats () {
    // Automatically join private chats, based on the
    // "auto_join_private_chats" configuration setting.
    api.settings.get('auto_join_private_chats').forEach(jid => {
        if (_converse.chatboxes.where({ 'jid': jid }).length) {
            return;
        }
        if (typeof jid === 'string') {
            api.chats.open(jid);
        } else {
            log.error('Invalid jid criteria specified for "auto_join_private_chats"');
        }
    });
    /**
     * Triggered once any private chats have been automatically joined as
     * specified by the `auto_join_private_chats` setting.
     * See: https://conversejs.org/docs/html/configuration.html#auto-join-private-chats
     * @event _converse#privateChatsAutoJoined
     * @example _converse.api.listen.on('privateChatsAutoJoined', () => { ... });
     * @example _converse.api.waitUntil('privateChatsAutoJoined').then(() => { ... });
     */
    api.trigger('privateChatsAutoJoined');
}

export function registerMessageHandlers () {
    _converse.connection.addHandler(
        stanza => {
            if (
                ['error'].includes(stanza.getAttribute('type')) ||
                isHeadline(stanza) ||
                isServerMessage(stanza) ||
                isArchived(stanza)
            ) {
                return true;
            }
            return _converse.handleMessageStanza(stanza) || true;
        },
        null,
        'message',
    );

    _converse.connection.addHandler(
        stanza => handleErrorMessage(stanza) || true,
        null,
        'message',
        'error'
    );
}

export async function updateGroupIfNotExist(data){
    let item = document.createElement(`item`)
    item.setAttribute(`subscription`,'both')
    item.setAttribute(`jid`,data.jid)
    item.setAttribute(`image`,data?.image)
    item.setAttribute(`name`,data?.nickname)
    item.setAttribute(`type`,data?.type)
    item.setAttribute(`time`,new Date.now())
    return _converse.roster.updateContact(item)
}

/**
 * Handler method for all incoming single-user chat "message" stanzas.
 * @param { MessageAttributes } attrs - The message attributes
 */
export async function handleMessageStanza (stanza) {
    const is_group_stanza = await isGroupStanza(stanza)
    const is_group_exist_in_roster = await isGroupExistInRoster(stanza)
    const is_group_affiliation_message = await isGroupAffiliationMessage(stanza)
    if(isMAMMessage(stanza)) {
        let selector = `forwarded`;
        stanza = sizzle(selector, stanza).pop()
        selector = `message`;
        stanza = sizzle(selector, stanza).pop()
        
        if(_converse?.messageIds?.includes(stanza.getAttribute(`id`)))
        {
            return
        }
    }
    if(isInboxMessage(stanza).length){
        let selector = `forwarded`;
        stanza = sizzle(selector, stanza).pop()
        selector = `message`;
        stanza = sizzle(selector, stanza).pop()
        return
        if(!sizzle(`story_status`,stanza).length)
        {
            return
            return log.info(`handleMessageStanza: Ignoring incoming inbox message from Server`);
        }
    }
    if (isServerMessage(stanza)) {
        // Prosody sends headline messages with type `chat`, so we need to filter them out here.
        const from = stanza.getAttribute('from');
        return log.info(`handleMessageStanza: Ignoring incoming server message from JID: ${from}`);
    }
    let attrs;
    try {
        attrs = await parseMessage(stanza);
    } catch (e) {
        return log.error(e);
    }
    // if(attrs.is_error && attrs?.from_jid_resource && attrs?.from_jid_resource===_converse.bare_jid){
    if(attrs.is_error){
            return
    }
    if(attrs.message_type===MESSAGE_TYPE.WEBRTC) {
        const chatboxwebrtc = await api.chatboxes.get(attrs.from)
        if(attrs.type===SIGNAL_NAME.START_CALL) {
            const responsetostartcall = responseToStartCall(attrs)
            if(!responsetostartcall) {
                await api.confirm(`Opps! Unknown call type, please retry.`)
                return
            }
            await chatboxwebrtc.sendMessage(responsetostartcall)
        }
        if(attrs.type===SIGNAL_NAME.RINGING_CALL) {
            const responsetoringingcall = await responseToRingingCall(attrs)
            await chatboxwebrtc.sendMessage(responsetoringingcall)
        }
        if(attrs.type===SIGNAL_NAME.ANSWER) {
            const responsetoanswer = await responseToAnswer(chatboxwebrtc, attrs)
        }
        if(attrs.type===SIGNAL_NAME.ICE) {
            const responsetoice = await responseToIce(chatboxwebrtc,attrs)
        }
        if(attrs.type===SIGNAL_NAME.DECLINE) {
            _converse?.outgoingcallmodal?.stopCall()
        }
        if(attrs.type===SIGNAL_NAME.CANCEL) {
            _converse?.incommingcallmodal?.stopCall()
        }
        if(attrs.type===SIGNAL_NAME.LEAVE) {
            _converse?.incommingcallmodal?.stopCall()
            _converse?.outgoingcallmodal?.stopCall()
            _converse?.callconnectedmodal?.leaveCall(false)
        }
        if(attrs.type===SIGNAL_NAME.OFFER) {
            await setRemoteDescription(attrs)
            const call_type = attrs.message.call_type ? attrs.message.call_type : attrs.message.callType===CALL_TYPE.AUDIO ? CALL_TYPE_CONST.AUDIO : attrs.message.callType===CALL_TYPE.VIDEO ? CALL_TYPE_CONST.VIDEO : `ERROR`
            const callType = call_type===CALL_TYPE_CONST.AUDIO ? CALL_TYPE.AUDIO : call_type===CALL_TYPE_CONST.VIDEO ? CALL_TYPE.VIDEO : `ERROR`
            if(call_type==`ERROR` || callType==`ERROR`) {
                await api.confirm(`Opps! Unknown call type, please retry.`)
                return
            }

            // const answer = await generateAnswer(attrs)
            // const decline = await generateDecline(attrs)
            api.trigger(`converse-incomming-call-modal`,{ is_modal_type: 'incomming_call', disable_close_button_modal: true, CALL_TYPE_VALUE: callType, 'model': chatboxwebrtc.contact, chatbox: chatboxwebrtc, answer: await generateAnswer(attrs), decline: await generateDecline(attrs) })
        }
    }
    if(is_group_stanza){
        if(!is_group_exist_in_roster){
            const getgroupinfo = await u.getGroupInfo(attrs.from)
            if(getgroupinfo){
                _converse.roster.create({ my_affilation: true, nickname:getgroupinfo.roomname, stamp: Date.now(), chat_type: CHAT_TYPE.GROUP_CHAT, info:getgroupinfo, image:getgroupinfo.image_url, ask:"", groups:[], jid:attrs.from, subscription:'both' }, {sort: false});
            }
        } else if(is_group_affiliation_message){
            const fetch_group_affiliations = await fetchGroupAffiliations(stanza)
            if(fetch_group_affiliations){
                if(fetch_group_affiliations[0].jid===_converse.bare_jid && fetch_group_affiliations[0].affiliation=="none"){
                    _converse.roster.get(attrs.from).save({ my_affilation: false});
                    await _converse.chatboxes.get(attrs.from).save({ my_affilation: false });
                    api.trigger(`groupInfoRequestUpdate`)
                    log.info(`you have been removed from group`)
                }else{
                    const getgroupinfo = await u.getGroupInfo(attrs.from)
                    _converse.roster.get(attrs.from).save({ info:getgroupinfo, my_affilation: true });
                    await _converse.chatboxes.get(attrs.from).save({ info:getgroupinfo, my_affilation: true });
                    api.trigger(`groupInfoRequestUpdate`)
                }
            }
        }
    }
    if (u.isErrorObject(attrs)) {
        attrs.stanza && log.error(attrs.stanza);
        return log.error(attrs.message);
    }
    // XXX: Need to take XEP-428 <fallback> into consideration
    const has_body = !!(attrs.body || attrs.plaintext)
    const chatbox = await api.chats.get(attrs.contact_jid, { 'nickname': attrs.nick }, has_body);
    await chatbox?.queueMessage(attrs,stanza);
    if(attrs?.time && attrs?.encrypted && _converse?.roster?.models?.length && attrs.is_story!==true){
        let last_msg = attrs?.plaintext || ``
        if(attrs.message_type===MESSAGE_TYPE.WEBRTC){
            last_msg = `CALL`
        }
        if(attrs.message_type===converse.MESSAGE_TYPE.AUDIO) {
            last_msg = `AUDIO`
        }

            _converse.roster.get(attrs.from).save({last_msg_type: attrs.message_type, last_msg_time: Date.now(), last_msg, stamp: Date.now()})
        // if(_converse?.roster?.models[0]?.get('jid')!==attrs.from && attrs.from!==_converse.bare_jid){
        //     _converse.roster.get(attrs.from).save({stamp: Date.now()})
        // }
    }
    /**
     * @typedef { Object } MessageData
     * An object containing the original message stanza, as well as the
     * parsed attributes.
     * @property { XMLElement } stanza
     * @property { MessageAttributes } stanza
     * @property { ChatBox } chatbox
     */
    const data = { stanza, attrs, chatbox };
    /**
     * Triggered when a message stanza is been received and processed.
     * @event _converse#message
     * @type { object }
     * @property { module:converse-chat~MessageData } data
     */
    api.trigger('message', data);
}

/**
 * Ask the XMPP server to enable Message Carbons
 * See [XEP-0280](https://xmpp.org/extensions/xep-0280.html#enabling)
 * @param { Boolean } reconnecting
 */
export async function enableCarbons () {
    const domain = Strophe.getDomainFromJid(_converse.bare_jid);
    const supported = await api.disco.supports(Strophe.NS.CARBONS, domain);

    if (!supported) {
        log.warn("Not enabling carbons because it's not supported!");
        return;
    }

    const iq = new Strophe.Builder('iq', {
        'from': _converse.connection.jid,
        'type': 'set'
    }).c('enable', {xmlns: Strophe.NS.CARBONS});

    const result = await api.sendIQ(iq, null, false);
    if (result === null) {
        log.warn(`A timeout occurred while trying to enable carbons`);
    } else if (u.isErrorStanza(result)) {
        log.warn('An error occurred while trying to enable message carbons.');
        log.error(result);
    } else {
        log.debug('Message carbons have been enabled.');
    }
}
